import { Controller } from "@hotwired/stimulus"

// import { RequestNewEvent, RequestDismissEvent } from "../hub/requests"

import I18n from "../i18n"

export default class extends Controller {
  static targets = ["status"]

  // connect() {
  //   document.addEventListener("request:new", this.incoming)
  //   document.addEventListener("request:dismiss", this.dismiss)
  // }

  // disconnect() {
  //   document.removeEventListener("request:new", this.incoming)
  //   document.removeEventListener("request:dismiss", this.dismiss)
  // }

  // incoming = (ev: CustomEvent<RequestNewEvent>) => {
  //   let obj = ev.detail.object
  //   let el = this.element as HTMLDivElement
  //   if (obj.table_id != el.dataset.tableId) return

  //   // @ts-ignore
  //   this.statusTarget.classList.add("requested-table")
  //   // @ts-ignore
  //   this.statusTarget.textContent = I18n.t(`js.hub.requests.${obj.kind}`)
  // }

  // dismiss = (ev: CustomEvent<RequestDismissEvent>) => {
  //   let obj = ev.detail.object
  //   let el = this.element as HTMLDivElement
  //   if (obj.table_id != el.dataset.tableId) return

  //   // @ts-ignore
  //   this.statusTarget.classList.remove("requested-table")

  //   let status = el.dataset.status
  //   if (status?.match(/^unpaid:/)) {
  //     let amount = parseFloat(status.split(":")[1]) * 100
  //     // @ts-ignore
  //     this.statusTarget.textContent = Price({
  //       value: amount,
  //       currency: JSON.parse(el.dataset.currency!),
  //     })
  //   } else {
  //     // @ts-ignore
  //     this.statusTarget.textContent = I18n.t(`js.hub.tables.${status}`)
  //   }
  // }
}
