import React, { useEffect } from "react"
import { useQuery } from "@apollo/client"
import { getTakingSheetItems } from "../../mutations"
import { LoadingSpinner } from "@react-shared/LoadingSpinner"
import { Product } from "../../graphql/graphql"
import ProductRow from "./ProductRow"

import { t } from "../../i18n"

type Props = {
  locationId: string
  takingSheetId: string
  refresh: any
}

type Category = {
  id: string
  name: string
  products: Product[]
}

export default function TakingSheetItemsList(props: Props) {
  const { locationId, takingSheetId } = props

  const { data, loading, error, refetch } = useQuery(getTakingSheetItems, {
    variables: { locationId, takingSheetId },
    fetchPolicy: "no-cache"
  })

  console.log("Refresh", props.refresh)
  useEffect(() => {
    refetch()
  }, [props.refresh])

  if (loading || !data) {
    return <LoadingSpinner size={"h-8 w-8"} />
  }

  if (error) {
    return <p>Something went wrong</p>
  }

  const items = data!.inventoryTakingSheet!.items
  const preparations = items.filter((item: any) => item.__typename === "Preparation")
  const products: Product[] = items.filter((item: any) => item.__typename === "Product") as Product[]

  let categories = products.map(item => item.category)
  categories = categories.filter((category, index: number, self) => self.findIndex((t) => t.id === category.id) === index)
  categories.sort((a, b) => a.name.localeCompare(b.name))

  const productsByCategory = categories.map(category => {
    return {
      ...category,
      products: products.filter(product => product.category.id === category.id)
    }
  })

  if (items.length == 0) {
    return null
  }

  return (
    <>
      {productsByCategory.map((category: Category) => (
        <div key={category.id}>
          <h3 className={"font-medium mb-2"}>{category.name}</h3>
          <table className={"w-full text-gray-800 text-sm"}>
            <thead className={"text-start font-semibold"}>
              <tr className={"bg-gray-50"}>
                <th className={"text-start font-medium px-3 py-2 w-1/2"}>
                  {t("js.hub.admin.inventory.taking_sheets.table.name")}
                </th>
                <th className={"text-start font-medium px-3 py-2"}>
                  {t("js.hub.admin.inventory.taking_sheets.table.stock_increment")}
                </th>
                <th className={"text-start font-medium px-3 py-2"}>
                  <span className={"sr-only"}>
                    {t("js.common.actions")}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {category.products.map(product => (
                <ProductRow
                  key={product.id}
                  product={product}
                  locationId={locationId}
                  takingSheetId={takingSheetId}
                  refetch={refetch}
                />
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}