import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["number"]

  declare numberTarget: HTMLInputElement

  add(e: Event) {
    this.numberTarget.value += (e.target as HTMLButtonElement).innerHTML
  }

  back() {
    this.numberTarget.value = this.numberTarget.value.slice(0, -1)
  }

  clear() {
    this.numberTarget.value = ""
  }
}
