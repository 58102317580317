import Button from "@react-shared/Button"
import React, { useContext, useEffect, useState } from "react"
import { HubBillPaymentProps } from "./types"
import Price from "@react-shared/Price"
import { t } from "../../i18n"
import { CurrencyField } from "@react-shared/Forms/CurrencyField"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import { useNavigate } from "react-router"
import AppContext from "./AppContext"

function isRound(number: number): boolean {
  return Math.round(number) === number
}

export default function SplitItem(props: HubBillPaymentProps) {
  const currency = useContext(CurrencyContext)
  const appContext = useContext(AppContext)
  const setAppAmount = appContext.setAmount

  const navigate = useNavigate()

  const [selected, setSelected] = useState<{ [key: string]: number[] }>({})

  const [amount, setAmount] = useState<number>(0)
  const [amountStr, setAmountStr] = useState<string>("")
  const [minimum, setMinimum] = useState<number>(0)
  const [canContinue, setCanContinue] = useState<boolean>(false)

  useEffect(() => {
    appContext.setSelectedItems(selected)
  }, [selected])

  useEffect(() => {
    console.log("amount", amount)
    const tip = amount - minimum
    console.log("tip", tip)

    if (!isNaN(tip) && tip > 0) {
      appContext.setTip(tip)
    } else {
      appContext.setTip(0)
    }
  }, [amount, minimum])

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.dataset.id!
    const index = parseInt(event.target.dataset.index!)
    const value = event.target.checked

    const newSelected = {
      ...selected,
      [id]: value ? [...(selected[id] ?? []), index] : selected[id].filter((i) => i !== index),
    }

    setSelected(newSelected)

    const total = Object.keys(newSelected).reduce((sum, id) => {
      const indices = newSelected[id]
      const item = props.items.find((item) => item.id === id)!

      return sum + indices.reduce((sum, index) => sum + item.unit_prices_cents[index], 0)
    }, 0)

    const decimalPlaces = Math.log10(currency.subunits)
    setAmount(total)
    setAppAmount(total)
    setAmountStr((total / currency.subunits).toFixed(decimalPlaces))
    setMinimum(total)
    setCanContinue(true)
  }

  return (
    <div className="w-96 mx-auto select-none">
      <div className="text-lg mb-4 font-medium">{t("js.hub.payment_splitter.title")}</div>

      <div className="space-y-3 mb-4">
        {props.items.map((lineItem) =>
          isRound(lineItem.quantity) ? (
            Array.from({ length: lineItem.quantity }).map((_, i) => (
              <SingleItem
                key={`${lineItem.id}-${i}`}
                lineItem={lineItem}
                index={i}
                cost={lineItem.unit_prices_cents[i]}
                paid={lineItem.paid_indices.includes(i)}
                selected={selected[lineItem.id]?.includes(i) ?? false}
                onSelect={onSelect}
              />
            ))
          ) : (
            <SingleItem
              key={lineItem.id}
              lineItem={lineItem}
              index={0}
              cost={lineItem.unit_prices_cents[0]}
              paid={lineItem.quantity_paid > 0}
              selected={(selected[lineItem.id] || []).length > 0}
              onSelect={onSelect}
            />
          ),
        )}
      </div>

      <div className="mb-4">
        <CurrencyField
          className="flex-1"
          currencyIso={currency.iso}
          amount={amount}
          amountStr={amountStr}
          onChange={(amtCents, amtStr) => {
            setAmount(amtCents)
            setAmountStr(amtStr)
            setAppAmount(amtCents)
            setCanContinue(amtCents >= minimum)
          }}
          subunits={currency.subunits}
        />
        {amount < minimum / currency.subunits && (
          <span className="mt-1 text-sm leading-6 text-red-600 dark:text-red-400 text-ellipsis overflow-hidden">
            {t("js.hub.payment_splitter.minimum", { amount: Price({ cents: minimum }) })}
          </span>
        )}
      </div>

      <div className="flex gap-3">
        <Button
          kind="primary"
          className="ms-auto"
          disabled={Object.values(selected).flat().length == 0 || !canContinue}
          onClick={() => navigate("/terminals")}>
          {t("js.common.continue")}
        </Button>
      </div>
    </div>
  )
}

type SingleItemProps = {
  lineItem: HubBillPaymentProps["items"][0]
  index: number
  cost: number
  paid: boolean
  selected: boolean
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function SingleItem({ lineItem, index, cost, paid, selected, onSelect }: SingleItemProps) {
  return (
    <div className="w-full flex gap-1">
      <label className="flex gap-1 w-full">
        <input
          type="checkbox"
          disabled={paid}
          checked={selected}
          onChange={onSelect}
          data-id={lineItem.id}
          data-index={index}
          className={`mt-0.5 h-4 w-4 border-gray-300 focus:ring-blue-600 me-2 rounded
            ${paid ? "cursor-not-allowed opacity-50" : "text-blue-600"}`}
        />

        <div className={`${paid ? "text-gray-500 line-through" : ""}`}>
          <div className="font-medium text-sm">{lineItem.name}</div>
          <div className="text-xs">{lineItem.formatted_options}</div>
        </div>
      </label>
      <span className="ms-auto">
        <Price cents={cost} />
      </span>
    </div>
  )
}
