import React, { useContext } from "react"
import { HubOrderingModifierOption } from "@hub-ordering/types"
import Price from "@react-shared/Price"
import { ModifiersContext } from "../../../GuestOrdering/pages/ItemModal/ModifiersContext"
import PosButton from "@hub-ordering/PosButton"

type Props = {
  modifierGroupId: string
  modifierOption: HubOrderingModifierOption
  disabled: boolean
}

export default function MultiModifierOption(props: Props) {
  const { disabled, modifierGroupId, modifierOption } = props
  const { modifiers, setMultiModifier } = useContext(ModifiersContext)

  const count = modifiers.find(
    (group) => group.modifierGroupId == modifierGroupId,
  )?.modifierOptions.find(
    (option) => option.modifierOptionId == modifierOption.id,
  )?.quantity || 0

  const increment = () => {
    if (disabled) {
      return
    }

    setMultiModifier(modifierGroupId!, {
      name: modifierOption.name,
      modifierOptionId: modifierOption.id,
      quantity: count + 1,
      unitPriceCents: modifierOption.priceCents
    }, count + 1)
  }

  const decrement = () => {
    if (count == 0) {
      return
    }

    setMultiModifier(modifierGroupId!, {
      name: modifierOption.name,
      modifierOptionId: modifierOption.id,
      quantity: count - 1,
      unitPriceCents: modifierOption.priceCents
    }, count - 1)
  }

  const isSelected = count > 0

  return (
    <PosButton
      tag="div"
      className={`p-3 cursor-pointer border border-cyan-800 ${isSelected ? "bg-cyan-800" : "white"
        }`}
      text_color={isSelected ? "text-white" : "text-black dark:text-white"}
      onClick={increment}
    >
      {isSelected && (
        <>
          <div
            className="p-1"
            onClick={(e) => {
              e.stopPropagation()
              decrement()
            }}
          >
            <button
              type="button"
              aria-label="Decrease quantity"
              className={`w-5 h-5 text-xl flex items-center justify-center border-2 rounded-full
                ${isSelected ? "border-white-700 text-white-700" : "text-white border-gray-300"}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
              </svg>
            </button>
          </div>
          <div className="mx-2">{count}</div>
        </>
      )}
      <div className="flex-grow text-start">
        <div className={`${disabled ? "text-gray-400" : ""}`}>{modifierOption.name}</div>
        {modifierOption.priceCents != 0 && (
          <div className={`text-xs ${isSelected ? "text-white" : "text-gray-600 dark:text-white"}`}>
            <Price cents={modifierOption.priceCents} />
          </div>
        )}
      </div>
      <div className="p-1">
        <button
          type="button"
          aria-label="Increase quantity"
          className={`w-5 h-5 text-xl flex items-center justify-center border-2 rounded-full
            ${disabled
              ? "hidden"
              : isSelected
                ? "border-white text-white"
                : "border-gray-700 text-gray-700"
            }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
        </button>
      </div>
    </PosButton>
  )
}
