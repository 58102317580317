import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "overlay"]

  declare readonly sidebarTarget: HTMLElement
  declare readonly overlayTarget: HTMLElement

  open() {
    this.sidebarTarget.classList.remove("-translate-x-full")
    this.overlayTarget.classList.remove("pointer-events-none")
    this.overlayTarget.style.opacity = "1"
  }

  close() {
    this.sidebarTarget.classList.add("-translate-x-full")
    this.overlayTarget.classList.add("pointer-events-none")
    this.overlayTarget.style.opacity = "0"
  }
}
