import React, { useContext } from "react"
import Transaction from "./Transaction"
import Price from "@react-shared/Price"
import { HubBill } from "@react-shared/types"
import { LineItemsContext } from "@hub-ordering/LineItemsContext"
import { HubOrderingItem, isLineItem } from "@hub-ordering/types"
import LineItem from "./LineItem"
import OpenLineItem from "./OpenLineItem"

import { t } from "../../i18n"

type HubBillTicketProps = {
  bill: HubBill
  items: HubOrderingItem[]
}

export default function HubBillTicket(props: HubBillTicketProps) {
  const { bill } = props

  const { lineItems } = useContext(LineItemsContext)

  const newItemsTotal = lineItems.reduce((total, lineItem) => {
    return total + lineItem.unitPriceCents * lineItem.quantity
  }, 0)

  return (
    <div className="w-full md:w-auto h-100 bg-gray-100 dark:bg-gray-800 p-4 rounded-lg text-sm font-mono">
      {bill.created_at && (
        <div className="bg-green-200 dark:bg-green-700 p-2 rounded-lg font-medium text-center">
          {(bill.at_table && (
            <span>{t("js.hub.ordering.seated_at", { time: bill.created_at })}</span>
          )) || <span>{t("js.hub.ordering.placed_at", { time: bill.created_at })}</span>}
        </div>
      )}

      {lineItems.map((lineItem, index) => {
        if (isLineItem(lineItem)) {
          const item = props.items.find((item) => item.id === lineItem.itemId)!

          return <LineItem key={index} item={item} lineItem={lineItem} index={index} />
        } else {
          // Open line item
          return <OpenLineItem key={index} lineItem={lineItem} index={index} />
        }
      })}

      <div className="flex font-semibold mt-2">
        <div className="flex-1">
          <span>{t("js.hub.ordering.total")}</span>
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={bill.net_sales_cents + newItemsTotal} />
          </span>
        </div>
      </div>

      {bill.transactions.map((transaction) => (
        <div key={transaction.id} className="flex mt-2 items-center">
          <Transaction transaction={transaction} />
        </div>
      ))}

      <div className="flex font-semibold mt-2">
        <div className="flex-1">
          <span>{t("js.hub.ordering.payments")}</span>
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={bill.net_payments_cents} />
          </span>
        </div>
      </div>

      <div className="flex font-semibold mt-2">
        <div className="flex-1">
          <span>{t("js.hub.ordering.tips")}</span>
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={bill.total_tips_cents} />
          </span>
        </div>
      </div>

      <div className="flex font-semibold mt-2">
        <div className="flex-1">
          <span>{t("js.hub.ordering.balance")}</span>
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={bill.balance_cents + newItemsTotal} />
          </span>
        </div>
      </div>
    </div>
  )
}
