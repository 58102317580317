import React from "react"
import Dialog from "../GuestOrdering/Dialog"
import { useNavigate } from "react-router"
import ItemSearch from "./ItemSearch"
import Button from "@react-shared/Button"
import { useMutation } from "@apollo/client"
import { addCategoryToInventoryTakingSheet, addItemToInventoryTakingSheet } from "../../mutations"
import CategorySearch from "./CategorySearch"

import { t } from "../../i18n"

type Props = {
  locationId: string
  takingSheetId: string
  refreshItems: () => void
}

export default function AddItemDialog(props: Props) {
  const navigate = useNavigate()

  const [addType, setAddType] = React.useState<"single" | "category">("single")
  const [selectedProduct, setSelectedProduct] = React.useState<string | null>(null)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)

  const [addSingleProduct, addItemState] = useMutation(addItemToInventoryTakingSheet)
  const [addCategory, addCategoryState] = useMutation(addCategoryToInventoryTakingSheet)

  const handleConfirm = async () => {
    if (addType === "single" && selectedProduct) {
      await addSingleProduct({
        variables: {
          locationId: props.locationId,
          takingSheetId: props.takingSheetId,
          itemId: selectedProduct
        }
      })

      props.refreshItems()
      navigate(-1);
    } else if (addType === "category" && selectedCategory) {
      await addCategory({
        variables: {
          locationId: props.locationId,
          takingSheetId: props.takingSheetId,
          categoryId: selectedCategory
        }
      })

      props.refreshItems()
      navigate(-1);
    }
  }

  return (
    <Dialog onClose={() => navigate(-1)} withDarkMode={true}>
      <div className={"p-6"}>
        <h3 className={"text-lg font-semibold mb-4"}>
          {t("js.hub.admin.inventory.taking_sheets.form.add.title")}
        </h3>

        <label className={"w-full block mb-4"}>
          <input
            className={"h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"}
            type={"radio"}
            name={"addType"}
            value={"single"}
            onChange={() => setAddType("single")}
            checked={addType === "single"} />
          <span className={"ms-3 text-sm font-medium leading-6 text-gray-900 dark:text-white"}>
            {t("js.hub.admin.inventory.taking_sheets.form.add.single_item")}
          </span>
        </label>

        {addType === "single" && (
          <div className={"my-4 ms-8"}>
            <ItemSearch onChange={id => setSelectedProduct(id)} />
          </div>
        )}

        <label className={"w-full block mb-4"}>
          <input
            className={"h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"}
            type={"radio"}
            name={"addType"}
            value={"category"}
            onChange={() => setAddType("category")}
            checked={addType === "category"} />
          <span className={"ms-3 text-sm font-medium leading-6 text-gray-900 dark:text-white"}>
            {t("js.hub.admin.inventory.taking_sheets.form.add.category")}
          </span>
        </label>

        {addType === "category" && (
          <div className={"my-4 ms-8"}>
            <CategorySearch onChange={id => setSelectedCategory(id)} />
          </div>
        )}

        <div className={"text-end mt-4"}>
          <Button
            kind={"plain"}
            onClick={() => navigate(-1)}>
            {t("js.common.cancel")}
          </Button>
          <Button
            kind={"primary"}
            className={"ms-4"}
            disabled={(addType === "single" && !selectedProduct) || (addType === "category" && !selectedCategory)}
            loading={addItemState.loading}
            onClick={() => handleConfirm()}>
            {t("js.common.confirm")}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}