import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["advancedSettings"]

  declare advancedSettingsTarget: HTMLElement
  declare hasAdvancedSettingsTarget: boolean

  connect() {
    if (this.hasAdvancedSettingsTarget) {
      this.advancedSettingsTarget.classList.add("hidden")

      this.advancedSettingsTarget.querySelectorAll("a").forEach((link) => {
        if (link.classList.contains("active")) {
          this.advancedSettingsTarget.classList.remove("hidden")
        }
      })
    }
  }

  toggleAdvancedSettings(e: Event) {
    e.preventDefault()

    if (this.hasAdvancedSettingsTarget) {
      this.advancedSettingsTarget.classList.toggle("hidden")
    }
  }
}
