import React from "react"
import { HubOrderingItem } from "@hub-ordering/types"
import ModifierGroup from "./ModifierGroup"
import SizePicker from "./SizePicker"

type Props = {
  item: HubOrderingItem,
  showErrors: boolean,
}

export default function ModifierGroups({ item, showErrors }: Props) {
  return (
    <div>
      {item.sizes && <SizePicker />}
      {item.modifier_groups.map((modifierGroupId) => (
        <ModifierGroup key={modifierGroupId} modifierGroupId={modifierGroupId} showErrors={showErrors} />
      ))}
    </div>
  )
}
