import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import I18n from "../../i18n"
import Price from "@react-shared/Price"
import { AppContext, PropsContext } from "./Contexts"
import currencySymbol from "@react-shared/currencySymbol"
import Button from "@react-shared/Button"

export default function EntireBillSummaryCard() {
  const { refund_amount, setRefundAmount } = useContext(AppContext)
  const { payments, currency } = useContext(PropsContext)

  const navigate = useNavigate()

  const [isInvalid, setIsInvalid] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const field = useRef<HTMLInputElement>(null)
  const total_unrefunded = payments.reduce((acc, p) => acc + p.unrefunded_amount_cents, 0)

  const update = (val: string) => {
    setIsFilled(val.length > 0)

    if (!val.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setIsInvalid(true)
      setRefundAmount(0)
      return
    }

    const cents = parseFloat(val)
    const amount = Math.round(cents * 100)
    if (amount > total_unrefunded || amount <= 0) {
      setIsInvalid(true)
      setRefundAmount(0)
    } else {
      setIsInvalid(false)
      setRefundAmount(amount)
    }
  }

  useEffect(() => {
    if (field.current) {
      field.current.focus()
      update(field.current.value)
    }
  })

  return (
    <>
      <div className="mb-4">
        <h3 className="mb-3 font-semibold">{I18n.t("js.hub.refunds.custom.title")}</h3>
        <label htmlFor="custom-refund-amount" className="mb-1 text-sm font-semibold">
          {I18n.t("js.hub.refunds.custom.description")}
        </label>
        <div className="btn-group w-full mb-1">
          <div className="bg-gray-300 dark:bg-gray-600 flex items-center px-3 text-xs font-medium">
            {currencySymbol(currency.iso)}
          </div>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset \
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-xs sm:leading-6 \
                         bg-white dark:bg-white/5 \
                         text-gray-900 dark:text-white \
                         ring-gray-300 dark:ring-white/10 \
                         focus:ring-indigo-600 dark:focus:ring-indigo-500 flex-1"
            onChange={(e) => update(e.target.value)}
            autoComplete="off"
            autoFocus={true}
            inputMode="decimal"
            ref={field}
            id="custom-refund-amount"
          />
        </div>
        <div className="text-xs">
          {I18n.t("js.hub.refunds.custom.limit", {
            amount: Price({ cents: total_unrefunded }),
          })}
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <Button kind="plain" onClick={() => navigate(-1)}>
          {I18n.t("js.common.back")}
        </Button>
        <Button disabled={refund_amount <= 0} kind="primary" onClick={() => navigate("/summary")}>
          {I18n.t("js.common.continue")}
        </Button>
      </div>
    </>
  )
}
