import React from "react"
import Price from "@react-shared/Price"
import Button from "@react-shared/Button"

import { t } from "../../../../i18n"

type Props = {
  price_cents: number
  addToOrder: (quantity: number) => void
}

export default function Footer({ price_cents, addToOrder }: Props) {
  const [quantity, setQuantity] = React.useState(1)

  return (
    <div className="pt-4 border-t">
      <div className="mb-6">
        <div className="mx-auto w-48 flex items-center place-content-between">
          <button
            type="button"
            aria-label={t("js.hub.ordering.decrease")}
            onClick={() => setQuantity((q) => Math.max(1, q - 1))}
            className="w-8 h-8 text-xl flex items-center justify-center border rounded-full border-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
            </svg>
          </button>
          <span className="text-xl font-bold">{quantity}</span>
          <button
            type="button"
            aria-label={t("js.hub.ordering.increase")}
            onClick={() => setQuantity((q) => q + 1)}
            className="w-8 h-8 text-xl flex items-center justify-center border rounded-full border-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
          </button>
        </div>
      </div>
      <div>
        <Button
          onClick={() => addToOrder(quantity)}
          kind="primary"
          className="rounded-md w-full py-3 text-center text-white bg-slate-800 font-medium">
          {t("js.hub.ordering.add_button", { amount: Price({ cents: price_cents * quantity }) })}
        </Button>
      </div>
    </div>
  )
}
