type RequestOptions = {
  asText?: boolean
}

const csrfToken = document.querySelector<HTMLMetaElement>('[name=csrf-token]')?.content

export async function request<Res, Req>(
  path: string,
  method: string = "GET",
  body: Req | null = null,
  options: RequestOptions = {},
): Promise<Res> {
  const headers: any = {
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken,
  }

  const response = await fetch(path, {
    method,
    body: body ? JSON.stringify(body) : null,
    headers,
  })

  if (options.asText) {
    return response.text() as any
  }

  return response.json()
}

export function baseUrl(): string {
  return ""
}
