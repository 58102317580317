import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDialogElement> {
  static targets = ["form"]

  declare formTarget: HTMLFormElement
  declare hasFormTarget: boolean

  connect() {
    this.element.addEventListener("click", this.testClose)
    this.element.showModal()

    if (this.hasFormTarget) {
      this.formTarget.addEventListener("turbo:submit-end", this.close)
    }
  }

  disconnect() {
    this.element.removeEventListener("click", this.testClose)
    this.element.remove()
  }

  testClose = (e: Event) => {
    if (e.target == this.element) {
      this.close()
    }
  }

  close = () => {
    this.element.close()
    this.element.remove()
  }
}
