import React from "react"
import Downshift from 'downshift'
import Label from "@react-shared/Forms/Label"
import TextField from "@react-shared/Forms/TextField"

type Props = {
  onChange: (selection: any) => void
  onInputChanged?: (value: string) => void
  itemToString: (item: any) => string
  label: React.ReactNode
  data: any[]
  itemToKey: (item: any) => string
  renderItem: (item: any) => React.ReactNode
}

export default function Combobox(props: Props) {
  return (
    <Downshift
      onChange={props.onChange}
      itemToString={props.itemToString}
      onInputValueChange={props.onInputChanged}
    >
      {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
        <div>
          <Label {...getLabelProps()}>{props.label}</Label>
          <div className={"relative"}>
            <div
              {...getRootProps({}, {suppressRefError: true})}
            >
              <TextField {...getInputProps()} />
            </div>
            <ul {...getMenuProps()} className={`${isOpen ? "py-1" : ""} absolute w-full z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white dark:bg-gray-600 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
              {isOpen
                ? props.data
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: props.itemToKey(item),
                        index,
                        item
                      })}
                      className={`${highlightedIndex === index ? "bg-indigo-600 text-white" : "text-gray-900 dark:text-white"} relative cursor-default select-none py-2 ps-3 pe-9`}
                    >
                      <div className="flex items-center">
                        <span
                          className={(selectedItem === item ? "font-semibold" : "font-normal") + " block truncate"}
                        >
                          {props.renderItem(item)}
                        </span>
                        {selectedItem === item ? (
                          <span
                            className={
                              (highlightedIndex === index ? 'text-white' : 'text-indigo-600') +
                              'ms-auto absolute inset-y-0 right-0 flex items-center pe-4'
                            }
                          >
                              ✔
                            </span>
                        ) : null}
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  )
}
