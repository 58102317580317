import React from 'react'

export default function Waiting({ height = 24, className }: { height?: number, className?: string }) {
  return (
    <svg className={className} width={height} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" transform="translate(12, 12) scale(0)">
        <animateTransform id="spinner_XR07" begin="0;spinner_npiH.begin+0.4s" attributeName="transform" calcMode="spline" type="translate" dur="2s" values="12 12;0 0" keySplines=".52,.6,.25,.99" />
        <animateTransform begin="0;spinner_npiH.begin+0.4s" attributeName="transform" calcMode="spline" additive="sum" type="scale" dur="2s" values="0;1" keySplines=".52,.6,.25,.99" />
        <animate begin="0;spinner_npiH.begin+0.4s" attributeName="opacity" calcMode="spline" dur="2s" values="1;0" keySplines=".52,.6,.25,.99" />
      </path>
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" transform="translate(12, 12) scale(0)">
        <animateTransform id="spinner_r5ci" begin="spinner_XR07.begin+0.4s" attributeName="transform" calcMode="spline" type="translate" dur="2s" values="12 12;0 0" keySplines=".52,.6,.25,.99" />
        <animateTransform begin="spinner_XR07.begin+0.4s" attributeName="transform" calcMode="spline" additive="sum" type="scale" dur="2s" values="0;1" keySplines=".52,.6,.25,.99" />
        <animate begin="spinner_XR07.begin+0.4s" attributeName="opacity" calcMode="spline" dur="2s" values="1;0" keySplines=".52,.6,.25,.99" />
      </path>
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" transform="translate(12, 12) scale(0)">
        <animateTransform id="spinner_npiH" begin="spinner_XR07.begin+0.8s" attributeName="transform" calcMode="spline" type="translate" dur="2s" values="12 12;0 0" keySplines=".52,.6,.25,.99" />
        <animateTransform begin="spinner_XR07.begin+0.8s" attributeName="transform" calcMode="spline" additive="sum" type="scale" dur="2s" values="0;1" keySplines=".52,.6,.25,.99" />
        <animate begin="spinner_XR07.begin+0.8s" attributeName="opacity" calcMode="spline" dur="2s" values="1;0" keySplines=".52,.6,.25,.99" />
      </path>
    </svg>
  )
}
