import { gql } from "../graphql"

export const hubPlaceOrder = gql(`
  mutation HubPlaceOrder($lineItems: [HubOrderingLineItem!]!, $billId: ID!) {
    hubPlaceOrder(input: { lineItems: $lineItems, billId: $billId, serviceType: AT_TABLE }) {
      accepted
      errors {
        path, code, message
      }
    }
  }`
)

export const hubPlaceOffPremiseOrder = gql(`
  mutation HubPlaceOffPremiseOrder(
    $lineItems: [HubOrderingLineItem!]!,
    $locationId: ID!,
    $customerName: String,
    $customerEmail: String,
    $customerPhone: String,
    $serviceType: ServiceTypeEnum!
  ) {
    hubPlaceOrder(input: {
      locationId: $locationId,
      lineItems: $lineItems,
      serviceType: $serviceType,
      customerName: $customerName,
      customerEmail: $customerEmail,
      customerPhone: $customerPhone,
    }) {
      accepted
      errors {
        path, code, message
      }
      bill {
        id
      }
    }
  }`
)