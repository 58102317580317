type TimeRange = { start: number | null; end: number | null };

function preprocessTimeRanges(objects: TimeRange[]): TimeRange[] {
  // Flatten objects that span over midnight into two separate objects
  const flattenedObjects: TimeRange[] = [];
  objects.forEach(obj => {
    if (obj.end! < obj.start!) { // If the object spans over midnight
      flattenedObjects.push({ start: obj.start, end: obj.end! + 1440 });
    } else {
      flattenedObjects.push(obj);
    }
  });
  return flattenedObjects;
}

export function mergeOverlappingTimeRanges(objects: TimeRange[]): TimeRange[] {
  // Separate objects with null values
  let validObjects = objects.filter(obj => obj.start !== null && obj.end !== null);
  const nullObjects = objects.filter(obj => obj.start === null || obj.end === null);

  // Split objects that span over midnight
  validObjects = preprocessTimeRanges(validObjects);

  // Sort valid objects by start time
  validObjects.sort((a, b) => a.start! - b.start!);

  const mergedObjects: TimeRange[] = [];

  // Merge overlapping intervals
  for (let obj of validObjects) {
    if (mergedObjects.length === 0) {
      mergedObjects.push(obj);
      continue;
    }

    let last = mergedObjects[mergedObjects.length - 1];
    // Check if current object overlaps with the last one
    if (obj.start! <= last.end!) {
      // Merge the two objects
      last = { start: last.start, end: Math.max(last.end!, obj.end!) };
      mergedObjects[mergedObjects.length - 1] = last;
    } else if (obj.end! === 1440 && last.start === 0) {
      // Merge the two objects that span over midnight
      last = { start: obj.start, end: last.end };
      mergedObjects[mergedObjects.length - 1] = last;
    } else if (obj.start! > last.end! && obj.end! > last.end! && last.end! < last.start!) {
      // Scenario: 8pm-6am, 9pm-10pm
      // Do not merge the two objects, ignore the current object
    } else {
      // Append the current object to the result
      mergedObjects.push(obj);
    }
  }

  // Clean up end times over 1440
  mergedObjects.forEach(obj => {
    if (obj.end! > 1440) {
      obj.end = obj.end! - 1440;
    }
  });

  // Append null-containing objects to the end of the result
  return [...mergedObjects, ...nullObjects];
}