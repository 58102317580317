import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import I18n from "../../i18n"
import Price from "@react-shared/Price"
import { AppContext, PropsContext } from "./Contexts"
import { RefundTypes } from "./types"
import Button from "@react-shared/Button"

export default function RefundType() {
  const { refund_type, setRefundType } = useContext(AppContext)
  const { payments } = useContext(PropsContext)
  const navigate = useNavigate()

  const total_unrefunded = payments.reduce((acc, p) => acc + p.unrefunded_amount_cents, 0)

  let nextPage: string = ""
  switch (refund_type) {
    case RefundTypes.CustomAmount:
      nextPage = "/custom"
      break
    default:
      nextPage = "/summary"
      break
  }

  return (
    <>
      <div>
        <h3 className="mb-3 font-semibold">{I18n.t("js.hub.refunds.type.title")}</h3>

        <div className="rounded rounded-md border divide-y text-sm mb-4">
          <label className="block p-4 cursor-pointer flex gap-3 items-center">
            <input
              type="radio"
              name="refund_type"
              checked={refund_type == RefundTypes.EntireBill}
              onChange={() => setRefundType(RefundTypes.EntireBill)}
              value="all"
            />
            <div>
              <div>{I18n.t("js.hub.refunds.type.entire_bill")}</div>
              <div className="text-xs">
                {I18n.t("js.hub.refunds.type.entire_bill_desc", {
                  count: payments.filter((p) => p.unrefunded_amount_cents > 0).length,
                  amount: Price({ cents: total_unrefunded }),
                })}
              </div>
            </div>
          </label>
          <label className="block p-4 cursor-pointer flex gap-3 items-center">
            <input
              type="radio"
              name="refund_type"
              checked={refund_type == RefundTypes.CustomAmount}
              onChange={() => setRefundType(RefundTypes.CustomAmount)}
              value="custom"
            />
            <div>
              <div>{I18n.t("js.hub.refunds.type.custom")}</div>
              <div className="text-xs">
                {I18n.t("js.hub.refunds.type.custom_desc")}
              </div>
            </div>
          </label>
        </div>
      </div>
      <div>
        <Button kind="primary" onClick={() => navigate(nextPage)}>
          {I18n.t("js.common.continue")}
        </Button>
      </div>
    </>
  )
}
