import { gql } from "../graphql"

export const guestPlaceTableOrder = gql(`
  mutation GuestPlaceTableOrder($lineItems: [LineItemInput!]!, $locationId: ID!, $tableId: ID!) {
    guestPlaceTableOrder(input: { lineItems: $lineItems, locationId: $locationId, tableId: $tableId }) {
      accepted
      errors { code, path, message }
    }
  }`
)
