import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LinearScale, CategoryScale, Tooltip, PointElement, LineElement } from 'chart.js';
import useDarkMode from '@react-shared/useDarkMode';

ChartJS.register(LineElement, LinearScale, CategoryScale, Tooltip, PointElement)

type Props = {
  labels: string[]
  data: { [key: number]: number }
  start: string
  end: string
  currency: {
    iso: string,
    subunits: number,
  }
}

// start and end are two YYYY-MM-DD strings
// this function fills in the missing days in data with 0
function dataValues(start: string, end: string, data: { [key: string]: number }) {
  const startDate = new Date(start)
  const endDate = new Date(end)

  const days = []
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    days.push(date.toISOString().slice(0, 10))
  }

  const dataWithZeros = days.reduce<{ [key: string]: number }>((acc, day) => {
    acc[day] = data[day] || 0
    return acc
  }, {})

  return Object.values(dataWithZeros)
}

export default function DailySalesLineChart(props: Props) {
  const isDark = useDarkMode()

  return (
    <Line
      data={{
        labels: props.labels,
        datasets: [
          {
            data: dataValues(props.start, props.end, props.data).map((value) => value / props.currency.subunits),
            backgroundColor: isDark ? "white" : 'rgb(30, 41, 59)',
            borderColor: isDark ? "white" : 'rgb(30, 41, 59)',
          }
        ]
      }}

      options={{
        maintainAspectRatio: false,

        font: {
          family: 'Inter',
        },
        plugins: {
          tooltip: {
            enabled: true,
            displayColors: false,
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: isDark ? "white" : "#666",
              format: {
                style: 'currency',
                currency: props.currency.iso
              }
            }
          },
          x: {
            ticks: {
              color: isDark ? "white" : "#666",
            }
          }
        }
      }}
    />
  )
}
