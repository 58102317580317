import * as Sentry from "@sentry/browser"
import { APP_VERSION } from "./AppVersion"

if (process.env.NODE_ENV === "production") {
  const dsn = document.querySelector("meta[name='sentry-dsn']")?.getAttribute("content")!

  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing()],
    release: APP_VERSION,
  })
}
