import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reloadTaxInformation(e: Event) {
    const countryIso = (e.currentTarget as HTMLSelectElement).value
    const taxInformation = document.querySelector("#tax-information")! as HTMLElement

    if (countryIso) {
      // @ts-ignore
      taxInformation.src = `/hub/admin/payment-beneficiaries/new?payment_beneficiary[address_country_iso]=${countryIso}`;
      // @ts-ignore
      taxInformation.reload()
    }
  }
}
