import PosButton from "@hub-ordering/PosButton"
import React, { useContext } from "react"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../../i18n"

type Props = {
  courseId: string | null
  setCourseId: (courseId: string | null) => void
}

export default function CoursePicker({ courseId, setCourseId }: Props) {
  const courses = useContext(MenuContext).courses

  return (
    <>
      <div className="text-lg font-semibold mb-2 mt-6">{t("js.hub.ordering.course")}</div>
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        {courses.map((course) => {
          const selected = course.id === courseId

          return (
            <PosButton
              key={course.id}
              tag="label"
              className={`p-3 cursor-pointer border border-cyan-800 ${selected ? "bg-cyan-800" : "white"
                }`}
              text_color={selected ? "text-white" : "text-black dark:text-white"}>
              <input
                type="radio"
                className={`h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 me-2`}
                value={course.id}
                checked={selected}
                onChange={() => setCourseId(course.id)}
                name="item-course"
              />
              <div className="flex-grow text-start">
                <div>{course.name}</div>
              </div>
            </PosButton>
          )
        })}
      </ul>
    </>
  )
}
