import React, { useCallback } from "react"
import { useLazyQuery } from "@apollo/client"
import { inventorySearch } from "../../mutations"
import debounce from "../../shared/debounce"
import Combobox from "@react-shared/Forms/Combobox"

import { t } from "../../i18n"

type Props = {
  onChange: (selection: string) => void
}

export default function ItemSearch(props: Props) {
  const [lastResult, setLastResult] = React.useState<any[]>([])
  const [search] = useLazyQuery(inventorySearch, {
    onCompleted: (data) => {
      setLastResult(data!.inventorySearch)
    }
  })

  const debouncedSearch = useCallback(debounce(search, 150), [])

  return (
    <Combobox
      onChange={selection => props.onChange(selection.id)}
      onInputChanged={value => {
        if (value) {
          debouncedSearch({ variables: { query: value } })
        }
      }}
      itemToString={item => (item ? item.name : '')}
      label={t("js.hub.admin.inventory.taking_sheets.form.add.item_label")}
      data={lastResult}
      itemToKey={item => item.id}
      renderItem={item => item.name} />
  )
}
