import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["code"]

  declare codeTarget: HTMLElement

  toggle() {
    this.codeTarget.classList.toggle("blur-sm")
  }
}
