import React, { useEffect } from "react"
import toast, { Toaster } from "react-hot-toast"

export default function ToastApp() {
  useEffect(() => {
    const listener = (e: CustomEvent) => {
      console.log("Message received", e.detail)
      const { detail } = e
      const { type, message, options } = detail

      if (type === "error") {
        toast.error(message, options)
      } else if (type === "success") {
        toast.success(message, options)
      }
    }

    document.addEventListener("qly:toast", listener as EventListener)

    return () => {
      document.removeEventListener("qly:toast", listener as EventListener)
    }
  }, [])

  return <Toaster />
}
