import React, { useContext } from "react"
import ModifierOption from "./ModifierOption"
import { ModifiersContext } from "../../../GuestOrdering/pages/ItemModal/ModifiersContext"
import { MenuContext } from "@hub-ordering/MenuContext"
import { t } from "../../../../i18n"

type Props = {
  modifierGroupId: string,
  showErrors: boolean,
}

export default function ModifierGroup({ modifierGroupId, showErrors }: Props) {
  const { getModifierGroup } = useContext(MenuContext)

  const modifierGroup = getModifierGroup(modifierGroupId)

  if (!modifierGroup) {
    return null
  }

  const zeroOrMore = modifierGroup.multiple_selection
  const min = modifierGroup.multiple_selection ? (modifierGroup.min_selections || 0) : 1
  const max = modifierGroup.max_selections || Infinity

  const { modifiers } = useContext(ModifiersContext)

  const totalCount = modifiers
    .find((group) => group.modifierGroupId === modifierGroupId)
    ?.modifierOptions.reduce((total, option) => total + option.quantity, 0) || 0

  return (
    <>
      <div className="text-lg font-semibold mb-2 mt-6">{modifierGroup.name}</div>
      {showErrors && totalCount < min && (
        <div className="text-red-600 mb-2">
          {t("js.hub.ordering.please_select_at_least", { count: min })}
        </div>
      )}
      {showErrors && totalCount > max && (
        <div className="text-red-600 mb-2">
          {t("js.hub.ordering.please_select_no_more_than", { count: max })}
        </div>
      )}
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        {modifierGroup.modifier_options.map((modifierOption) => (
          <ModifierOption
            key={modifierOption.id}
            type={zeroOrMore ? "checkbox" : "radio"}
            modifierGroupId={modifierGroupId}
            modifierOption={modifierOption}
            disabled={totalCount >= max}
          />
        ))}
      </ul>
    </>
  )
}
