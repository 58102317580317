import React from "react"
import { useNavigate } from "react-router"

type Props = {
  segments: ({
    name: string
    url: string | null
  } | null)[]
}

function Chevron() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="inline w-4 h-4 mx-1">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
  )
}

export default function Breadcrumb({ segments }: Props) {
  const navigate = useNavigate()

  const valid = segments.filter((x) => x !== null) as { name: string; url: string }[]

  return (
    <h2 className="font-semibold mb-3">
      {valid.map((segment, index) => (
        <span key={segment.name}>
          {index > 0 && <Chevron />}
          <button onClick={() => segment.url && navigate(segment.url, { replace: true })}>
            {segment.name}
          </button>
        </span>
      ))}
    </h2>
  )
}
