import React, { useContext, useState } from "react"

import { t } from "../../../i18n"
import Dialog from "../../GuestOrdering/Dialog"

import { useLocation, useNavigate } from "react-router"
import TextField from "@react-shared/Forms/TextField"
import Button from "@react-shared/Button"
import { LineItemsContext } from "@hub-ordering/LineItemsContext"
import { useMutation } from "@apollo/client"
import { hubPlaceOffPremiseOrder } from "../../../mutations"
import { ServiceTypeEnum } from "../../../graphql/graphql"
import { MenuContext } from "@hub-ordering/MenuContext"
import { isOpenLineItem } from "@hub-ordering/Sidebar"

export default function ConfirmTakeout() {
  const navigate = useNavigate()
  const location = useLocation()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const { lineItems } = useContext(LineItemsContext)
  const { location_id } = useContext(MenuContext)

  const [confirmOrder, { loading }] = useMutation(hubPlaceOffPremiseOrder, {
    variables: {
      locationId: location_id,
      serviceType: ServiceTypeEnum.Takeaway,
      customerName: name,
      customerEmail: email,
      customerPhone: phone,
      lineItems: lineItems.map((lineItem) => {
        if (isOpenLineItem(lineItem)) {
          return {
            openLineItem: {
              openItemId: lineItem.openItemId,
              name: lineItem.name,
              quantity: lineItem.quantity,
              unitPriceCents: lineItem.unitPriceCents,
              courseId: lineItem.courseId,
            }
          }
        } else {
          return {
            menuLineItem: {
              menuId: lineItem.menuId,
              menuGroupId: lineItem.menuGroupId,
              itemId: lineItem.itemId,
              sizeId: lineItem.sizeId,
              quantity: lineItem.quantity,
              courseId: lineItem.courseId,
              notes: lineItem.notes,
              modifiers: lineItem.modifiers.map((group) => ({
                modifierGroupId: group.modifierGroupId,
                modifierOptions: group.modifierOptions.map((option) => ({
                  modifierOptionId: option.modifierOptionId,
                  quantity: option.quantity
                }))
              }))
            }
          }
        }
      })
    },
    onCompleted: (data) => {
      if (data.hubPlaceOrder?.accepted) {
        window.location.href = "/hub/bills/" + data.hubPlaceOrder.bill?.id
      }
    }
  })

  return (
    <Dialog withDarkMode={true} onClose={() => { navigate(location.state.previousLocation) }}>
      <form onSubmit={confirmOrder}>
        <div className="flex flex-col gap-4 p-6">
          <h1 className="text-2xl font-bold">{t("js.hub.ordering.confirm_takeout.title")}</h1>

          <div>
            <label className="mb-1 text-sm font-semibold">
              {t("js.hub.ordering.confirm_takeout.name")}
              <span className="text-gray-500 ms-1">{t("js.hub.ordering.confirm_takeout.optional")}</span>
            </label>
            <TextField
              value={name}
              onChange={(e) => { setName(e.target.value); e.preventDefault() }}
            />
          </div>
          <div>
            <label className="mb-1 text-sm font-semibold">
              {t("js.hub.ordering.confirm_takeout.email")}
              <span className="text-gray-500 ms-1">{t("js.hub.ordering.confirm_takeout.optional")}</span>
            </label>
            <TextField
              value={email}
              pattern=".+@.+\..+"
              onChange={(e) => { setEmail(e.target.value); e.preventDefault() }}
            />
          </div>

          <Button
            kind="primary"
            isSubmit={true}
          >{t("js.hub.ordering.confirm_takeout.confirm")}</Button>
        </div>
      </form>
    </Dialog>
  )
}
