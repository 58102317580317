import { createContext } from "react"
import {
  HubOrderingItem,
  HubOrderingMenu,
  HubOrderingMenuGroup,
  HubOrderingModifierGroup,
  HubOrderingProps,
} from "./types"

type MenuContextType = HubOrderingProps & {
  getMenu(menuId: string): HubOrderingMenu | undefined
  getMenuGroup(menuGroupId: string): HubOrderingMenuGroup | undefined
  getItem(itemId: string): HubOrderingItem | undefined
  getModifierGroup(modifierGroupId: string): HubOrderingModifierGroup | undefined
}

export const MenuContext = createContext<MenuContextType>({
  getMenu: (_menuId: string) => ({}) as HubOrderingMenu,
  getMenuGroup: (_menuGroupId: string) => ({}) as HubOrderingMenuGroup,
  getItem: (_itemId: string) => ({}) as HubOrderingItem,
  getModifierGroup: (_modifierGroupId: string) => ({}) as HubOrderingModifierGroup,

  location_id: "",
  currency_iso: "",
  currency_subunits: 0,
  menus: [],
  menu_groups: [],
  items: [],
  modifier_groups: [],
  courses: [],
  open_items: [],

  bill: {
    id: "",
    at_table: false,
    created_at: "",
    transactions: [],
    net_sales_cents: 0,
    net_payments_cents: 0,
    balance_cents: 0,
  },
})
