import Button from "@react-shared/Button"
import React, { useContext, useEffect, useState } from "react"
import SegmentedRoundDisplay from "react-segmented-round-display"
import { t } from "../../i18n"
import { HubBillPaymentProps } from "./types"
import Price from "@react-shared/Price"
import { CurrencyField } from "@react-shared/Forms/CurrencyField"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import AppContext from "./AppContext"
import { useNavigate } from "react-router"

function buildQuotas(total: number, guests: number) {
  const q = Math.floor(total / guests)
  const r = total % guests
  return Array.from({ length: guests }, (_, i) => {
    return i < r ? q + 1 : q
  })
}

export default function SplitPerson(props: HubBillPaymentProps) {
  const [guests, setGuests] = useState(props.guests)
  const [payingGuests, setPayingGuests] = useState(1)
  const currency = useContext(CurrencyContext)
  const appContext = useContext(AppContext)
  const setAppAmount = appContext.setAmount
  const navigate = useNavigate()

  const segments = {
    displayValue: false,
    segments: Array.from({ length: guests }, (_, i) => {
      return {
        total: 1,
        filled: i < props.paid_quotas + payingGuests ? 1 : 0,
      }
    }),
    totalArcSize: 353,
    filledArcColor: "#FF00FF",
    incompleteArcColor: "#ADB1CC",
    animated: false,
    radius: 100,
  }

  const [amount, setAmount] = useState<number>(0)
  const [amountStr, setAmountStr] = useState<string>("")
  const [minimum, setMinimum] = useState<number>(0)
  const [canContinue, setCanContinue] = useState<boolean>(false)

  useEffect(() => {
    const quotas = buildQuotas(props.total_for_even_split, guests)
    const payingQuotas = quotas.splice(props.paid_quotas, payingGuests)
    const payingTotal = payingQuotas.reduce((a, b) => a + b, 0)

    const decimalPlaces = Math.log10(currency.subunits)

    setAmount(payingTotal)
    setAppAmount(payingTotal)
    setAmountStr((payingTotal / currency.subunits).toFixed(decimalPlaces))
    setMinimum(payingTotal)
    setCanContinue(true)
  }, [guests, payingGuests])

  useEffect(() => {
    const tip = amount - minimum

    console.log("T", tip)

    if (!isNaN(tip) && tip > 0) {
      appContext.setTip(tip)
    } else {
      appContext.setTip(0)
    }
  }, [amount, minimum])

  useEffect(() => {
    appContext.setPayingQuotas(payingGuests)
  }, [payingGuests])

  useEffect(() => {
    appContext.setNewGuests(guests)
  }, [guests])

  return (
    <div className="w-96 m-auto select-none">
      <div className="relative">
        <SegmentedRoundDisplay {...segments} style={{ margin: "0 auto" }} />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="text-2xl font-bold">{t("js.hub.bill.split_person.to_split")}</div>
          <div className="text-2xl">
            <Price cents={props.total_for_even_split} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 items-center mb-4">
        <div className="text-end">{t("js.hub.bill.split_person.paying_for_pre")}</div>
        <div className="flex justify-center items-center gap-3">
          <Button
            kind="plain"
            onClick={() => {
              setPayingGuests(Math.max(payingGuests - 1, 1))
            }}>
            -
          </Button>

          <span>{payingGuests}</span>

          <Button
            kind="plain"
            onClick={() => {
              setPayingGuests(Math.min(payingGuests + 1, guests - props.paid_quotas))
            }}>
            +
          </Button>
        </div>
        <div>
          {(payingGuests === 1 && t("js.hub.bill.split_person.paying_for_post_one")) ||
            t("js.hub.bill.split_person.paying_for_post_other")}
        </div>
      </div>

      {props.paid_quotas == 0 && (
        <div className="grid grid-cols-3 items-center">
          <div className="text-end">{t("js.hub.bill.split_person.out_of_pre")}</div>
          <div className="flex justify-center items-center gap-3">
            <Button
              kind="plain"
              onClick={() => {
                setGuests(Math.max(guests - 1, 1))
                if (payingGuests > guests - 1) {
                  setPayingGuests(Math.max(guests - 1, 1))
                }
              }}>
              -
            </Button>
            <span>{guests}</span>
            <Button
              kind="plain"
              onClick={() => {
                setGuests(guests + 1)
              }}>
              +
            </Button>
          </div>
          <span>{t("js.hub.bill.split_person.out_of_post")}</span>
        </div>
      )}

      <div className="my-6 max-w-64 mx-auto">
        <CurrencyField
          className="flex-1"
          currencyIso={currency.iso}
          amount={amount}
          amountStr={amountStr}
          onChange={(amtCents, amtStr) => {
            setAmount(amtCents)
            setAmountStr(amtStr)
            setAppAmount(amtCents)
            setCanContinue(amtCents >= minimum)
          }}
          subunits={currency.subunits}
        />
        {amount < minimum / currency.subunits && (
          <span className="mt-1 text-sm leading-6 text-red-600 dark:text-red-400 text-ellipsis overflow-hidden">
            {t("js.hub.payment_splitter.minimum", { amount: Price({ cents: minimum }) })}
          </span>
        )}
      </div>

      <div className="text-end">
        <Button
          kind="primary"
          className="mt-3"
          disabled={!canContinue}
          onClick={() => navigate("/terminals")}>
          {t("js.common.continue")}
        </Button>
      </div>
    </div>
  )
}
