import React, { useEffect } from "react"

type Props = {
  enabled: boolean
}

// This only handles refreshing and closing the tab. Navigation to other pages by clicking on links is not handled.
// Turbo also prevents us from handling the back button properly, so no point in trying to make this perfect.
export default function UnsavedAlert(props: Props) {
  const { enabled } = props

  function beforeUnloadHandler(e: BeforeUnloadEvent) {
    e.preventDefault()
    return e.returnValue = "Are you sure you want to exit?"
  }

  useEffect(() => {
    if (!enabled) return

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [enabled])

  /*if (!enabled)*/ return null

  // return (
  //   <div>This form includes unsaved information. Don't forget to save it!</div>
  // )
}