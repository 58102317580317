import currencySymbol from "@react-shared/currencySymbol"
import React, { useEffect } from "react"
import { t } from "../../../i18n"
import DecimalField from "./DecimalField"

type CurrencyFieldProps = {
  disableDarkMode?: boolean
  className?: string
  isInvalid?: boolean
  currencyIso: string
  subunits: number
  amountStr?: string
  initialValue?: number | null // Expressed in the smallest unit of the currency, must be round
  onChange: (amount: number, amountStr: string) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">

export function CurrencyField(props: CurrencyFieldProps) {
  const { currencyIso, subunits, onChange, className, initialValue, amountStr, ...rest } = props

  const handleAmountChange = (number: number, string: string) => {
    onChange(Math.round(number * subunits), string)
  }

  let decimalPlaces = Math.log10(subunits)
  let decimalInitialValue = props.initialValue ? (props.initialValue / subunits) : 0

  return (
    <div className="btn-group w-full">
      <div className="bg-gray-300 dark:bg-gray-600 dark:text-white flex items-center px-3 text-xs font-medium">
        {currencySymbol(currencyIso)}
      </div>
      <DecimalField
        disableDarkMode={props.disableDarkMode}
        className={className}
        autoComplete="off"
        inputMode="decimal"
        {...rest}
        valueStr={props.amountStr}
        initialValue={decimalInitialValue}
        minDecimalPlaces={decimalPlaces}
        maxDecimalPlaces={decimalPlaces}
        onUpdate={handleAmountChange}
      />
    </div>
  )
}
