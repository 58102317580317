import React, { useEffect } from "react";
import { Routes, Route, useLocation, redirect, useNavigate } from "react-router";
import Item from "./pages/Item";
import Menu from "./pages/Menu";
import MenuGroup from "./pages/MenuGroup";
import MenuIndex from "./pages/MenuIndex";
import OpenItem from "./pages/OpenItem";
import ConfirmTakeout from "./pages/ConfirmTakeout";

function FallbackComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return <></>;
}

export default function App() {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <>
      <Routes location={previousLocation || location}>
        <Route path="/" element={<MenuIndex />} />
        <Route path="/menus/:id" element={<Menu />} />
        <Route path="/menus/:menuId/menu-groups/:id" element={<MenuGroup />} />
        <Route path="/menus/:menuId/menu-groups/:menuGroupId/items/:id" element={<Item />} />
        <Route path="/open-items/:id" element={<OpenItem />} />
      </Routes>
      {previousLocation && (
        <Routes>
          <Route path="/confirm-takeout" element={<ConfirmTakeout />} />
        </Routes>
      ) || (
          <Routes>
            <Route path="/confirm-takeout" element={<FallbackComponent />} />
          </Routes>
        )}
    </>
  )
}