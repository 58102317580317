import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "radio"]

  declare tabTargets: HTMLDivElement[]
  declare radioTargets: HTMLInputElement[]

  connect() {
    this.change()
  }

  change() {
    this.tabTargets.forEach((tab) => {
      tab.classList.add("hidden")

      tab.querySelectorAll("input").forEach((input) => {
        input.disabled = true
      })
    })

    this.radioTargets.forEach((radio) => {
      if (radio.checked && radio.dataset.tabTarget) {
        const tab = document.querySelector(radio.dataset.tabTarget)
        if (tab) {
          tab.classList.remove("hidden")

          tab.querySelectorAll("input").forEach((input) => {
            input.disabled = false
          })
        }
      }
    })
  }
}
