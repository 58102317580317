import { Controller } from "@hotwired/stimulus"
import { Html5Qrcode } from "html5-qrcode"

export default class extends Controller {
  html5QrCode: Html5Qrcode | null = null;
  lastDecode: string | null = null;

  static targets = ["scanner"];

  declare scannerTarget: HTMLDivElement;

  connect() {
    this.html5QrCode = new Html5Qrcode(this.scannerTarget.id);
  }

  start(event: Event) {
    // If you want to prefer back camera
    this.html5QrCode!.start({ facingMode: "environment" }, {
      fps: 20
    }, this.successCallback, this.errorCallback);
  }

  successCallback = async (decodedText: string) => {
    if (this.lastDecode === decodedText) {
      return;
    }

    this.lastDecode = decodedText;
    this.onScan(decodedText);
  }

  errorCallback = (errorMessage: string) => {
    // ignore
  }

  onScan(_text: string) {
    console.error("I should be overridden");
  }
}
