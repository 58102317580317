import React, { useContext } from "react"
import { useParams, useNavigate } from "react-router"
import BackButton from "../BackButton"
import Breadcrumb from "../Breadcrumb"
import PosButton from "../PosButton"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../i18n"
import { is } from "cypress/types/bluebird"

export default function Menu() {
  const { id } = useParams()
  const { getMenu, getMenuGroup, menus, open_items } = useContext(MenuContext)
  const navigate = useNavigate()

  const menu = getMenu(id!)

  // Handle discarded entities that are still referenced
  if (!menu) {
    return null
  }

  const isSingleMenu = menus.length === 1 && menus[0].available && menus[0].id === menu.id

  return (
    <div>
      <Breadcrumb
        segments={[
          isSingleMenu ? null : { name: t("js.hub.ordering.menus"), url: "/" },
          { name: menu.name, url: null },
        ]}
      />
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        {isSingleMenu ? null : <BackButton target="/" />}
        {menu.menu_groups.map((menuGroupId) => {
          const menu_group = getMenuGroup(menuGroupId)
          if (!menu_group) {
            return null
          }

          return (
            <li key={menu_group.id}>
              <PosButton
                tag="button"
                onClick={() =>
                  menu_group.available
                    ? navigate(`/menus/${id}/menu-groups/${menuGroupId}`, { replace: true })
                    : null
                }
                text_color={menu_group.available ? menu_group.text_color : "text-white"}
                bg_color={menu_group.available ? menu_group.color : "gray"}
                className="p-3">
                {menu_group.name}
              </PosButton>
            </li>
          )
        })}
        {isSingleMenu &&
          open_items.map((openItem) => (
            <li key={openItem.id}>
              <PosButton
                tag="button"
                onClick={() => navigate(`/open-items/${openItem.id}`, { replace: true })}
                text_color={openItem.text_color}
                bg_color={openItem.color}
                className="p-3">
                {openItem.name}
              </PosButton>
            </li>
          ))}
      </ul>
    </div>
  )
}
