import React, { useContext, useState } from "react"
import { redirect, useNavigate, useParams } from "react-router"
import Footer from "./Footer"

import { LineItemsWriteContext } from "@hub-ordering/LineItemsContext"
import Breadcrumb from "@hub-ordering/Breadcrumb"
import BackButton from "@hub-ordering/BackButton"
import CoursePicker from "../Item/CoursePicker"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../../i18n"

export default function Item() {
  const navigate = useNavigate()
  const { id } = useParams()
  const currency_subunits = useContext(MenuContext).currency_subunits

  const all_open_items = useContext(MenuContext).open_items
  const open_item = all_open_items.find((open_item) => open_item.id === id)

  const { addOpenItem } = useContext(LineItemsWriteContext)
  const [name, setName] = useState<string>("")
  const [courseId, setCourseId] = useState<string | null>(open_item?.default_course_id || null)
  const [notes, setNotes] = useState<string>("")
  const [unitPriceStr, setUnitPrice] = useState<string>("")

  const unitPrice = parseFloat(unitPriceStr) * currency_subunits

  if (!open_item) {
    throw redirect("/")
  }

  const addToOrder = (quantity: number) => {
    addOpenItem({
      name: name,
      quantity,
      openItemId: open_item.id,
      unitPriceCents: unitPrice,
      netTotalCents: unitPrice * quantity,
      notes,
      courseId: courseId,
    })

    navigate("/", { replace: true })
  }

  return (
    <>
      <Breadcrumb
        segments={[
          { name: t("js.hub.ordering.menus"), url: "/" },
          { name: open_item.name, url: null },
        ]}
      />
      <div className="flex-grow pb-4">
        <div className="flex justify-center items-center gap-4">
          <div className="w-32">
            <BackButton target={"/"} />
          </div>
          <div className="flex-grow text-2xl">
            <div className="font-bold">{open_item.name}</div>
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="item_name" className="block mb-1 font-medium">
            {t("js.hub.ordering.open_item_name")}
          </label>
          <input
            type="text"
            id="item_name"
            name="item[notes]"
            className="focus:ring-indigo-600 dark:focus:ring-indigo-500 ring-gray-300 dark:ring-white/10 text-gray-900 dark:text-white bg-white dark:bg-white/5 block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus={true}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="item_unit_price" className="block mb-1 font-medium">
            {t("js.hub.ordering.open_item_price")}
          </label>
          <input
            type="text"
            id="item_unit_price"
            name="item[notes]"
            className="focus:ring-indigo-600 dark:focus:ring-indigo-500 ring-gray-300 dark:ring-white/10 text-gray-900 dark:text-white bg-white dark:bg-white/5 block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            value={unitPriceStr}
            inputMode="decimal"
            onChange={(e) => setUnitPrice(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="item_notes" className="block mb-1 font-medium">
            {t("js.hub.ordering.open_item_notes")}
          </label>
          <input
            type="text"
            id="item_notes"
            name="item[notes]"
            className="focus:ring-indigo-600 dark:focus:ring-indigo-500 ring-gray-300 dark:ring-white/10 text-gray-900 dark:text-white bg-white dark:bg-white/5 block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        {open_item.default_course_id && (
          <CoursePicker courseId={courseId} setCourseId={setCourseId} />
        )}
      </div>
      <Footer
        disabled={
          name.length === 0 || unitPriceStr.length === 0 || isNaN(unitPrice) || unitPrice <= 0
        }
        price_cents={unitPrice}
        addToOrder={addToOrder}
      />
    </>
  )
}
