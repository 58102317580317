import { LineItemsWriteContext } from "@hub-ordering/LineItemsContext"
import { HubOrderingOpenLineItem } from "@hub-ordering/types"
import Button from "@react-shared/Button"
import Price from "@react-shared/Price"
import React, { useContext } from "react"

import { t } from "../../i18n"

type Props = {
  lineItem: HubOrderingOpenLineItem
  index: number
}

export default function OpenLineItem({ lineItem, index }: Props) {
  const { incrementLineItem, decrementLineItem } = useContext(LineItemsWriteContext)

  return (
    <>
      <div className="flex mt-2 gap-2">
        <div>{lineItem.quantity} x</div>
        <div className="flex-1">
          <div>{lineItem.name}</div>
          {lineItem.notes.length > 0 && <div>{lineItem.notes}</div>}
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={lineItem.unitPriceCents * lineItem.quantity} />
          </span>
        </div>
      </div>
      <div className="btn-group">
        <Button size="small" kind="warning" onClick={() => decrementLineItem(index)}>
          {t("js.hub.ordering.remove")}
        </Button>
        <Button size="small" kind="primary" onClick={() => incrementLineItem(index)}>
          {t("js.hub.ordering.add_more")}
        </Button>
      </div>
    </>
  )
}
