import React, { useContext, useState } from "react"
import { redirect, useNavigate, useParams } from "react-router"
import { useSelectedModifiers } from "@react-shared/hooks"
import ModifierGroups from "./ModifierGroups"
import Footer from "./Footer"
import { ModifiersContext } from "../../../GuestOrdering/pages/ItemModal/ModifiersContext"

import { LineItemsWriteContext } from "@hub-ordering/LineItemsContext"
import Breadcrumb from "@hub-ordering/Breadcrumb"
import BackButton from "@hub-ordering/BackButton"
import { MenuContext } from "@hub-ordering/MenuContext"
import CoursePicker from "./CoursePicker"

import { t } from "../../../../i18n"
import { LineItemModifierGroup } from "../../../../graphql/graphql"

function validateModifierGroup(group: { id: string, multiple_selection: boolean, min_selections?: number | null, max_selections?: number | null }, selectedModifiers: LineItemModifierGroup[]) {
  const grp = selectedModifiers.find((grp) => grp.modifierGroupId === group.id)
  const options = grp?.modifierOptions || []

  if (group.multiple_selection) {
    const min = group.min_selections || 0
    const max = group.max_selections || Infinity

    const count = options.reduce(
      (total, selection) => total + selection.quantity,
      0,
    )

    return count >= min && count <= max
  } else {
    return options.length === 1
  }
}

function useValidateModifiers(item: { id: string, modifier_groups: string[] } | undefined, modifiers: LineItemModifierGroup[]) {
  const { getModifierGroup } = useContext(MenuContext)

  return item?.modifier_groups.every((groupId) => {
    const modifierGroup = getModifierGroup(groupId)!
    if (!modifierGroup) {
      return false
    }
    return validateModifierGroup(modifierGroup, modifiers)
  })
}

export default function Item() {
  const navigate = useNavigate()
  const { menuId, menuGroupId, id } = useParams()

  const { getMenu, getMenuGroup, getItem } = useContext(MenuContext)

  const menu = getMenu(menuId!)!
  const menu_group = getMenuGroup(menuGroupId!)!
  const item = getItem(id!)
  const { modifiers, setUniqueModifier, setMultiModifier } = useSelectedModifiers()
  const [sizeId, setSizeId] = useState<string | null>(null)
  const [notes, setNotes] = useState<string>("")

  const groupUrl = `/menus/${menuId}/menu-groups/${menuGroupId}`

  const { addLineItem } = useContext(LineItemsWriteContext)
  const [courseId, setCourseId] = useState<string | null>(item?.default_course_id || null)

  const areModifiersValid = useValidateModifiers(item, modifiers)
  const [showErrors, setShowErrors] = useState(false)

  if (!item) {
    throw redirect(groupUrl)
  }

  const addToOrder = (quantity: number) => {
    if (areModifiersValid) {
      addLineItem({
        quantity,
        menuId: menuId!,
        menuGroupId: menuGroupId!,
        itemId: item.id,
        modifiers,
        unitPriceCents: unitPrice,
        netTotalCents: unitPrice * quantity,
        notes,
        sizeId: sizeId,
        courseId: courseId,
      })

      navigate(groupUrl, { replace: true })
    } else {
      setShowErrors(true)
    }
  }

  const modifierTotalPriceCents = Object.values(modifiers)
    .flat()
    .reduce((total, group) => total +
      group.modifierOptions.reduce((total, modifier) => total +
        modifier.unitPriceCents * modifier.quantity, 0), 0)
  const basePrice = item.sizes
    ? item.sizes.find((size) => size.id === sizeId)?.price_cents
    : item.price_cents
  const unitPrice = (basePrice || 0) + modifierTotalPriceCents

  return (
    <ModifiersContext.Provider
      value={{ modifiers, setUniqueModifier, setMultiModifier, sizeId, setSizeId }}>
      <Breadcrumb
        segments={[
          { name: t("js.hub.ordering.menus"), url: "/" },
          { name: menu.name, url: `/menus/${menuId}` },
          { name: menu_group.name, url: groupUrl },
          { name: item.name, url: null },
        ]}
      />
      <div className="flex-grow pb-4">
        <div className="flex justify-center items-center gap-4">
          <div className="w-32">
            <BackButton target={groupUrl} />
          </div>
          <div className="flex-grow text-2xl">
            <div className="font-bold">{item.name}</div>
            {item.allergens.length > 0 && (
              <div className="text-sm mt-2 text-gray-700">
                {item.allergens.length > 0 && (
                  <>
                    <span className="font-semibold">{t("js.hub.ordering.contains")}</span>
                    <span>
                      {item.allergens.map((allergen) => t(`js.allergens.${allergen}`)).join(", ")}.
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <ModifierGroups item={item} showErrors={showErrors} />
        <div className="mt-4">
          <label className="sr-only" htmlFor="item_notes">
            {t("js.hub.ordering.notes")}
          </label>
          <input
            type="text"
            id="item_notes"
            name="item[notes]"
            className="focus:ring-indigo-600 dark:focus:ring-indigo-500 ring-gray-300 dark:ring-white/10 text-gray-900 dark:text-white bg-white dark:bg-white/5 block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
            placeholder={t("js.hub.ordering.notes")}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        {item.default_course_id && <CoursePicker courseId={courseId} setCourseId={setCourseId} />}
      </div>
      <Footer price_cents={unitPrice} addToOrder={addToOrder} />
    </ModifiersContext.Provider>
  )
}
