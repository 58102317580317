import React, { useContext, useEffect, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HubBillRefundPayment, RefundTypes } from "./types"
import private_api from "../../private_api"
import I18n from "../../i18n"

import AmexImage from "../../images/amex.svg"
import DinersImage from "../../images/diners.svg"
import DiscoverImage from "../../images/discover.svg"
import JcbImage from "../../images/jcb.svg"
import MaestroImage from "../../images/maestro.svg"
import MastercardImage from "../../images/mastercard.svg"
import UnionPayImage from "../../images/unionpay.svg"
import VisaImage from "../../images/visa.svg"
import { AppContext, PropsContext } from "./Contexts"
import Price from "@react-shared/Price"
import Button from "@react-shared/Button"
import TextField from "@react-shared/Forms/TextField"

function networkIcon(network: string) {
  switch (network) {
    case "amex":
      return AmexImage
    case "diners":
      return DinersImage
    case "discover":
      return DiscoverImage
    case "jcb":
      return JcbImage
    case "maestro":
      return MaestroImage
    case "mastercard":
      return MastercardImage
    case "unionpay":
      return UnionPayImage
    case "visa":
      return VisaImage
    default:
      return "null"
  }
}

async function executeRefund(billId: string, refunds: { payment_id: string; amount: number }[]) {
  await private_api.Hub.Bills.IssueRefund(billId, { refunds: refunds.filter((r) => r.amount > 0) })

  window.location.reload()
}

function PaymentLine({ payment }: { payment: HubBillRefundPayment }) {
  return (
    ((payment.payment_method == "online" || payment.payment_method == "terminal") && (
      <>
        <img src={networkIcon(payment.card_brand!)} className="h-6" />
        <span>****{payment.card_last4}</span>
      </>
    )) || <div>{payment.custom_payment_method_name!}</div>
  )
}

function PaymentsSummary({ payments, refunds }: { payments: HubBillRefundPayment[], refunds: { payment_id: string; amount: number }[] }) {
  return (
    <>
      <div className="mb-3">{I18n.t("js.hub.refunds.summary.title_full")}</div>

      <div className="rounded rounded-md divide-y border mb-4">
        {payments.map((payment) => {
          const refund = refunds.find((r) => r.payment_id == payment.id)!
          if (!refund || refund.amount == 0) {
            return null
          }
          
          return (
            <div key={payment.id} className="p-4 flex items-center gap-4">
              <PaymentLine payment={payment} />
              <div className="ms-auto">{Price({ cents: refund.amount })}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

function reducer(
  state: { payment_id: string; amount: number }[],
  update: { payment_id: string; amount: number },
) {
  return [...state.filter((pay) => pay.payment_id != update.payment_id), update]
}

function MultiPaymentSummary({ setRefunds, setDisabled }: { setRefunds: any; setDisabled: any }) {
  const { refund_amount } = useContext(AppContext)
  const { payments } = useContext(PropsContext)

  const [state, dispatch] = useReducer(
    reducer,
    payments.map((p) => ({ payment_id: p.id, amount: 0 })),
  )

  const stateMap = Object.fromEntries(state.map((refund) => [refund.payment_id, refund.amount]))

  const totalAllocated = state.reduce((sum, v) => sum + (isNaN(v.amount) ? 0 : v.amount), 0)
  const allValid = state.every(
    (ref) => ref.amount <= payments.find((p) => p.id == ref.payment_id)!.unrefunded_amount_cents,
  )

  useEffect(() => {
    setRefunds(state)
    setDisabled(!allValid || totalAllocated != refund_amount)
  }, state)

  return (
    <>
      <div className="mb-3">
        {I18n.t("js.hub.refunds.summary.title_allocate", {
          amount: Price({ cents: refund_amount }),
        })}
      </div>

      <div className="rounded rounded-md divide-y border mb-4">
        {payments.filter(pay => pay.unrefunded_amount_cents > 0).map((payment, idx) => (
          <div key={idx} className="p-4 flex items-center gap-4">
            <PaymentLine payment={payment} />
            <div className="ms-auto">
              <TextField
                autoComplete="off"
                pattern="^-?[0-9]+(.[0-9]{1,2})?$"
                data-cy={`payment-refund-amount-${idx}`}
                onChange={(e) =>
                  dispatch({ payment_id: payment.id, amount: parseFloat(e.target.value) * 100 })
                }
                isInvalid={stateMap[payment.id] > payment.unrefunded_amount_cents}
              />
              <div className="text-sm">
                {I18n.t("js.hub.refunds.summary.available", {
                  amount: Price({ cents: payment.unrefunded_amount_cents }),
                })}
              </div>
            </div>
          </div>
        ))}
      </div>

      {(totalAllocated != refund_amount && (
        <div className="alert alert-warning mb-4">
          {(refund_amount > totalAllocated &&
            I18n.t("js.hub.refunds.summary.not_enough_allocated", {
              amount: Price({ cents: refund_amount - totalAllocated }),
            })) ||
            I18n.t("js.hub.refunds.summary.too_much_allocated", {
              amount: Price({ cents: totalAllocated - refund_amount }),
            })}
        </div>
      )) ||
        (!allValid && (
          <div className="alert alert-warning mb-4">
            {I18n.t("js.hub.refunds.summary.too_much_single", {
              amount: Price({ cents: totalAllocated - refund_amount }),
            })}
          </div>
        ))}
    </>
  )
}

export default function Summary() {
  const { bill, payments } = useContext(PropsContext)
  const { refund_amount, refund_type } = useContext(AppContext)

  const navigate = useNavigate()

  const [refunds, setRefunds] = useState<{ payment_id: string; amount: number }[]>([])
  useEffect(() => {
    const unrefundedPayments = payments.filter((p) => p.unrefunded_amount_cents > 0)

    if (refund_type == RefundTypes.EntireBill) {
      setRefunds(
        payments
          .filter((p) => p.unrefunded_amount_cents > 0)
          .map((p) => ({ payment_id: p.id, amount: p.unrefunded_amount_cents })),
      )
    } else if (unrefundedPayments.length == 1) {
      setRefunds([{ payment_id: unrefundedPayments[0].id, amount: refund_amount }])
    }
  }, [])

  const [disabled, setDisabled] = useState(false)

  return (
    <>
      <div>
        <h3 className="mb-3 font-semibold">{I18n.t("js.hub.refunds.summary.title")}</h3>

        {((refund_type == RefundTypes.EntireBill || payments.filter(pay => pay.unrefunded_amount_cents > 0).length == 1) && (
          <PaymentsSummary payments={payments} refunds={refunds} />
        )) || <MultiPaymentSummary setRefunds={setRefunds} setDisabled={setDisabled} />}
      </div>

      <div className="flex justify-between gap-4">
        <Button kind="plain" onClick={() => navigate(-1)}>
          {I18n.t("js.common.back")}
        </Button>
        <Button
          disabled={disabled}
          kind="primary"
          onClick={() => {
            setDisabled(true)
            executeRefund(bill.id, refunds)
          }}>
          {I18n.t("js.common.confirm")}
        </Button>
      </div>
    </>
  )
}
