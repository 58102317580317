import { createContext } from "react"
import { HubBillPaymentProps } from "./types"

const AppContext = createContext<HubBillPaymentProps>({
  currency_iso: "",
  currency_subunits: 0,
  bill: {
    id: "",
    balance_cents: 0,
    balance_with_tips_cents: 0,
    service_type: "at_table",
    guests: 0,
  },
  payment_methods: [],
  card_readers: [],
  amount: 0,
  setAmount: () => { },
  tip: 0,
  setTip: () => { },
  items: [],
  voided_items: [],

  selectedItems: {},
  setSelectedItems: () => { },

  payingQuotas: 0,
  setPayingQuotas: () => { },

  newGuests: 0,
  setNewGuests: () => { },

  guests: 0,
  paid_quotas: 0,
  total_for_even_split: 0,
})

export default AppContext
