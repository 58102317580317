// @ts-ignore
import ColorPicker from "stimulus-color-picker"

export default class extends ColorPicker {
  // @ts-ignore
  element: HTMLElement
  inputTarget: HTMLInputElement
  buttonTarget: HTMLElement

  static targets = ["button", "input"]

  connect() {
    super.connect()
    this.picker.on("change", () => {
      this.picker.applyColor()
    })
  }

  get componentOptions() {
    return {
      palette: false,
      preview: false,
      hue: false,

      interaction: {
        input: false,
        cancel: false,
        clear: false,
        save: false,
      },
    }
  }

  // You can override the swatches with this getter.
  // Here are the default options.
  get swatches() {
    return [
      "#334155", // slate-700
      "#c2410c", // red-700
      "#a16207", // orange-700
      "#4d7c0f", // amber-700
      "#1d4ed8", // blue-700
      "#4338ca", // indigo-700
      "#6d28d9", // purple-700
      "#be185d", // pink-700

      "#fed7aa", // orange-200
      "#fde047", // yellow-300
      "#bef264", // lime-300
      "#bfdbfe", // blue-200
      "#c7d2fe", // indigo-200
      "#ddd6fe", // violet-200
      "#fbcfe8", // pink-200
    ]
  }
}
