import { createContext } from "react"
import { HubOrderingLineItem, HubOrderingOpenLineItem } from "./types"

export const LineItemsContext = createContext({
  lineItems: [] as (HubOrderingLineItem | HubOrderingOpenLineItem)[],
})

export const LineItemsWriteContext = createContext({
  addLineItem: (_: HubOrderingLineItem) => { },
  addOpenItem: (_: HubOrderingOpenLineItem) => { },
  incrementLineItem: (_index: number) => { },
  decrementLineItem: (_index: number) => { },
})
