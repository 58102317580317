import React, { useMemo } from "react"
import FieldError from "@react-shared/Icons/FieldError"
import Label from "@react-shared/Forms/Label"
import { FieldContext } from "@react-shared/Forms/FieldContext"

type Props = {
  children: React.ReactNode
  label: React.ReactNode
  error?: string
  hint?: string
}

export default function FormField(props: Props) {
  const fieldId = useMemo(() => `field-${Math.random().toString(36).slice(2)}`, []);

  return (
    <div>
      <Label htmlFor={fieldId}>{props.label}</Label>

      <div className="relative mt-1">
        <FieldContext.Provider value={{ id: fieldId, error: props.error }}>
          {props.children}
        </FieldContext.Provider>
        {props.error && (<FieldError />)}
      </div>

      {props.error && (
        <p className="mt-1 text-sm leading-6 text-red-600 dark:text-red-400 text-ellipsis overflow-hidden">
          {props.error}
        </p>
        ) || (props.hint && (
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300 text-ellipsis overflow-hidden">
            {props.hint}
          </p>
        ))}
    </div>
  )
}
