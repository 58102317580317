import React from "react"
import { confirmable, createConfirmation } from "react-confirm"
import Dialog from "../GuestOrdering/Dialog"
import Button from "@react-shared/Button"

import { t } from "../../i18n"

const DeleteConfirm = ({ proceed }: { proceed: (_: boolean) => void }) => (
  <Dialog
    onClose={() => proceed(false)}
    additionalInnerClasses={"p-6"}
    additionalClasses={"!max-w-sm"}>
    <div className={"font-semibold mb-4"}>{t("common.are_you_sure")}</div>
    <div className={"flex gap-4 justify-end"}>
      <Button kind={"plain"} onClick={() => proceed(false)}>
        {t("js.common.back")}
      </Button>
      <Button kind={"danger"} onClick={() => proceed(true)}>
        {t("js.common.delete")}
      </Button>
    </div>
  </Dialog>
)

export default createConfirmation(confirmable(DeleteConfirm), 0)
