import React from "react"
import { useNavigate } from "react-router"

import { t } from "../../i18n"

export default function BackButton({ target }: { target: string | number }) {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => navigate(target, { replace: true })}
      className="w-full text-white flex items-center justify-center p-3 h-16 rounded-md bg-gray-800">
      {t("js.common.back")}
    </button>
  )
}
