import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unit1", "unit2", "value1", "value2", "density"]

  declare unit1Target: HTMLSelectElement
  declare unit2Target: HTMLSelectElement
  declare value1Target: HTMLInputElement
  declare value2Target: HTMLInputElement
  declare densityTarget: HTMLInputElement

  WEIGHT_UNITS = ["g", "kg", "mg", "lb", "oz"]
  VOLUME_UNITS = [
    "l", "ml", "tsp", "tbsp", "cup", "pint", "quart", "gal", "fl oz",
    "tsp (US)", "tbsp (US)", "cup (US)", "pint (US)", "quart (US)", "gal (US)", "fl oz (US)"
  ] 

  WEIGHT_GRAMS = {
    g: 1,
    kg: 1000,
    mg: 0.001,
    lb: 453.592,
    oz: 28.3495
  }

  VOLUME_ML = {
    l: 1000,
    ml: 1,
    tsp: 5.91939,
    tbsp: 17.7582,
    cup: 284.131,
    pint: 568.261,
    quart: 1136.52,
    gal: 4546.096,
    "fl oz": 28.4131,
    "tsp (US)": 4.92892,
    "tbsp (US)": 14.7868,
    "cup (US)": 236.588,
    "pint (US)": 473.176,
    "quart (US)": 946.353,
    "gal (US)": 3785.41,
    "fl oz (US)": 29.5735
  }

  primary: string = "weight"

  connect() {
    const density = parseFloat(this.densityTarget.value)

    this.unit1Target.innerHTML = this.optionsForSelect(this.WEIGHT_UNITS, "kg")
    this.unit2Target.innerHTML = this.optionsForSelect(this.VOLUME_UNITS, "l")

    if (!isNaN(density)) {
      this.value1Target.value = density.toFixed(4)
    }
    this.value2Target.value = "1"

    this.calculate()
  }

  swap() {
    console.log("Swapping")
    let currentUnit1 = this.unit1Target.value
    let currentValue1 = this.value1Target.value

    let currentUnit2 = this.unit2Target.value
    let currentValue2 = this.value2Target.value

    this.unit1Target.innerHTML = this.optionsForSelect(this.primary == "weight" ? this.VOLUME_UNITS : this.WEIGHT_UNITS, currentUnit2)
    this.unit2Target.innerHTML = this.optionsForSelect(this.primary == "weight" ? this.WEIGHT_UNITS : this.VOLUME_UNITS, currentUnit1)

    this.value1Target.value = currentValue2
    this.value2Target.value = currentValue1

    this.primary = this.primary == "weight" ? "volume" : "weight"

    this.calculate()
  }

  calculate() {
    let unit1 = this.unit1Target.value
    let value1 = parseFloat(this.value1Target.value)

    let unit2 = this.unit2Target.value
    let value2 = parseFloat(this.value2Target.value)

    let density = 0

    if (this.primary == "weight") {
      let u1 = unit1 as keyof typeof this.WEIGHT_GRAMS
      let u2 = unit2 as keyof typeof this.VOLUME_ML

      density = value1 / (value2 * this.VOLUME_ML[u2] / this.WEIGHT_GRAMS[u1])
    } else {
      let u1 = unit1 as keyof typeof this.VOLUME_ML
      let u2 = unit2 as keyof typeof this.WEIGHT_GRAMS

      density = value2 / (value1 / this.WEIGHT_GRAMS[u2] * this.VOLUME_ML[u1])
    }

    let val = (Math.round(density * 1000000) / 1000000)

    this.densityTarget.value = isNaN(val) ? "" : val.toString()
  }

  optionsForSelect(units: string[], selected: string) {
    return units.map((unit) => {
      return `<option value="${unit}" ${unit == selected ? "selected" : ""}>${unit}</option>`
    }).join("")
  }
}
