import React from "react"

type Props = {
  tag: string
  text_color?: string
  bg_color?: string
  children: React.ReactNode
  className?: string
} & React.HTMLAttributes<HTMLElement>

export default function PosButton(props: Props) {
  const { tag, text_color, bg_color, children } = props

  const textColor = text_color || ""
  const backgroundColor = bg_color || null

  return React.createElement(
    tag,
    {
      ...props,
      className: `${textColor} w-full flex shadow items-center justify-center h-16 rounded-md text-center select-none ${props.className}`,
      style: { backgroundColor },
    },
    children,
  )
}
