import { gql } from "../graphql"

export const assignTableCode = gql(`
  mutation AssignTableCode($tableId: ID!, $code: String!) {
    assignTableCode(input: { tableId: $tableId, code: $code }) {
      accepted
      errors { code, path, message }
    }
  }`
)
