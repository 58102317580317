import React, { useState } from "react"

import { ApolloProvider, useMutation, useQuery } from "@apollo/client"
import apolloClient from "../../shared/apolloClient"
import { getStockTakeItems, submitStockTake, updateStockTakeItem } from "../../mutations"
import { LoadingSpinner } from "@react-shared/LoadingSpinner"
import { Category, InventoryItem, Product, StockTakeItem } from "../../graphql/graphql"
import DecimalField from "@react-shared/Forms/DecimalField"
import Price from "@react-shared/Price"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import { CurrencyField } from "@react-shared/Forms/CurrencyField"
import Button from "@react-shared/Button"

import { t } from "../../i18n"

type Props = {
  locationId: string
  stockTakeId: string
  takingSheetId: string
  currency: {
    iso: string
    subunits: number
  }
}

export default function HubStockTake(props: Props) {
  return (
    <ApolloProvider client={apolloClient}>
      <CurrencyContext.Provider value={props.currency}>
        <Main {...props} />
      </CurrencyContext.Provider>
    </ApolloProvider>
  )
}

function Main(props: Props) {
  const { data, loading, error } = useQuery(getStockTakeItems, {
    variables: {
      locationId: props.locationId,
      stockTakeId: props.stockTakeId,
    },
  })

  if (loading) return <LoadingSpinner color="text-black dark:text-white" size="h-12 w-12" />

  if (error) {
    console.error(error)
    return <div>Error!</div>
  }

  let items = data?.inventoryStockTake?.items.slice()
  items?.sort((a, b) => a.item.name.localeCompare(b.item.name))

  const itemsByCategory = items?.reduce(
    (acc: any, item: StockTakeItem) => {
      const category = item?.item.category
      if (!acc[category.id]) acc[category.id] = []
      acc[category.id].push(item)
      return acc
    },
    {} as { [key: string]: StockTakeItem[] },
  )

  let categories = items
    ?.map((item: any) => item.item.category)
    .filter(
      (category: any, index: number, self: any) => self.indexOf(category) === index,
    ) as Category[]

  categories = categories.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <>
      <Header items={items} locationId={props.locationId} stockTakeId={props.stockTakeId} />
      <Editor
        categories={categories}
        itemsByCategory={itemsByCategory}
        locationId={props.locationId}
        stockTakeId={props.stockTakeId}
        currency={props.currency}
      />
    </>
  )
}

type EditorProps = {
  categories: Category[]
  itemsByCategory: { [key: string]: StockTakeItem[] }
  locationId: string
  stockTakeId: string
  currency: {
    iso: string
    subunits: number
  }
}

function Editor(props: EditorProps) {
  return (
    <div>
      {props.categories.map((category) => (
        <CategoryEditor
          key={category.id}
          category={category}
          items={props.itemsByCategory[category.id]}
          locationId={props.locationId}
          stockTakeId={props.stockTakeId}
          currency={props.currency}
        />
      ))}
    </div>
  )
}

type CategoryEditorProps = {
  category: Category
  items: StockTakeItem[]
  locationId: string
  stockTakeId: string
  currency: {
    iso: string
    subunits: number
  }
}

function CategoryEditor(props: CategoryEditorProps) {
  return (
    <div className="mb-8">
      <h2 className="pb-2 mb-4 text-lg border-b font-medium">{props.category.name}</h2>
      <table className="text-base space-y-2 w-full text-gray-800 text-sm">
        <thead className={"font-semibold"}>
          <tr className={"bg-gray-50 whitespace-nowrap"}>
            <th className="font-medium px-3 py-2 text-start w-[40%]">
              {t("js.hub.stock_take.products.name")}
            </th>
            <th className="font-medium px-3 py-2 text-start hidden sm:block">
              {t("js.hub.stock_take.products.last_purchase")}
            </th>
            <th className="font-medium px-3 py-2 text-end">
              {t("js.hub.stock_take.products.unit_value")}
            </th>
            <th className="font-medium px-3 py-2 text-start">
              {t("js.hub.stock_take.products.stock")}
            </th>
            <th className="font-medium px-3 py-2 text-start">
              {t("js.hub.stock_take.products.count_by")}
            </th>
            <th className="font-medium px-3 py-2 text-end">
              {t("js.hub.stock_take.products.total_value")}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item) => (
            <ItemEditor
              key={item.id}
              locationId={props.locationId}
              stockTakeId={props.stockTakeId}
              item={item}
              currency={props.currency}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

type ItemEditorProps = {
  locationId: string
  stockTakeId: string
  item: StockTakeItem
  currency: {
    iso: string
    subunits: number
  }
}

function countBy(item: Product) {
  if (item.unitOfMeasure === "EACH" && item.eachName) {
    return `${item.eachName} (${item.stockIncrement})`
  } else {
    return `${item.stockIncrement} ${t("units." + item.unitOfMeasure.toLocaleLowerCase()).toLocaleLowerCase()}`
  }
}

function ItemEditor(props: ItemEditorProps) {
  const [save, { loading, error }] = useMutation(updateStockTakeItem)

  const { item } = props
  const details = item.item

  const [unitCostCents, setUnitCostCents] = useState(item.unitCostCents)
  const [count, setCount] = useState(item.quantity)
  const total = count * unitCostCents

  const [editingCost, setEditingCost] = useState(false)
  const [editingCostValue, setEditingCostValue] = useState(unitCostCents / 100)

  let updateCount = (newCount: number) => {
    newCount = Math.min(newCount, 99999)

    if (count === newCount) return

    setCount(newCount)
    save({
      variables: {
        stockTakeId: props.stockTakeId,
        locationId: props.locationId,
        id: item.id,
        quantity: newCount,
      },
    })
  }

  let updateUnitCost = (newCost: number) => {
    setUnitCostCents(newCost)
    save({
      variables: {
        stockTakeId: props.stockTakeId,
        locationId: props.locationId,
        id: item.id,
        unitCostCents: newCost,
      },
    })
  }

  return (
    <tr className={"even:bg-gray-100 odd:bg-white"}>
      <td className={"px-3 py-2"}>{details.name}</td>
      <td className={"px-3 py-2 hidden sm:block"}>-</td>
      <td className={"px-3 py-2 text-end"}>
        {editingCost ? (
          <div className="flex gap-1">
            <CurrencyField
              className="!px-2 !py-1 !w-16"
              disableDarkMode={true}
              currencyIso={props.currency.iso}
              initialValue={editingCostValue}
              onChange={(newCost) => {
                setEditingCostValue(newCost)
              }}
              subunits={props.currency.subunits}
            />
            <button
              className="text-green-500"
              onClick={() => {
                updateUnitCost(Math.round(editingCostValue * 100))
                setEditingCost(false)
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 inline-block">
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <>
            <a onClick={() => setEditingCost(true)} className="me-2 text-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 inline-block">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </a>
            <span>{unitCostCents === 0 ? "-" : <Price cents={unitCostCents} />}</span>
          </>
        )}
      </td>
      <td className={"px-3 py-2"}>
        <DecimalField
          className="!px-2 !py-1 !w-16"
          disableDarkMode={true}
          initialValue={count}
          onFocus={(e) => e.target.select()}
          onUpdate={updateCount}
        />
      </td>
      <td className={"px-3 py-2 text-start whitespace-nowrap"}>{countBy(details as Product)}</td>
      <td className={"px-3 py-2 text-end"}>
        <Price cents={total} />
      </td>
    </tr>
  )
}

type HeaderProps = {
  locationId: string
  stockTakeId: string
  items: StockTakeItem[]
}

function Header(props: HeaderProps) {
  const totalValueCents = props.items.reduce((acc, item) => {
    return acc + item.quantity * item.unitCostCents
  }, 0)

  const [submit, { loading }] = useMutation(submitStockTake)

  return (
    <div className="bg-blue-500 text-white p-4 rounded-md flex items-center mb-6">
      <div>
        <div className="text-lg">{t("js.hub.stock_take.total_value")}</div>
        <div className="text-2xl font-semibold">
          <Price cents={totalValueCents} />
        </div>
      </div>
      <div className="ms-auto">
        <Button
          kind="primary"
          size="large"
          loading={loading}
          onClick={async () => {
            await submit({
              variables: {
                locationId: props.locationId,
                id: props.stockTakeId,
              },
            })

            window.location.href = `/hub/inventory`
          }}>
          {t("js.hub.stock_take.submit")}
        </Button>
      </div>
    </div>
  )
}
