import PosButton from "@hub-ordering/PosButton"
import Price from "@react-shared/Price"
import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { ModifiersContext } from "../../../GuestOrdering/pages/ItemModal/ModifiersContext"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../../i18n"

export default function SizePicker() {
  const { id } = useParams()
  const { getItem } = useContext(MenuContext)

  const item = getItem(id!)!

  const { sizeId, setSizeId } = useContext(ModifiersContext)

  return (
    <>
      <div className="text-lg font-semibold mb-2 mt-6">{t("js.hub.ordering.size")}</div>
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        {item.sizes!.map((size) => {
          const selected = sizeId == size.id

          return (
            <PosButton
              key={size.id}
              tag="label"
              className={`p-3 cursor-pointer border border-cyan-800 ${selected ? "bg-cyan-800" : "white"
                }`}
              text_color={selected ? "text-white" : "text-black dark:text-white"}>
              <input
                type="radio"
                className={`h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 me-2`}
                value={size.id}
                onChange={() => setSizeId(size.id)}
                name="item-size"
              />
              <div className="flex-grow text-start">
                <div>{size.name}</div>
                {size.price_cents != 0 && (
                  <div className={`text-xs ${selected ? "text-white" : "text-gray-600`}>"}`}>
                    <Price cents={size.price_cents} />
                  </div>
                )}
              </div>
            </PosButton>
          )
        })}
      </ul>
    </>
  )
}
