{
  "ar": {
    "common": {
      "actions": "الإجراءات",
      "active": "نشط",
      "add": "إضافة",
      "and_x_more": "و %{count} المزيد",
      "any": "أي",
      "archive": "أرشفة",
      "archived": "تمت الأرشفة",
      "are_you_sure": "هل أنت متأكد؟",
      "back": "رجوع",
      "cancel": "إلغاء",
      "choose": "اختيار",
      "close": "إغلاق",
      "closed": "مغلق",
      "confirm": "تأكيد",
      "confirm_delete": "هل أنت متأكد؟",
      "continue": "متابعة",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "حذف",
      "details": "تفاصيل",
      "do_not_refresh_page": "لا تقم بتحديث الصفحة أو إغلاقها.",
      "edit": "تحرير",
      "form_error_html": "بعض البيانات المُرسلة غير صحيحة. يرجى التحقق من النموذج للأخطاء والمحاولة مرة أخرى. إذا استمرت المشكلة، <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">اتصل بدعم العملاء</a>.",
      "learn_more": "تعرف أكثر",
      "minutes": "Minutes",
      "never": "أبدًا",
      "no": "لا",
      "none": "لا شيء",
      "not_active": "غير نشط",
      "not_provided": "لم يتم توفيره",
      "processing": "جارٍ المعالجة",
      "remove": "إزالة",
      "restore": "استعادة",
      "save": "حفظ",
      "selected": "محدد",
      "service_types": {
        "at_table": "في الجدول",
        "delivery": "التوصيل",
        "takeaway": "الطلب للخارج"
      },
      "settings_saved": "تم حفظ الإعدادات.",
      "show": "عرض",
      "talk_to_an_expert": "التحدث إلى خبير",
      "view": "عرض",
      "yes": "نعم"
    },
    "js": {
      "allergens": {
        "celery": "كرفس",
        "cereals": "الحبوب الكاملة",
        "crustaceans": "القشريات",
        "dairy": "منتجات الألبان",
        "eggs": "بيض",
        "fish": "أسماك",
        "lupin": "لوبين",
        "mustard": "خردل",
        "nuts": "المكسرات",
        "peanuts": "فول السوداني",
        "sesame": "السمسم",
        "shellfish": "محار",
        "soy": "فول الصويا",
        "sulphur": "كبريتيدات"
      },
      "common": {
        "actions": "Actions",
        "back": "رجوع",
        "cancel": "إلغاء",
        "confirm": "تأكيد",
        "continue": "متابعة",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "خالي من الجلوتين",
        "halal": "حلال",
        "kosher": "كوشير",
        "teetotal": "خالي من الكحول",
        "vegan": "نباتي",
        "vegetarian": "نباتي"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "أضف عناصر من القائمة للبدء",
            "empty_title": "سلة التسوق الخاصة بك فارغة",
            "go_to_checkout": "انتقل إلى الخروج",
            "place_order": "قدم الطلب",
            "title": "سلة التسوق الخاصة بك",
            "total": "الإجمالي"
          },
          "bill": {
            "go_to_payment": "انتقل إلى الدفع",
            "outstanding": "الرصيد المستحق",
            "paid": "تم الدفع",
            "title": "فاتورتك",
            "total": "الإجمالي"
          },
          "change_menu": "تغيير القائمة",
          "checkout": {
            "email": "البريد الإلكتروني",
            "ending_in": "البطاقة تنتهي بـ %{last4}",
            "name": "الاسم",
            "or": "أو",
            "pay": "ادفع %{amount}",
            "previous_payment": "البطاقات المستخدمة سابقًا",
            "title": "الخروج",
            "total": "الإجمالي",
            "use": "ادفع"
          },
          "choose_menu": "اختر قائمة",
          "closed": "%{name} لا تقبل الطلبات حاليًا.",
          "item": {
            "add_for": "أضف لـ %{price}",
            "choose_size": "اختر حجمًا",
            "contains": "يحتوي على:",
            "increase_quantity": "زيادة الكمية",
            "reduce_quantity": "تقليل الكمية",
            "select_at_least": "يرجى اختيار %{min} خيارات على الأقل.",
            "select_one": "يرجى اختيار خيار."
          },
          "kcal_disclaimer": "يحتاج البالغون إلى حوالي 2000 سعرة حرارية يومياً.",
          "no_menus": "المطعم ليس لديه قوائم متاحة في الوقت الحالي.",
          "payment_success": {
            "back_to_menu": "العودة إلى القائمة",
            "failed1": "تم فشل عملية الدفع الخاصة بك.",
            "failed2": "يرجى المحاولة مرة أخرى أو التواصل مع موظفي المطعم.",
            "message": "شكرًا لك على عملية الدفع الخاصة بك.",
            "message_takeaway": "تمت عملية الدفع بنجاح وتم إرسال طلبك إلى المطعم.",
            "refunded1": "عذرًا، لم نتمكن من معالجة طلبك.",
            "refunded2": "تم استرداد المبلغ المدفوع.",
            "title": "تمت عملية الدفع بنجاح.",
            "view_receipt": "عرض الإيصال",
            "waiting": "في انتظار تأكيد الدفع."
          },
          "toast": {
            "bad_items": "بعض العناصر في طلبك غير متوفرة حاليًا وتمت إزالتها.",
            "location_closed": "هذا الموقع لا يقبل الطلبات حاليًا.",
            "order_placed": "تم تقديم طلبك."
          },
          "view_basket": "عرض السلة",
          "view_bill": "عرض الفاتورة"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "be": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Сельдзі",
        "cereals": "Глютэн",
        "crustaceans": "Ракападобныя",
        "dairy": "Малочныя прадукты",
        "eggs": "Яйкі",
        "fish": "Рыба",
        "lupin": "Лупін",
        "mustard": "Гарчыца",
        "nuts": "Галінавыя плоды",
        "peanuts": "Арахіс",
        "sesame": "Кунжут",
        "shellfish": "Малакі",
        "soy": "Сая",
        "sulphur": "Сульфіты"
      },
      "common": {
        "actions": "Actions",
        "back": "Назад",
        "cancel": "Адмяніць",
        "confirm": "Пацвердзіць",
        "continue": "Працягнуць",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Бяглютэнавы вольны",
        "halal": "Халяль",
        "kosher": "Кашрут",
        "teetotal": "Цвярозы",
        "vegan": "Веганскі",
        "vegetarian": "Вегетарыянскі"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Дадаць прадукты з меню, каб пачаць",
            "empty_title": "Ваш кошык пусты",
            "go_to_checkout": "Перайсці ў кошык",
            "place_order": "Размясціць заказ",
            "title": "Ваш кошык",
            "total": "Усяго"
          },
          "bill": {
            "go_to_payment": "Перайсці да аплаты",
            "outstanding": "Засталасць да аплаты",
            "paid": "Сплачана",
            "title": "Ваш рахунак",
            "total": "Усяго"
          },
          "change_menu": "Змяніць меню",
          "checkout": {
            "email": "Email",
            "ending_in": "Карта заканчваецца на %{last4}",
            "name": "Імя",
            "or": "або",
            "pay": "Заплаціць %{amount}",
            "previous_payment": "Раней выкарыстоўваемыя карты",
            "title": "Калядаўка",
            "total": "Усяго",
            "use": "Сплаціць"
          },
          "choose_menu": "Выбраць меню",
          "closed": "%{name} у дадзены момант не прымае заказы.",
          "item": {
            "add_for": "Дадаць для %{price}",
            "choose_size": "Выбраць памер",
            "contains": "Змяшчае:",
            "increase_quantity": "Павялічыць колькасць",
            "reduce_quantity": "Змяніць колькасць",
            "select_at_least": "Калі ласка, выберыце па крайняй меры %{min} варыянт(аў).",
            "select_one": "Калі ласка, выберыце варыянт."
          },
          "kcal_disclaimer": "Дарослым людзям патрэбна каля 2000 калорый на дзень.",
          "no_menus": "У рэстаране ў дадзены момант няма даступных меню.",
          "payment_success": {
            "back_to_menu": "Вярнуцца да меню",
            "failed1": "Ваша аплата не атрымалася.",
            "failed2": "Калі ласка, паспрабуйце яшчэ раз ці звяжыцеся з персаналам рэстарана.",
            "message": "Дзякуй за аплату.",
            "message_takeaway": "Ваша аплата атрымалася і ваш заказ адпраўлены ў рэстаран.",
            "refunded1": "Выбачайце, мы не можам апрацаваць ваш заказ.",
            "refunded2": "Ваша аплата вярнута.",
            "title": "Аплата паспяхова працягнутая",
            "view_receipt": "Прагляд чэк",
            "waiting": "Чакаем пацверджання аплаты"
          },
          "toast": {
            "bad_items": "Некаторыя прадукты ў вашым заказе больш недаступныя і былі выдаленыя",
            "location_closed": "Гэта месца ў дадзены момант не прымае заказы",
            "order_placed": "Ваш заказ размяшчаны"
          },
          "view_basket": "Прагледзець кошык",
          "view_bill": "Прагледзець рахунак"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "bg": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Целина",
        "cereals": "Глутен",
        "crustaceans": "Ракообразни",
        "dairy": "Млечни продукти",
        "eggs": "Яйца",
        "fish": "Риба",
        "lupin": "Люпин",
        "mustard": "Горчица",
        "nuts": "Ядки",
        "peanuts": "Фъстъци",
        "sesame": "Сусам",
        "shellfish": "Черупчести",
        "soy": "Соя",
        "sulphur": "Сулфити"
      },
      "common": {
        "actions": "Actions",
        "back": "Назад",
        "cancel": "Отказ",
        "confirm": "Потвърждение",
        "continue": "Продължи",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Без глутен",
        "halal": "Халал",
        "kosher": "Кошер",
        "teetotal": "Безалкохолни напитки",
        "vegan": "Веган",
        "vegetarian": "Вегетариански"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Добавете елементи от менюто, за да започнете",
            "empty_title": "Вашата кошница е празна",
            "go_to_checkout": "Отидете на покупки",
            "place_order": "Поръчайте",
            "title": "Вашата кошница",
            "total": "Общо"
          },
          "bill": {
            "go_to_payment": "Отидете на плащане",
            "outstanding": "Неплатена сума",
            "paid": "Платено",
            "title": "Вашият сметка",
            "total": "Общо"
          },
          "change_menu": "Промяна на менюто",
          "checkout": {
            "email": "Имейл",
            "ending_in": "Карта, завършваща на %{last4}",
            "name": "Име",
            "or": "или",
            "pay": "Платете %{amount}",
            "previous_payment": "Предишно използвани карти",
            "title": "Плащане",
            "total": "Общо",
            "use": "Плати"
          },
          "choose_menu": "Изберете меню",
          "closed": "%{name} понастоящем не приема поръчки.",
          "item": {
            "add_for": "Добави за %{price}",
            "choose_size": "Изберете размер",
            "contains": "Съдържа:",
            "increase_quantity": "Увеличаване на количеството",
            "reduce_quantity": "Намаляване на количеството",
            "select_at_least": "Моля, изберете поне %{min} опции.",
            "select_one": "Моля, изберете опция."
          },
          "kcal_disclaimer": "Възрастните нуждаят се от около 2000 калории на ден.",
          "no_menus": "Ресторантът няма налични менюта понастоящем.",
          "payment_success": {
            "back_to_menu": "Назад към менюто",
            "failed1": "Плащането ви беше неуспешно.",
            "failed2": "Моля, опитайте отново или се свържете с персонала на ресторанта.",
            "message": "Благодарим ви за плащането.",
            "message_takeaway": "Плащането ви беше успешно и вашата поръчка беше изпратена на ресторанта.",
            "refunded1": "Съжаляваме, не можахме да обработим вашата поръчка.",
            "refunded2": "Плащането ви беше възстановено.",
            "title": "Плащането е успешно",
            "view_receipt": "Преглед на касова бележка",
            "waiting": "Изчакване на потвърждение за плащане"
          },
          "toast": {
            "bad_items": "Някои елементи от вашата поръчка вече не са налични и са премахнати",
            "location_closed": "Тази локация понастоящем не приема поръчки",
            "order_placed": "Вашата поръчка е приета"
          },
          "view_basket": "Преглед на кошницата",
          "view_bill": "Преглед на сметката"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "bn": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "শাকবিশেষ",
        "cereals": "গ্লুটেন",
        "crustaceans": "ক্রাস্টেশিয়ান",
        "dairy": "ডেয়ারি",
        "eggs": "ডিম",
        "fish": "মাছ",
        "lupin": "লুপিন",
        "mustard": "সরিষা",
        "nuts": "বাদাম",
        "peanuts": "বাদাম",
        "sesame": "তিল",
        "shellfish": "শেলফিশ",
        "soy": "সয়াবিন",
        "sulphur": "সালফার"
      },
      "common": {
        "actions": "Actions",
        "back": "পিছনে যান",
        "cancel": "বাতিল করুন",
        "confirm": "নিশ্চিত করুন",
        "continue": "চালিয়ে যান",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "গ্লুটেন মুক্ত",
        "halal": "হালাল",
        "kosher": "খাসার",
        "teetotal": "অলকোহল বিহীন",
        "vegan": "ভেজান",
        "vegetarian": "ভেজিটেরিয়ান"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "শুরু করতে মেনু থেকে আইটেম যোগ করুন",
            "empty_title": "আপনার ঝুলি খালি",
            "go_to_checkout": "চেকআউটে যান",
            "place_order": "অর্ডার করুন",
            "title": "আপনার ঝুলি",
            "total": "মোট"
          },
          "bill": {
            "go_to_payment": "পেমেন্টে যান",
            "outstanding": "বকেয়া মূল্য",
            "paid": "পেইড",
            "title": "আপনার বিল",
            "total": "মোট"
          },
          "change_menu": "মেনু পরিবর্তন",
          "checkout": {
            "email": "ইমেইল",
            "ending_in": "%{last4} শেষ হচ্ছে কার্ড",
            "name": "নাম",
            "or": "অথবা",
            "pay": "দাম পরিশোধ করুন %{amount}",
            "previous_payment": "আগে ব্যবহৃত কার্ড",
            "title": "চেকআউট",
            "total": "মোট",
            "use": "পরিশোধ করুন"
          },
          "choose_menu": "একটি মেনু চয়ন করুন",
          "closed": "%{name} বর্তমানে অর্ডার গ্রহণ করছে না।",
          "item": {
            "add_for": "%{price} এর জন্য যোগ করুন",
            "choose_size": "একটি আকার নির্বাচন করুন",
            "contains": "ধারণ করে:",
            "increase_quantity": "পরিমাণ বৃদ্ধি",
            "reduce_quantity": "পরিমাণ হ্রাস",
            "select_at_least": "অনুগ্রহ করে অবশ্যই %{min} অপশন নির্বাচন করুন।",
            "select_one": "অনুগ্রহ করে একটি অপশন নির্বাচন করুন।"
          },
          "kcal_disclaimer": "প্রাপ্তবয়স্কদের প্রতিদিন প্রায় ২০০০ ক্যালরি প্রয়োজন।",
          "no_menus": "রেস্তোরাঁ এখন পাওয়া মেনু উপলব্ধ নেই।",
          "payment_success": {
            "back_to_menu": "মেনু তে ফিরে যান",
            "failed1": "আপনার পেমেন্ট সফল হয়নি।",
            "failed2": "অনুগ্রহ করে আবার চেষ্টা করুন বা রেস্তোরাঁ স্টাফের সাথে যোগাযোগ করুন।",
            "message": "আপনার পেমেন্টের জন্য ধন্যবাদ।",
            "message_takeaway": "আপনার পেমেন্ট সফল হয়েছে এবং আপনার অর্ডারটি রেস্তোরাঁতে প্ঠোষিত হয়েছে।",
            "refunded1": "দুঃখিত, আমরা আপনার অর্ডারটি প্রসেস করতে পারলাম না।",
            "refunded2": "আপনার পেমেন্ট ফেরৎ হয়েছে।",
            "title": "পেমেন্ট সফল",
            "view_receipt": "রসিদ দেখুন",
            "waiting": "পেমেন্ট নিশ্চিত হওয়ার জন্য অপেক্ষা করা হচ্ছে"
          },
          "toast": {
            "bad_items": "আপনার অর্ডারে কিছু আইটেম এখন উপলব্ধ না থাকায় সেগুলি সরানো হয়েছে",
            "location_closed": "এই লোকেশনটি বর্তমানে অর্ডার গ্রহণ করছে না",
            "order_placed": "আপনার অর্ডার প্রদান করা হয়েছে"
          },
          "view_basket": "ঝুলি দেখুন",
          "view_bill": "বিল দেখুন"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "cs": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Celer",
        "cereals": "Lepek",
        "crustaceans": "Korýši",
        "dairy": "Mléčné výrobky",
        "eggs": "Vejce",
        "fish": "Ryb",
        "lupin": "Lupina",
        "mustard": "Hořčice",
        "nuts": "Oříšky",
        "peanuts": "Arašídy",
        "sesame": "Sezam",
        "shellfish": "Mořské plody",
        "soy": "Sójové výrobky",
        "sulphur": "Síranové sloučeniny"
      },
      "common": {
        "actions": "Actions",
        "back": "Zpět",
        "cancel": "Zrušit",
        "confirm": "Potvrdit",
        "continue": "Pokračovat",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bezlepkové",
        "halal": "Halal",
        "kosher": "Košer",
        "teetotal": "Nealkoholické",
        "vegan": "Veganské",
        "vegetarian": "Vegetariánské"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Přidejte položky z nabídky a začněte nakupovat",
            "empty_title": "Váš košík je prázdný",
            "go_to_checkout": "Přejít k pokladně",
            "place_order": "Objednat",
            "title": "Váš košík",
            "total": "Celkem"
          },
          "bill": {
            "go_to_payment": "Přejít k platbě",
            "outstanding": "Zbývající zůstatek",
            "paid": "Zaplaceno",
            "title": "Vaše faktura",
            "total": "Celkem"
          },
          "change_menu": "Změnit nabídku",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Karta končící na %{last4}",
            "name": "Jméno",
            "or": "nebo",
            "pay": "Zaplatit %{amount}",
            "previous_payment": "Dříve použité karty",
            "title": "Pokladna",
            "total": "Celkem",
            "use": "Zaplatit"
          },
          "choose_menu": "Vyberte si nabídku",
          "closed": "%{name} v současné době nepřijímá objednávky",
          "item": {
            "add_for": "Přidat pro %{price}",
            "choose_size": "Vyberte velikost",
            "contains": "Obsahuje:",
            "increase_quantity": "Zvýšit množství",
            "reduce_quantity": "Snížit množství",
            "select_at_least": "Vyberte prosím alespoň %{min} možností",
            "select_one": "Prosím vyberte možnost"
          },
          "kcal_disclaimer": "Dospělí potřebují kolem 2000 kcal denně.",
          "no_menus": "V tuto chvíli restaurace nemá k dispozici žádné nabídky",
          "payment_success": {
            "back_to_menu": "Zpět do nabídky",
            "failed1": "Vaše platba byla neúspěšná",
            "failed2": "Zkuste to prosím znovu nebo kontaktujte personál restaurace",
            "message": "Děkujeme za vaši platbu",
            "message_takeaway": "Vaše platba byla úspěšná a vaše objednávka byla odeslána restauraci",
            "refunded1": "Omlouváme se, vaši objednávku jsme nemohli zpracovat",
            "refunded2": "Vaše platba byla vrácena",
            "title": "Platba úspěšná",
            "view_receipt": "Zobrazit účtenku",
            "waiting": "Čekání na potvrzení platby"
          },
          "toast": {
            "bad_items": "Některé položky ve vaší objednávce již nejsou k dispozici a byly odebrány",
            "location_closed": "Tato lokalita v současné době nepřijímá objednávky",
            "order_placed": "Vaše objednávka byla přijata"
          },
          "view_basket": "Zobrazit košík",
          "view_bill": "Zobrazit fakturu"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "da": {
    "common": {
      "actions": "Handlinger",
      "active": "Aktiv",
      "add": "Tilføj",
      "and_x_more": "og %{count} mere",
      "any": "Nogen",
      "archive": "Arkivér",
      "archived": "Arkiveret",
      "are_you_sure": "Er du sikker?",
      "back": "Tilbage",
      "cancel": "Afbryd",
      "choose": "Vælg",
      "close": "Luk",
      "closed": "Lukket",
      "confirm": "Bekræft",
      "confirm_delete": "Er du sikker?",
      "continue": "Fortsæt",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Slet",
      "details": "Detaljer",
      "do_not_refresh_page": "Opdater ikke eller luk siden.",
      "edit": "Rediger",
      "form_error_html": "Noget af den indsendte data er ugyldig. Kontrollér formularen for fejl og prøv igen. Hvis problemet fortsætter, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">kontakt kundesupport</a>.",
      "learn_more": "Læs mere",
      "minutes": "Minutes",
      "never": "Aldrig",
      "no": "Nej",
      "none": "Ingen",
      "not_active": "Ikke aktiv",
      "not_provided": "Fraværende",
      "processing": "Behandler",
      "remove": "Fjern",
      "restore": "Gendan",
      "save": "Gem",
      "selected": "Valgt",
      "service_types": {
        "at_table": "Ved bord",
        "delivery": "Levering",
        "takeaway": "Takeaway"
      },
      "settings_saved": "Indstillingerne er blevet gemt.",
      "show": "Vis",
      "talk_to_an_expert": "Tal med en ekspert",
      "view": "Vis",
      "yes": "Ja"
    },
    "js": {
      "allergens": {
        "celery": "Selleri",
        "cereals": "Gluten",
        "crustaceans": "Krebsdyr",
        "dairy": "Mejeri",
        "eggs": "Æg",
        "fish": "Fisk",
        "lupin": "Lupin",
        "mustard": "Sennep",
        "nuts": "Nødder",
        "peanuts": "Peanuts",
        "sesame": "Sesam",
        "shellfish": "Skaldyr",
        "soy": "Soja",
        "sulphur": "Sulfitter"
      },
      "common": {
        "actions": "Actions",
        "back": "Tilbage",
        "cancel": "Annuller",
        "confirm": "Bekræft",
        "continue": "Fortsæt",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenfri",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Ikke-alkoholisk",
        "vegan": "Vegansk",
        "vegetarian": "Vegetarisk"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Tilføj varer fra menuen for at komme i gang",
            "empty_title": "Din kurv er tom",
            "go_to_checkout": "Gå til betaling",
            "place_order": "Afgiv ordre",
            "title": "Din kurv",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Gå til betaling",
            "outstanding": "Udestående beløb",
            "paid": "Betalt",
            "title": "Din regning",
            "total": "Total"
          },
          "change_menu": "Skift menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Kort der slutter på %{last4}",
            "name": "Navn",
            "or": "eller",
            "pay": "Betal %{amount}",
            "previous_payment": "Tidligere brugte kort",
            "title": "Gå til betaling",
            "total": "Total",
            "use": "Betale"
          },
          "choose_menu": "Vælg en menu",
          "closed": "%{name} tager i øjeblikket ikke imod ordrer.",
          "item": {
            "add_for": "Tilføj til %{price}",
            "choose_size": "Vælg en størrelse",
            "contains": "Indeholder:",
            "increase_quantity": "Øg antal",
            "reduce_quantity": "Reducer antal",
            "select_at_least": "Vælg mindst %{min} muligheder.",
            "select_one": "Vælg en mulighed."
          },
          "kcal_disclaimer": "Voksne har brug for omkring 2000 kcal om dagen.",
          "no_menus": "Restauranten har i øjeblikket ingen tilgængelige menuer.",
          "payment_success": {
            "back_to_menu": "Tilbage til menuen",
            "failed1": "Din betaling mislykkedes.",
            "failed2": "Prøv venligst igen eller kontakt restaurantpersonalet.",
            "message": "Tak for din betaling.",
            "message_takeaway": "Din betaling var vellykket, og din ordre er blevet sendt til restauranten.",
            "refunded1": "Beklager, vi kunne ikke behandle din ordre.",
            "refunded2": "Din betaling er blevet refunderet.",
            "title": "Betaling vellykket",
            "view_receipt": "Se kvittering",
            "waiting": "Venter på betalingsbekræftelse"
          },
          "toast": {
            "bad_items": "Nogle varer i din ordre er ikke længere tilgængelige og er blevet fjernet",
            "location_closed": "Denne placering modtager i øjeblikket ikke ordrer",
            "order_placed": "Din ordre er blevet placeret"
          },
          "view_basket": "Se kurv",
          "view_bill": "Se regning"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "de": {
    "common": {
      "actions": "Aktionen",
      "active": "Aktiv",
      "add": "Hinzufügen",
      "and_x_more": "und %{count} mehr",
      "any": "Jede",
      "archive": "Archivieren",
      "archived": "Archiviert",
      "are_you_sure": "Bist du sicher?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "choose": "Auswählen",
      "close": "Schließen",
      "closed": "Geschlossen",
      "confirm": "Bestätigen",
      "confirm_delete": "Bist du sicher?",
      "continue": "Weiter",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Löschen",
      "details": "Details",
      "do_not_refresh_page": "Aktualisieren oder Seite schließen.",
      "edit": "Bearbeiten",
      "form_error_html": "Einige der übermittelten Daten sind ungültig. Bitte überprüfen Sie das Formular auf Fehler und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">kontaktieren Sie den Kundensupport</a>.",
      "learn_more": "Mehr erfahren",
      "minutes": "Minutes",
      "never": "Nie",
      "no": "Nein",
      "none": "Keine",
      "not_active": "Nicht aktiv",
      "not_provided": "Abwesend",
      "processing": "Verarbeitung",
      "remove": "Entfernen",
      "restore": "Wiederherstellen",
      "save": "Speichern",
      "selected": "Ausgewählt",
      "service_types": {
        "at_table": "Am Tisch",
        "delivery": "Lieferung",
        "takeaway": "Mitnehmen"
      },
      "settings_saved": "Die Einstellungen wurden gespeichert.",
      "show": "Anzeigen",
      "talk_to_an_expert": "Mit einem Experten sprechen",
      "view": "Ansehen",
      "yes": "Ja"
    },
    "js": {
      "allergens": {
        "celery": "Sellerie",
        "cereals": "Gluten",
        "crustaceans": "Krustentiere",
        "dairy": "Milchprodukte",
        "eggs": "Eier",
        "fish": "Fisch",
        "lupin": "Lupinen",
        "mustard": "Senf",
        "nuts": "Nüsse",
        "peanuts": "Erdnüsse",
        "sesame": "Sesam",
        "shellfish": "Schalentiere",
        "soy": "Soja",
        "sulphur": "Sulfite"
      },
      "common": {
        "actions": "Actions",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "confirm": "Bestätigen",
        "continue": "Fortfahren",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenfrei",
        "halal": "Halal",
        "kosher": "Koscher",
        "teetotal": "Alkoholfrei",
        "vegan": "Vegan",
        "vegetarian": "Vegetarisch"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Artikel aus der Speisekarte hinzufügen, um zu beginnen",
            "empty_title": "Ihr Warenkorb ist leer",
            "go_to_checkout": "Zur Kasse gehen",
            "place_order": "Bestellung aufgeben",
            "title": "Ihr Warenkorb",
            "total": "Gesamt"
          },
          "bill": {
            "go_to_payment": "Zur Zahlung gehen",
            "outstanding": "Ausstehender Betrag",
            "paid": "Bezahlt",
            "title": "Ihre Rechnung",
            "total": "Gesamt"
          },
          "change_menu": "Menü ändern",
          "checkout": {
            "email": "E-Mail",
            "ending_in": "Karte endet auf %{last4}",
            "name": "Name",
            "or": "oder",
            "pay": "%{amount} bezahlen",
            "previous_payment": "Zuvor verwendete Karten",
            "title": "Zur Kasse",
            "total": "Gesamt",
            "use": "Bezahlen"
          },
          "choose_menu": "Menü auswählen",
          "closed": "%{name} akzeptiert derzeit keine Bestellungen.",
          "item": {
            "add_for": "Für %{price} hinzufügen",
            "choose_size": "Größe wählen",
            "contains": "Enthält:",
            "increase_quantity": "Menge erhöhen",
            "reduce_quantity": "Menge verringern",
            "select_at_least": "Bitte wählen Sie mindestens %{min} Optionen aus.",
            "select_one": "Bitte wählen Sie eine Option aus."
          },
          "kcal_disclaimer": "Erwachsene benötigen täglich etwa 2000 kcal.",
          "no_menus": "Das Restaurant hat derzeit keine verfügbaren Menüs.",
          "payment_success": {
            "back_to_menu": "Zurück zum Menü",
            "failed1": "Ihre Zahlung war nicht erfolgreich.",
            "failed2": "Bitte versuchen Sie es erneut oder kontaktieren Sie das Restaurantpersonal.",
            "message": "Vielen Dank für Ihre Zahlung.",
            "message_takeaway": "Ihre Zahlung war erfolgreich und Ihre Bestellung wurde an das Restaurant gesendet.",
            "refunded1": "Entschuldigung, wir konnten Ihre Bestellung nicht verarbeiten.",
            "refunded2": "Ihre Zahlung wurde zurückerstattet.",
            "title": "Zahlung erfolgreich",
            "view_receipt": "Quittung anzeigen",
            "waiting": "Warten auf Zahlungsbestätigung"
          },
          "toast": {
            "bad_items": "Einige Artikel in Ihrer Bestellung sind nicht mehr verfügbar und wurden entfernt",
            "location_closed": "Dieser Standort nimmt derzeit keine Bestellungen entgegen",
            "order_placed": "Ihre Bestellung wurde aufgegeben"
          },
          "view_basket": "Warenkorb anzeigen",
          "view_bill": "Rechnung anzeigen"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Zentimeter",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Gramm",
      "gal": "Gallon",
      "kg": "Kilogramm",
      "l": "Liter",
      "lb": "Pfund",
      "mg": "Milligrams",
      "ml": "Milliliter",
      "oz": "Unzen",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "el": {
    "common": {
      "actions": "Ενέργειες",
      "active": "Ενεργός",
      "add": "Προσθήκη",
      "and_x_more": "και άλλες %{count}",
      "any": "Οποιοδήποτε",
      "archive": "Αρχειοθέτηση",
      "archived": "Αρχειοθετημένα",
      "are_you_sure": "Είστε σίγουροι;",
      "back": "Πίσω",
      "cancel": "Ακύρωση",
      "choose": "Επιλογή",
      "close": "Κλείσιμο",
      "closed": "Κλειστό",
      "confirm": "Επιβεβαίωση",
      "confirm_delete": "Είστε σίγουροι;",
      "continue": "Συνέχεια",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Διαγραφή",
      "details": "Λεπτομέρειες",
      "do_not_refresh_page": "Μην ανανεώνετε ή κλείνετε τη σελίδα.",
      "edit": "Επεξεργασία",
      "form_error_html": "Ορισμένα από τα υποβληθέντα δεδομένα δεν είναι έγκυρα. Παρακαλώ ελέγξτε τη φόρμα για σφάλματα και δοκιμάστε ξανά. Εάν το πρόβλημα εξακολουθεί, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">επικοινωνήστε με την εξυπηρέτηση πελατών</a>.",
      "learn_more": "Μάθετε περισσότερα",
      "minutes": "Minutes",
      "never": "Ποτέ",
      "no": "Όχι",
      "none": "Κανένας",
      "not_active": "Μη ενεργός",
      "not_provided": "Απών",
      "processing": "Επεξεργασία",
      "remove": "Αφαίρεση",
      "restore": "Ανάκτηση",
      "save": "Αποθήκευση",
      "selected": "Επιλεγμένο",
      "service_types": {
        "at_table": "Στο τραπέζι",
        "delivery": "Παράδοση",
        "takeaway": "Παραλαβή"
      },
      "settings_saved": "Οι ρυθμίσεις έχουν αποθηκευτεί.",
      "show": "Εμφάνιση",
      "talk_to_an_expert": "Μιλήστε σε έναν ειδικό",
      "view": "Προβολή",
      "yes": "Ναι"
    },
    "js": {
      "allergens": {
        "celery": "Σέλινο",
        "cereals": "Γλουτένη",
        "crustaceans": "Καρκινιδικά",
        "dairy": "Γαλακτοκομικά",
        "eggs": "Αυγά",
        "fish": "Ψάρι",
        "lupin": "Λούπινο",
        "mustard": "Μουστάρδα",
        "nuts": "Ξηροί καρποί",
        "peanuts": "Φιστίκια",
        "sesame": "Σησαμέλαιο",
        "shellfish": "Θαλασσινά",
        "soy": "Σόγια",
        "sulphur": "Θειώδη"
      },
      "common": {
        "actions": "Actions",
        "back": "Πίσω",
        "cancel": "Ακύρωση",
        "confirm": "Επιβεβαίωση",
        "continue": "Συνέχεια",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Χωρίς γλουτένη",
        "halal": "Χαλάλ",
        "kosher": "Κασέρ",
        "teetotal": "Χωρίς αλκοόλ",
        "vegan": "Φυτοφάγος",
        "vegetarian": "Χορτοφάγος"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Προσθέστε προϊόντα από το μενού για να ξεκινήσετε",
            "empty_title": "Το καλάθι σας είναι άδειο",
            "go_to_checkout": "Μετάβαση στην ολοκλήρωση αγοράς",
            "place_order": "Ολοκλήρωση παραγγελίας",
            "title": "Το καλάθι σας",
            "total": "Σύνολο"
          },
          "bill": {
            "go_to_payment": "Μετάβαση στην πληρωμή",
            "outstanding": "Εκκρεμές υπόλοιπο",
            "paid": "Πληρώθηκε",
            "title": "Το τιμολόγιό σας",
            "total": "Σύνολο"
          },
          "change_menu": "Αλλαγή μενού",
          "checkout": {
            "email": "Email",
            "ending_in": "Κάρτα λήγουσα σε %{last4}",
            "name": "Όνομα",
            "or": "ή",
            "pay": "Πληρωμή %{amount}",
            "previous_payment": "Προηγουμένως χρησιμοποιημένες κάρτες",
            "title": "Ολοκλήρωση αγοράς",
            "total": "Σύνολο",
            "use": "Πληρωμή"
          },
          "choose_menu": "Επιλέξτε ένα μενού",
          "closed": "Το %{name} δεν δέχεται παραγγελίες αυτήν τη στιγμή.",
          "item": {
            "add_for": "Προσθήκη για το %{price}",
            "choose_size": "Επιλέξτε μέγεθος",
            "contains": "Περιέχει:",
            "increase_quantity": "Αύξηση ποσότητας",
            "reduce_quantity": "Μείωση ποσότητας",
            "select_at_least": "Επιλέξτε τουλάχιστον %{min} επιλογές.",
            "select_one": "Επιλέξτε μία επιλογή."
          },
          "kcal_disclaimer": "Οι ενήλικες χρειάζονται περίπου 2000 θερμίδες την ημέρα.",
          "no_menus": "Το εστιατόριο δεν έχει διαθέσιμα μενού αυτήν τη στιγμή.",
          "payment_success": {
            "back_to_menu": "Επιστροφή στο μενού",
            "failed1": "Η πληρωμή σας απέτυχε.",
            "failed2": "Παρακαλώ προσπαθήστε ξανά ή επικοινωνήστε με το προσωπικό του εστιατορίου.",
            "message": "Σας ευχαριστούμε για την πληρωμή σας.",
            "message_takeaway": "Η πληρωμή σας ήταν επιτυχής και η παραγγελία σας έχει σταλεί στο εστιατόριο.",
            "refunded1": "Λυπάμαι, δεν μπορέσαμε να επεξεργαστούμε την παραγγελία σας.",
            "refunded2": "Η πληρωμή σας έχει επιστραφεί.",
            "title": "Επιτυχής πληρωμή",
            "view_receipt": "Προβολή απόδειξης",
            "waiting": "Αναμονή επιβεβαίωσης πληρωμής"
          },
          "toast": {
            "bad_items": "Ορισμένα αντικείμενα στην παραγγελία σας δεν είναι πλέον διαθέσιμα και έχουν αφαιρεθεί",
            "location_closed": "Αυτή η τοποθεσία δεν δέχεται προσφορές επί του παρόντος",
            "order_placed": "Η παραγγελία σας έχει τοποθετηθεί"
          },
          "view_basket": "Προβολή καλαθιού",
          "view_bill": "Προβολή λογαριασμού"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Εκατοστά",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Γραμμάρια",
      "gal": "Gallon",
      "kg": "Κιλά",
      "l": "Λίτρα",
      "lb": "Λίβρες",
      "mg": "Milligrams",
      "ml": "Χιλιόλιτρα",
      "oz": "Ουγγιές",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "en": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Celery",
        "cereals": "Gluten",
        "crustaceans": "Crustaceans",
        "dairy": "Dairy",
        "eggs": "Eggs",
        "fish": "Fish",
        "lupin": "Lupin",
        "mustard": "Mustard",
        "nuts": "Nuts",
        "peanuts": "Peanuts",
        "sesame": "Sesame",
        "shellfish": "Shellfish",
        "soy": "Soy",
        "sulphur": "Sulphites"
      },
      "common": {
        "actions": "Actions",
        "back": "Back",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "continue": "Continue",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Gluten free",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Non-alcoholic",
        "vegan": "Vegan",
        "vegetarian": "Vegetarian"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Add items from the menu to get started",
            "empty_title": "Your basket is empty",
            "go_to_checkout": "Go to checkout",
            "place_order": "Place order",
            "title": "Your basket",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Go to payment",
            "outstanding": "Outstanding balance",
            "paid": "Paid",
            "title": "Your bill",
            "total": "Total"
          },
          "change_menu": "Change menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Card ending in %{last4}",
            "name": "Name",
            "or": "or",
            "pay": "Pay %{amount}",
            "previous_payment": "Previously used cards",
            "title": "Checkout",
            "total": "Total",
            "use": "Pay"
          },
          "choose_menu": "Choose a menu",
          "closed": "%{name} is not currently accepting orders.",
          "item": {
            "add_for": "Add for %{price}",
            "choose_size": "Choose a size",
            "contains": "Contains:",
            "increase_quantity": "Increase quantity",
            "reduce_quantity": "Reduce quantity",
            "select_at_least": "Please select at least %{min} options.",
            "select_one": "Please select an option."
          },
          "kcal_disclaimer": "Adults need around 2000 kcal a day.",
          "no_menus": "The restaurant has no available menus at the moment.",
          "payment_success": {
            "back_to_menu": "Back to the menu",
            "failed1": "Your payment was unsuccessful.",
            "failed2": "Please try again or contact the restaurant staff.",
            "message": "Thank you for your payment.",
            "message_takeaway": "Your payment was successful and your order has been sent to the restaurant.",
            "refunded1": "Sorry, we could not process your order.",
            "refunded2": "Your payment has been refunded.",
            "title": "Payment successful",
            "view_receipt": "View receipt",
            "waiting": "Waiting for payment confirmation"
          },
          "toast": {
            "bad_items": "Some items in your order are no longer available and have been removed",
            "location_closed": "This location is not currently accepting orders",
            "order_placed": "Your order has been placed"
          },
          "view_basket": "View basket",
          "view_bill": "View bill"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "es": {
    "common": {
      "actions": "Acciones",
      "active": "Activo",
      "add": "Agregar",
      "and_x_more": "y %{count} más",
      "any": "Cualquier",
      "archive": "Archivar",
      "archived": "Archivado",
      "are_you_sure": "¿Estás seguro?",
      "back": "Atrás",
      "cancel": "Cancelar",
      "choose": "Seleccionar",
      "close": "Cerrar",
      "closed": "Cerrado",
      "confirm": "Confirmar",
      "confirm_delete": "¿Estás seguro?",
      "continue": "Continuar",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Eliminar",
      "details": "Detalles",
      "do_not_refresh_page": "No refresques ni cierres la página.",
      "edit": "Editar",
      "form_error_html": "Algunos de los datos enviados no son válidos. Por favor, verifique el formulario en busca de errores y vuelva a intentarlo. Si el problema persiste, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contacte al servicio de atención al cliente</a>.",
      "learn_more": "Más información",
      "minutes": "Minutes",
      "never": "Nunca",
      "no": "No",
      "none": "Ninguno",
      "not_active": "No activo",
      "not_provided": "Ausente",
      "processing": "Procesando",
      "remove": "Eliminar",
      "restore": "Restaurar",
      "save": "Guardar",
      "selected": "Seleccionado",
      "service_types": {
        "at_table": "En la mesa",
        "delivery": "Entrega",
        "takeaway": "Para llevar"
      },
      "settings_saved": "Los ajustes han sido guardados.",
      "show": "Mostrar",
      "talk_to_an_expert": "Hablar con un experto",
      "view": "Ver",
      "yes": "Sí"
    },
    "js": {
      "allergens": {
        "celery": "Apio",
        "cereals": "Gluten",
        "crustaceans": "Crustáceos",
        "dairy": "Lácteos",
        "eggs": "Huevos",
        "fish": "Pescado",
        "lupin": "Altramuces",
        "mustard": "Mostaza",
        "nuts": "Frutos secos",
        "peanuts": "Cacahuetes",
        "sesame": "Sésamo",
        "shellfish": "Mariscos",
        "soy": "Soja",
        "sulphur": "Sulfitos"
      },
      "common": {
        "actions": "Actions",
        "back": "Atrás",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "continue": "Continuar",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Sin gluten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Sin alcohol",
        "vegan": "Vegano",
        "vegetarian": "Vegetariano"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Añade elementos del menú para comenzar",
            "empty_title": "Tu cesta está vacía",
            "go_to_checkout": "Ir a pagar",
            "place_order": "Realizar pedido",
            "title": "Tu cesta",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Ir a pago",
            "outstanding": "Saldo pendiente",
            "paid": "Pagado",
            "title": "Tu factura",
            "total": "Total"
          },
          "change_menu": "Cambiar menú",
          "checkout": {
            "email": "Correo electrónico",
            "ending_in": "Tarjeta terminada en %{last4}",
            "name": "Nombre",
            "or": "o",
            "pay": "Pagar %{amount}",
            "previous_payment": "Tarjetas utilizadas anteriormente",
            "title": "Pagar",
            "total": "Total",
            "use": "Pagar"
          },
          "choose_menu": "Elegir un menú",
          "closed": "%{name} no está aceptando pedidos en este momento.",
          "item": {
            "add_for": "Añadir para %{price}",
            "choose_size": "Elegir un tamaño",
            "contains": "Contiene:",
            "increase_quantity": "Aumentar cantidad",
            "reduce_quantity": "Reducir cantidad",
            "select_at_least": "Por favor selecciona al menos %{min} opciones.",
            "select_one": "Por favor selecciona una opción."
          },
          "kcal_disclaimer": "Los adultos necesitan alrededor de 2000 kcal al día.",
          "no_menus": "El restaurante no tiene menús disponibles en este momento.",
          "payment_success": {
            "back_to_menu": "Volver al menú",
            "failed1": "Tu pago no se ha realizado con éxito.",
            "failed2": "Por favor, inténtalo de nuevo o contacta al personal del restaurante.",
            "message": "Gracias por tu pago.",
            "message_takeaway": "Tu pago se realizó con éxito y tu orden ha sido enviada al restaurante.",
            "refunded1": "Lo sentimos, no pudimos procesar tu orden.",
            "refunded2": "Tu pago ha sido reembolsado.",
            "title": "Pago exitoso",
            "view_receipt": "Ver recibo",
            "waiting": "Esperando confirmación de pago"
          },
          "toast": {
            "bad_items": "Algunos artículos de tu orden ya no están disponibles y han sido eliminados",
            "location_closed": "Esta ubicación actualmente no está aceptando órdenes",
            "order_placed": "Tu orden ha sido realizada"
          },
          "view_basket": "Ver cesta",
          "view_bill": "Ver factura"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Cantidad",
            "balance": "Balance",
            "card": "Tarjeta",
            "failure": "Pago fallido",
            "no_terminals": "No hay lectores de tarjetas disponibles",
            "pick_terminal": "Selecciona un lector de tarjetas",
            "split_item": "Selecciona los artículos a pagar",
            "split_person": "Dividir equitativamente",
            "success": "Pago exitoso",
            "terminal_offline": "El lector de tarjetas está fuera de línea",
            "tip": "Propina",
            "try_again": "Intenta de nuevo",
            "waiting_for_card": "Esperando tarjeta"
          },
          "split_person": {
            "out_of_post": "en la mesa",
            "out_of_pre": "fuera de",
            "paying_for_post_one": "persona",
            "paying_for_post_other": "personas",
            "paying_for_pre": "Pagando por",
            "to_split": "Dividiendo"
          }
        },
        "code_assigner": {
          "error": "Ocurrió un error: %{error}",
          "invalid_qr_code": "Este código QR no es un código de mesa válido.",
          "success_end": "¡Excelente trabajo! Todas las mesas tienen asignado un código QR.",
          "success_next": "¡Entendido! Moviendo a la mesa %{number}."
        },
        "common": {
          "are_you_sure": "¿Estás seguro?",
          "back": "Volver",
          "cancel": "Cancelar",
          "close": "Cerrar",
          "confirm": "Confirmar",
          "edit": "Editar",
          "processing": "Procesando",
          "save": "Guardar"
        },
        "kitchen": {
          "exit": "Salir",
          "preparing": "Preparando",
          "ready": "Listo",
          "todo": "Hacer"
        },
        "opening_hours": {
          "add_hours": "Agregar horas",
          "close": "Cerrar",
          "closed": "Cerrado",
          "confirm": "Confirmar",
          "days": [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
          ],
          "error": "Las horas de apertura que ingresaste no representan un rango de tiempo válido. Por favor, verifica los horarios e intenta nuevamente.",
          "open": "Abrir",
          "remove": "Eliminar"
        },
        "ordering": {
          "add": "Agregar",
          "add_button": "Agregar para %{amount}",
          "add_more": "Agregar más",
          "balance": "Saldo",
          "confirm": "Confirmar pedido",
          "confirm_takeout": {
            "confirm": "Confirmar",
            "email": "Correo electrónico del cliente",
            "name": "Nombre del cliente",
            "optional": "(opcional)",
            "phone": "Teléfono del cliente",
            "title": "Confirma tu pedido"
          },
          "contains": "Contiene: ",
          "course": "Plato",
          "decrease": "Disminuir cantidad",
          "increase": "Aumentar cantidad",
          "menus": "Menús",
          "notes": "Notas",
          "open_item_name": "Nombre",
          "open_item_notes": "Notas",
          "open_item_price": "Precio",
          "payments": "Pagos",
          "placed_at": "Realizado en %{time}",
          "please_select_at_least": {
            "one": "Por favor selecciona al menos una opción",
            "other": "Por favor selecciona al menos %{count} opciones"
          },
          "please_select_no_more_than": {
            "one": "Por favor no selecciones más de una opción",
            "other": "Por favor no selecciones más de %{count} opciones"
          },
          "remove": "Eliminar",
          "seated_at": "Sentado en %{time}",
          "size": "Tamaño",
          "tips": "Propinas",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Cantidad de pago",
          "cancel": "Cancelar pago",
          "collect": "Recaudar pago",
          "each": "cada",
          "include_service": "Incluir servicio (%{amount})",
          "minimum": "La cantidad mínima es %{amount}",
          "paid": "pagado",
          "title": "Pagar por"
        },
        "readers": {
          "cancel_pending_payment": "Cancelar pago pendiente",
          "could_not_collect": "No se pudo recopilar el método de pago. Por favor, vuelva a conectar al terminal.",
          "minimum_payment_amount": "El monto mínimo de pago es €0.50.",
          "payment_failed": "El pago no se realizó con éxito. Por favor, inténtelo de nuevo.",
          "select_reader": "Seleccionar terminal",
          "waiting_for_card": "Esperando tarjeta (haga clic para cancelar)"
        },
        "refunds": {
          "custom": {
            "description": "¿Cuánto deseas reembolsar?",
            "limit": "Máx: %{amount}",
            "title": "Reembolso de monto personalizado"
          },
          "summary": {
            "available": "Fuera de %{amount}",
            "kind": {
              "card": "Tarjeta",
              "cash": "Efectivo",
              "complimentary": "Cortesía"
            },
            "not_enough_allocated": "Por favor agregar %{amount} a las asignaciones anteriores",
            "title": "Revisar",
            "title_allocate": "Por favor asignar %{amount} a los siguientes pagos:",
            "title_full": "Las siguientes cantidades serán reembolsadas en su totalidad:",
            "too_much_allocated": "Por favor quitar %{amount} de las asignaciones anteriores",
            "too_much_single": "No puedes reembolsar más que el pago original"
          },
          "type": {
            "custom": "Un monto personalizado",
            "custom_desc": "Si quieres reembolsar un monto específico.",
            "entire_bill": "Toda la cuenta",
            "entire_bill_desc": {
              "one": "%{amount} será reembolsado a un pago.",
              "other": "%{amount} será reembolsado a %{count} pagos."
            },
            "title": "¿Qué deseas reembolsar?"
          }
        },
        "requests": {
          "payment": "Quiere pagar",
          "waiter": "Pidió un camarero",
          "water": "Quiere agua del grifo"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centímetros",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Gramos",
      "gal": "Gallon",
      "kg": "Kilogramos",
      "l": "Litros",
      "lb": "Libras",
      "mg": "Milligrams",
      "ml": "Mililitros",
      "oz": "Onzas",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "et": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selleri",
        "cereals": "Gluteen",
        "crustaceans": "Koorikloomad",
        "dairy": "Piim",
        "eggs": "Munad",
        "fish": "Kala",
        "lupin": "Lupiin",
        "mustard": "Sinep",
        "nuts": "Pähklid",
        "peanuts": "Maapähklid",
        "sesame": "Seesam",
        "shellfish": "Koorikloomad",
        "soy": "Soya",
        "sulphur": "Sulfitid"
      },
      "common": {
        "actions": "Actions",
        "back": "Tagasi",
        "cancel": "Tühista",
        "confirm": "Kinnita",
        "continue": "Jätka",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Gluteenivaba",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alkoholivaba",
        "vegan": "Vegan",
        "vegetarian": "Vegetaarlane"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Lisa esemeid menüüst alustamiseks",
            "empty_title": "Teie ostukorv on tühi",
            "go_to_checkout": "Minge kassasse",
            "place_order": "Esitage tellimus",
            "title": "Teie ostukorv",
            "total": "Kog"
          },
          "bill": {
            "go_to_payment": "Minge maksele",
            "outstanding": "Väljaminev saldo",
            "paid": "Makstud",
            "title": "Teie arve",
            "total": "Kokku"
          },
          "change_menu": " Viimane",
          "checkout": {
            "email": "E-post",
            "ending_in": "Kaart lõpeb %{last4}-ga",
            "name": "Nimi",
            "or": "või",
            "pay": "Maksa %{amount}",
            "previous_payment": "Eelnevalt kasutatud kaardid",
            "title": "Välju",
            "total": "Kokku",
            "use": "Maksa"
          },
          "choose_menu": "Valige menüü",
          "closed": "%{name} ei võta hetkel tellimusi vastu.",
          "item": {
            "add_for": "Lisa %{price} jaoks",
            "choose_size": "Valige suurus",
            "contains": "Sisaldab:",
            "increase_quantity": "Suurenda kogust",
            "reduce_quantity": "Vähenda kogust",
            "select_at_least": "Valige vähemalt %{min} valikut.",
            "select_one": "Palun valige üks valik."
          },
          "kcal_disclaimer": "Täiskasvanutel on vaja ligikaudu 2000 kcal päevas.",
          "no_menus": "Restoranil pole hetkel saadavalolevaid menüüsid.",
          "payment_success": {
            "back_to_menu": "Tagasi menüüsse",
            "failed1": "Teie makse ebaõnnestus.",
            "failed2": "Palun proovige uuesti või võtke ühendust restorani töötajatega.",
            "message": "Aitäh maksmast.",
            "message_takeaway": "Teie makse oli edukas ja teie tellimus on saadetud restoranile.",
            "refunded1": "Vabandust, me ei saanud teie tellimust töödelda.",
            "refunded2": "Teie makse on tagastatud.",
            "title": "Makse õnnestus",
            "view_receipt": "Vaata tšekki",
            "waiting": "Maksekinnitust oodatakse"
          },
          "toast": {
            "bad_items": "Mõned tellimuse üksused pole enam saadaval ja on eemaldatud",
            "location_closed": "See asukoht ei võta hetkel tellimusi vastu",
            "order_placed": "Teie tellimus on esitatud"
          },
          "view_basket": "Vaata ostukorvi",
          "view_bill": "Vaata arvet"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "fi": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selleri",
        "cereals": "Gluteeni",
        "crustaceans": "Äyriäiset",
        "dairy": "Maito",
        "eggs": "Munat",
        "fish": "Kala",
        "lupin": "Lupiini",
        "mustard": "Sinappi",
        "nuts": "Pähkinät",
        "peanuts": "Maapähkinät",
        "sesame": "Seesaminsiemen",
        "shellfish": "Äyriäiset",
        "soy": "Soija",
        "sulphur": "Sulfiitit"
      },
      "common": {
        "actions": "Actions",
        "back": "Takaisin",
        "cancel": "Peruuta",
        "confirm": "Vahvista",
        "continue": "Jatka",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Gluteeniton",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alkoholiton",
        "vegan": "Vegaaninen",
        "vegetarian": "Kasvissyöjä"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Lisää kohteita valikosta aloittaaksesi",
            "empty_title": "Korisi on tyhjä",
            "go_to_checkout": "Siirry kassalle",
            "place_order": "Tilaa",
            "title": "Korisi",
            "total": "Yhteensä"
          },
          "bill": {
            "go_to_payment": "Siirry maksuun",
            "outstanding": "Maksamaton saldo",
            "paid": "Maksettu",
            "title": "Laskusi",
            "total": "Yhteensä"
          },
          "change_menu": "Vaihda valikkoa",
          "checkout": {
            "email": "Sähköposti",
            "ending_in": "Kortti päättyy %{last4}",
            "name": "Nimi",
            "or": "tai",
            "pay": "Maksa %{amount}",
            "previous_payment": "Aiemmin käytetyt kortit",
            "title": "Kassalle",
            "total": "Yhteensä",
            "use": "Maksa"
          },
          "choose_menu": "Valitse valikosta",
          "closed": "%{name} ei tällä hetkellä ota vastaan tilauksia.",
          "item": {
            "add_for": "Lisää kohteelle %{price}",
            "choose_size": "Valitse koko",
            "contains": "Sisältää:",
            "increase_quantity": "Lisää määrää",
            "reduce_quantity": "Vähennä määrää",
            "select_at_least": "Valitse vähintään %{min} vaihtoehtoa.",
            "select_one": "Valitse vaihtoehto."
          },
          "kcal_disclaimer": "Aikuiset tarvitsevat noin 2000 kilokaloria päivässä.",
          "no_menus": "Ravintolalla ei tällä hetkellä ole valikoimia saatavilla.",
          "payment_success": {
            "back_to_menu": "Takaisin valikkoon",
            "failed1": "Maksusi epäonnistui.",
            "failed2": "Yritä uudelleen tai ota yhteyttä ravintolan henkilökuntaan.",
            "message": "Kiitos maksustasi.",
            "message_takeaway": "Maksusi onnistui ja tilauksesi on lähetetty ravintolaan.",
            "refunded1": "Pahoittelut, emme voineet käsitellä tilaustasi.",
            "refunded2": "Maksusi on palautettu.",
            "title": "Maksu onnistui",
            "view_receipt": "Näytä kuitti",
            "waiting": "Odottaa maksuvahvistusta"
          },
          "toast": {
            "bad_items": "Joitakin tilauksesi kohteita ei ole enää saatavilla ja ne on poistettu",
            "location_closed": "Tämä sijainti ei tällä hetkellä ota vastaan tilauksia",
            "order_placed": "Tilauksesi on vastaanotettu"
          },
          "view_basket": "Näytä kori",
          "view_bill": "Näytä lasku"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "fil": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Kintsay",
        "cereals": "Mga siryal",
        "crustaceans": "Mga krustaseo",
        "dairy": "Pagawaan ng gatas",
        "eggs": "Mga itlog",
        "fish": "Isda",
        "lupin": "Lupin",
        "mustard": "Mustasa",
        "nuts": "Mga mani",
        "peanuts": "Mga mani",
        "sesame": "Linga",
        "shellfish": "Shellfish",
        "soy": "Toyo",
        "sulphur": "Asupre"
      },
      "common": {
        "actions": "Actions",
        "back": "Bumalik",
        "cancel": "Kanselahin",
        "confirm": "Kumpirmahin",
        "continue": "Magpatuloy",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Libre sa Gluten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Non-alcoholic",
        "vegan": "Begano",
        "vegetarian": "Vegetariano"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Magdagdag ng mga item mula sa menu para makapagsimula",
            "empty_title": "Ang iyong basket ay walang laman",
            "go_to_checkout": "Pumunta sa checkout",
            "place_order": "Maglagay ng order",
            "title": "Ang iyong basket",
            "total": "Kabuuan"
          },
          "bill": {
            "go_to_payment": "Pumunta sa bayad",
            "outstanding": "Nakabinbin na balanse",
            "paid": "Bayad na",
            "title": "Ang iyong bill",
            "total": "Kabuuan"
          },
          "change_menu": "Baguhin ang menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Kard na nagtatapos sa %{last4}",
            "name": "Pangalan",
            "or": "o",
            "pay": "Magbayad ng %{amount}",
            "previous_payment": "Mga naunang ginamit na kard",
            "title": "Checkout",
            "total": "Kabuuan",
            "use": "Bayad"
          },
          "choose_menu": "Pumili ng menu",
          "closed": "Hindi tumatanggap ng order si %{name} sa ngayon",
          "item": {
            "add_for": "Idagdag para kay %{price}",
            "choose_size": "Pumili ng sukat",
            "contains": "Naglalaman:",
            "increase_quantity": "Taasan ang dami",
            "reduce_quantity": "Bawasan ang dami",
            "select_at_least": "Mangyaring pumili ng hindi bababa sa %{min} na opsyon",
            "select_one": "Mangyaring pumili ng opsyon"
          },
          "kcal_disclaimer": "Ang mga matatanda ay kailangan ng mga 2000 kcal kada araw.",
          "no_menus": "Walang available na menu ang restawran sa ngayon.",
          "payment_success": {
            "back_to_menu": "Bumalik sa menu",
            "failed1": "Ang iyong bayad ay hindi successful.",
            "failed2": "Subukan muli o kontakin ang mga staff ng restawran.",
            "message": "Salamat sa iyong bayad.",
            "message_takeaway": "Matagumpay ang iyong bayad at naipadala na ang iyong order sa restawran.",
            "refunded1": "Paumanhin, hindi namin maiproseso ang iyong order.",
            "refunded2": "Narefund ang iyong bayad.",
            "title": "Matagumpay ang bayad",
            "view_receipt": "Tingnan ang resibo",
            "waiting": "Naghihintay ng kumpirmasyon ng bayad"
          },
          "toast": {
            "bad_items": "Ilang items sa iyong order ay hindi na available at naalis na",
            "location_closed": "Ang lokasyong ito ay hindi tumatanggap ng order ngayon",
            "order_placed": "Naipadala na ang iyong order"
          },
          "view_basket": "Tingnan ang basket",
          "view_bill": "Tingnan ang bill"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "fr": {
    "common": {
      "actions": "Actions",
      "active": "Actif",
      "add": "Ajouter",
      "and_x_more": "et %{count} de plus",
      "any": "Tout",
      "archive": "Archiver",
      "archived": "Archivé",
      "are_you_sure": "Êtes-vous sûr(e) ?",
      "back": "Retour",
      "cancel": "Annuler",
      "choose": "Choisir",
      "close": "Fermer",
      "closed": "Fermé",
      "confirm": "Confirmer",
      "confirm_delete": "Êtes-vous sûr(e) ?",
      "continue": "Continuer",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Supprimer",
      "details": "Détails",
      "do_not_refresh_page": "Ne pas rafraîchir ou fermer la page.",
      "edit": "Modifier",
      "form_error_html": "Certaines des données soumises sont invalides. Veuillez vérifier le formulaire pour les erreurs et réessayer. Si le problème persiste, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contactez le support client</a>.",
      "learn_more": "En savoir plus",
      "minutes": "Minutes",
      "never": "Jamais",
      "no": "Non",
      "none": "Aucun",
      "not_active": "Non actif",
      "not_provided": "Non fourni",
      "processing": "En cours de traitement",
      "remove": "Supprimer",
      "restore": "Restaurer",
      "save": "Enregistrer",
      "selected": "Sélectionné",
      "service_types": {
        "at_table": "À table",
        "delivery": "Livraison",
        "takeaway": "À emporter"
      },
      "settings_saved": "Les paramètres ont été enregistrés.",
      "show": "Afficher",
      "talk_to_an_expert": "Parler à un expert",
      "view": "Voir",
      "yes": "Oui"
    },
    "js": {
      "allergens": {
        "celery": "Céleri",
        "cereals": "Gluten",
        "crustaceans": "Crustacés",
        "dairy": "Produits laitiers",
        "eggs": "Œufs",
        "fish": "Poisson",
        "lupin": "Lupin",
        "mustard": "Moutarde",
        "nuts": "Fruits à coque",
        "peanuts": "Arachides",
        "sesame": "Sésame",
        "shellfish": "Fruits de mer",
        "soy": "Soja",
        "sulphur": "Sulfites"
      },
      "common": {
        "actions": "Actions",
        "back": "Retour",
        "cancel": "Annuler",
        "confirm": "Confirmer",
        "continue": "Continuer",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Sans gluten",
        "halal": "Halal",
        "kosher": "Casher",
        "teetotal": "Sans alcool",
        "vegan": "Végétalien",
        "vegetarian": "Végétarien"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Ajouter des éléments du menu pour commencer",
            "empty_title": "Votre panier est vide",
            "go_to_checkout": "Aller à la caisse",
            "place_order": "Passer commande",
            "title": "Votre panier",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Aller au paiement",
            "outstanding": "Solde restant",
            "paid": "Payé",
            "title": "Votre facture",
            "total": "Total"
          },
          "change_menu": "Changer de menu",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Carte se terminant par %{last4}",
            "name": "Nom",
            "or": "ou",
            "pay": "Payer %{amount}",
            "previous_payment": "Cartes utilisées précédemment",
            "title": "Caisse",
            "total": "Total",
            "use": "Payer"
          },
          "choose_menu": "Choisir un menu",
          "closed": "%{name} n'accepte actuellement pas de commandes.",
          "item": {
            "add_for": "Ajouter pour %{price}",
            "choose_size": "Choisir une taille",
            "contains": "Contient :",
            "increase_quantity": "Augmenter la quantité",
            "reduce_quantity": "Diminuer la quantité",
            "select_at_least": "Veuillez sélectionner au moins %{min} options.",
            "select_one": "Veuillez sélectionner une option."
          },
          "kcal_disclaimer": "Les adultes ont besoin d'environ 2000 kcal par jour.",
          "no_menus": "Le restaurant n'a pas de menus disponibles pour le moment.",
          "payment_success": {
            "back_to_menu": "Retour au menu",
            "failed1": "Votre paiement a échoué.",
            "failed2": "Veuillez réessayer ou contacter le personnel du restaurant.",
            "message": "Merci pour votre paiement.",
            "message_takeaway": "Votre paiement a réussi et votre commande a été envoyée au restaurant.",
            "refunded1": "Désolé, nous n'avons pas pu traiter votre commande.",
            "refunded2": "Votre paiement a été remboursé.",
            "title": "Paiement réussi",
            "view_receipt": "Voir le reçu",
            "waiting": "En attente de confirmation de paiement"
          },
          "toast": {
            "bad_items": "Certains articles de votre commande ne sont plus disponibles et ont été retirés",
            "location_closed": "Cet emplacement n'accepte pas actuellement les commandes",
            "order_placed": "Votre commande a été passée"
          },
          "view_basket": "Voir le panier",
          "view_bill": "Voir la facture"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Montant",
            "balance": "Solde",
            "card": "Carte",
            "failure": "Paiement échoué",
            "no_terminals": "Aucun lecteur de carte disponible",
            "pick_terminal": "Sélectionner un lecteur de carte",
            "split_item": "Choisir des articles à payer",
            "split_person": "Diviser équitablement",
            "success": "Paiement réussi",
            "terminal_offline": "Le lecteur de carte est hors ligne",
            "tip": "Pourboire",
            "try_again": "Réessayer",
            "waiting_for_card": "En attente de carte"
          },
          "split_person": {
            "out_of_post": "à la table",
            "out_of_pre": "hors de",
            "paying_for_post_one": "personne",
            "paying_for_post_other": "personnes",
            "paying_for_pre": "Paiement pour",
            "to_split": "Diviser"
          }
        },
        "code_assigner": {
          "error": "Une erreur s'est produite : %{error}",
          "invalid_qr_code": "Ce code QR n'est pas un code de table valide.",
          "success_end": "Bon travail ! Toutes les tables ont reçu un code QR.",
          "success_next": "Compris ! Passage à la table %{number}."
        },
        "common": {
          "are_you_sure": "Êtes-vous sûr ?",
          "back": "Retour",
          "cancel": "Annuler",
          "close": "Fermer",
          "confirm": "Confirmer",
          "edit": "Modifier",
          "processing": "Traitement",
          "save": "Enregistrer"
        },
        "kitchen": {
          "exit": "Sortir",
          "preparing": "Préparation",
          "ready": "Prêt",
          "todo": "À faire"
        },
        "opening_hours": {
          "add_hours": "Ajouter des heures",
          "close": "Fermer",
          "closed": "Fermé",
          "confirm": "Confirmer",
          "days": [
            "Dimanche",
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi"
          ],
          "error": "Les heures d'ouverture que vous avez saisies ne représentent pas une plage horaire valide. Veuillez vérifier les horaires et réessayer.",
          "open": "Ouvert",
          "remove": "Retirer"
        },
        "ordering": {
          "add": "Ajouter",
          "add_button": "Ajouter pour %{amount}",
          "add_more": "Ajouter plus",
          "balance": "Solde",
          "confirm": "Confirmer la commande",
          "confirm_takeout": {
            "confirm": "Confirmer",
            "email": "E-mail du client",
            "name": "Nom du client",
            "optional": "(facultatif)",
            "phone": "Téléphone du client",
            "title": "Confirmez votre commande"
          },
          "contains": "Contient :",
          "course": "Plat",
          "decrease": "Diminuer la quantité",
          "increase": "Augmenter la quantité",
          "menus": "Menus",
          "notes": "Remarques",
          "open_item_name": "Nom",
          "open_item_notes": "Remarques",
          "open_item_price": "Prix",
          "payments": "Paiements",
          "placed_at": "Passé à %{time}",
          "please_select_at_least": {
            "one": "Veuillez sélectionner au moins une option",
            "other": "Veuillez sélectionner au moins %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Veuillez ne sélectionner qu'une seule option au maximum",
            "other": "Veuillez ne sélectionner que jusqu'à %{count} options"
          },
          "remove": "Retirer",
          "seated_at": "Assis à %{time}",
          "size": "Taille",
          "tips": "Conseils",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Montant du paiement",
          "cancel": "Annuler le paiement",
          "collect": "Récupérer le paiement",
          "each": "chaque",
          "include_service": "Inclure service (%{amount})",
          "minimum": "Le montant minimum est de %{amount}",
          "paid": "payé",
          "title": "Paiement en cours"
        },
        "readers": {
          "cancel_pending_payment": "Annuler le paiement en attente",
          "could_not_collect": "Impossible de collecter le moyen de paiement. Veuillez vous reconnecter au terminal.",
          "minimum_payment_amount": "Le montant minimum de paiement est de 0,30 €.",
          "payment_failed": "Le paiement n'a pas été effectué avec succès. Veuillez réessayer.",
          "select_reader": "Sélectionner le terminal",
          "waiting_for_card": "En attente de la carte (cliquer pour annuler)"
        },
        "refunds": {
          "custom": {
            "description": "Combien souhaitez-vous rembourser?",
            "limit": "Max : %{amount}",
            "title": "Remboursement d'un montant personnalisé"
          },
          "summary": {
            "available": "En rupture de stock de %{amount}",
            "kind": {
              "card": "Carte",
              "cash": "Espèces",
              "complimentary": "Gratuit"
            },
            "not_enough_allocated": "Veuillez ajouter %{amount} aux allocations ci-dessus",
            "title": "Révision",
            "title_allocate": "Veuillez allouer %{amount} aux paiements suivants :",
            "title_full": "Les montants suivants seront entièrement remboursés :",
            "too_much_allocated": "Veuillez supprimer %{amount} des allocations ci-dessus",
            "too_much_single": "Vous ne pouvez pas rembourser plus que le paiement original"
          },
          "type": {
            "custom": "Un montant personnalisé",
            "custom_desc": "Si vous souhaitez rembourser un montant spécifique.",
            "entire_bill": "La facture entière",
            "entire_bill_desc": {
              "one": "%{amount} sera remboursé à un paiement.",
              "other": "%{amount} sera remboursé à %{count} paiements."
            },
            "title": "Que souhaitez-vous rembourser?"
          }
        },
        "requests": {
          "payment": "Veut payer",
          "waiter": "A demandé un serveur",
          "water": "Veut de l'eau du robinet"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centimètres",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grammes",
      "gal": "Gallon",
      "kg": "Kilogrammes",
      "l": "Litres",
      "lb": "Livres",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Onces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "ga": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Sallery",
        "cereals": "Gluten",
        "crustaceans": "Crustaceans",
        "dairy": "Líonta",
        "eggs": "Iomaí",
        "fish": "Iasc",
        "lupin": "Lupin",
        "mustard": "Mustard",
        "nuts": "Nuts",
        "peanuts": "Peanuts",
        "sesame": "Sesame",
        "shellfish": "Shellfish",
        "soy": "Soy",
        "sulphur": "Sulphites"
      },
      "common": {
        "actions": "Actions",
        "back": "Ar ais",
        "cancel": "Cealaigh",
        "confirm": "Deimhnigh",
        "continue": "Lean ar aghaidh",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Gluten saor",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Mí-ailceolach",
        "vegan": "Feoilséantach",
        "vegetarian": "Veigeatarach"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Cuir earraí ón mbeár chun tosú",
            "empty_title": "Tá do bhosca folctha",
            "go_to_checkout": "Téigh go dtí an tseiceáil amach",
            "place_order": "Cuir ordú isteach",
            "title": "Do bhosca",
            "total": "Iomlán"
          },
          "bill": {
            "go_to_payment": "Téigh go dtí an íocaíocht",
            "outstanding": "Sábháilteacht neamhbhíoch",
            "paid": "Íoctha",
            "title": "Do bhill",
            "total": "Iomlán"
          },
          "change_menu": "Athraigh an mhéarluach",
          "checkout": {
            "email": "Ríomhphost",
            "ending_in": "Cárta ag críochnú in %{last4}",
            "name": "Ainm",
            "or": "nó",
            "pay": "Íoc €%{amount}",
            "previous_payment": "Cártaí a úsáidtear roimhe seo",
            "title": "Seiceáil amach",
            "total": "Iomlán",
            "use": "Íoc"
          },
          "choose_menu": "Roghnaigh meáin",
          "closed": "Ní glacann %{name} orduithe faoi láthair.",
          "item": {
            "add_for": "Cuir le haghaidh %{price}",
            "choose_size": "Roghnaigh méid",
            "contains": "Áiríonn:",
            "increase_quantity": "Méadóidh méid",
            "reduce_quantity": "Laghdóidh méid",
            "select_at_least": "Roghnaigh ar a laghad %{min} rogha.",
            "select_one": "Roghnaigh rogha amháin."
          },
          "kcal_disclaimer": "Is gá do dhaoine fásta thart ar 2000 kcailín in aghaidh an lae.",
          "no_menus": "Níl aon mheáin ar fáil ón mbialann faoi láthair.",
          "payment_success": {
            "back_to_menu": "Ar ais chuig an meán",
            "failed1": "Níorbh éasca do íocaíocht.",
            "failed2": "Déan iarracht eile nó déan teagmháil le foireann an bhialainne.",
            "message": "Go raibh maith agat as do íocaíocht.",
            "message_takeaway": "Bhí rathúil do íocaíocht agus tá do ordú seolte chuig an mbialann.",
            "refunded1": "Tá brón orainn, níor tháinig do ordú chun cinn.",
            "refunded2": "Tá do íocaíocht ar ais.",
            "title": "Íocaíocht rathúil",
            "view_receipt": "Amharc ar an fáistin",
            "waiting": "Ag fanacht le deimhniú íocaíochta"
          },
          "toast": {
            "bad_items": "Tá roinnt míreanna i do ordú nach bhfuil ar fáil níos mó agus tá siad curtha as",
            "location_closed": "Ní glacann an suíomh seo orduithe faoi láthair",
            "order_placed": "Cuireadh do ordú isteach"
          },
          "view_basket": "Amharc ar an mbosca",
          "view_bill": "Amharc ar an mbill"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "he": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "סלרי",
        "cereals": "גלוטן",
        "crustaceans": "קרום ימי",
        "dairy": "מוצרי חלב",
        "eggs": "ביצים",
        "fish": "דגים",
        "lupin": "פולי לופין",
        "mustard": "חרדל",
        "nuts": "אגוזים",
        "peanuts": "בוטנים",
        "sesame": "שומשום",
        "shellfish": "פירות ים",
        "soy": "סויה",
        "sulphur": "גפרית"
      },
      "common": {
        "actions": "Actions",
        "back": "חזור",
        "cancel": "בטל",
        "confirm": "אישור",
        "continue": "המשך",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "ללא גלוטן",
        "halal": "הלאל",
        "kosher": "כשר",
        "teetotal": "לא אלכוהולי",
        "vegan": "טבעוני",
        "vegetarian": "צמחוני"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "הוסף פריטים מהתפריט כדי להתחיל",
            "empty_title": "הסל שלך ריק",
            "go_to_checkout": "עבור לתשלום",
            "place_order": "בצע הזמנה",
            "title": "הסל שלך",
            "total": "סה\"כ"
          },
          "bill": {
            "go_to_payment": "עבור לתשלום",
            "outstanding": "יתרת חוב",
            "paid": "שולם",
            "title": "החשבון שלך",
            "total": "סה\"כ"
          },
          "change_menu": "שנה תפריט",
          "checkout": {
            "email": "אימייל",
            "ending_in": "כרטיס סיום ב-%{last4}",
            "name": "שם",
            "or": "או",
            "pay": "שלם %{amount}",
            "previous_payment": "כרטיסים ששומשו בעבר",
            "title": "תשלום",
            "total": "סה\"כ",
            "use": "שלם"
          },
          "choose_menu": "בחר תפריט",
          "closed": "%{name} לא מקבל כרגע הזמנות",
          "item": {
            "add_for": "הוסף עבור %{price}",
            "choose_size": "בחר גודל",
            "contains": "מכיל:",
            "increase_quantity": "הגדל כמות",
            "reduce_quantity": "הפחת כמות",
            "select_at_least": "בחר לפחות %{min} אפשרויות.",
            "select_one": "בחר אפשרות."
          },
          "kcal_disclaimer": "מבוגרים זקוקים לכ-2000 קלוריות ביום.",
          "no_menus": "המסעדה אינה מציעה תפריטים זמינים כרגע.",
          "payment_success": {
            "back_to_menu": "חזור לתפריט",
            "failed1": "התשלום שלך לא הצליח.",
            "failed2": "אנא נסה שוב או צור קשר עם צוות המסעדה.",
            "message": "תודה על התשלום שלך.",
            "message_takeaway": "התשלום שלך בוצע בהצלחה וההזמנה שלך נשלחה למסעדה.",
            "refunded1": "מצטערים, לא הצלחנו לעבד את ההזמנה שלך.",
            "refunded2": "התשלום שלך הוחזר.",
            "title": "התשלום בוצע בהצלחה",
            "view_receipt": "הצג קבלה",
            "waiting": "ממתין לאישור תשלום"
          },
          "toast": {
            "bad_items": "כמה מהפריטים בהזמנתך אינם זמינים ונמחקו",
            "location_closed": "המיקום הזה אינו מקבל הזמנות כרגע",
            "order_placed": "ההזמנה שלך הושלמה"
          },
          "view_basket": "צפה בסל",
          "view_bill": "צפה בחשבון"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "hi": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "अजवाइन",
        "cereals": "ग्लूटेन",
        "crustaceans": "क्रस्टेशियन्स",
        "dairy": "डेयरी",
        "eggs": "अंडे",
        "fish": "मछली",
        "lupin": "एक प्रकार का अनाज",
        "mustard": "सरसों",
        "nuts": "बादाम",
        "peanuts": "मूंगफली",
        "sesame": "तिल",
        "shellfish": "शैलफिश",
        "soy": "सोया",
        "sulphur": "सल्फाइट्स"
      },
      "common": {
        "actions": "Actions",
        "back": "पीछे जाएं",
        "cancel": "रद्द करें",
        "confirm": "पुष्टि करें",
        "continue": "जारी रखें",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "ग्लूटेन फ्री",
        "halal": "हलाल",
        "kosher": "कोशर",
        "teetotal": "नॉन-अल्कोहोलिक",
        "vegan": "वीगन",
        "vegetarian": "शाकाहारी"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "मेनू से आइटम जोड़ें शुरू करने के लिए",
            "empty_title": "आपका झोला खाली है",
            "go_to_checkout": "चेकआउट पर जाएं",
            "place_order": "आदेश दें",
            "title": "आपका झोला",
            "total": "कुल"
          },
          "bill": {
            "go_to_payment": "भुगतान पर जाएं",
            "outstanding": "आधिक शेष राशि",
            "paid": "भुगतान किया गया",
            "title": "आपका बिल",
            "total": "कुल"
          },
          "change_menu": "मेनू बदलें",
          "checkout": {
            "email": "ईमेल",
            "ending_in": "%{last4} से समाप्त होने वाला कार्ड",
            "name": "नाम",
            "or": "या",
            "pay": "%{amount} का भुगतान करें",
            "previous_payment": "पिछले इस्तेमाल किए गए कार्ड",
            "title": "चेकआउट",
            "total": "कुल",
            "use": "भुगतान करें"
          },
          "choose_menu": "मेनू चुनें",
          "closed": "%{name} वर्तमान में आर्डर स्वीकार नहीं कर रहा है।",
          "item": {
            "add_for": "%{price} के लिए जोड़ें",
            "choose_size": "एक आकार चुनें",
            "contains": "शामिल है:",
            "increase_quantity": "मात्रा बढ़ाएं",
            "reduce_quantity": "मात्रा कम करें",
            "select_at_least": "कृपया कम से कम %{min} विकल्प चुनें।",
            "select_one": "कृपया एक विकल्प चुनें।"
          },
          "kcal_disclaimer": "वयस्क लोगों को लगभग 2000 कैलोरी प्रतिदिन की आवश्यकता होती है।",
          "no_menus": "रेस्टोरेंट वर्तमान में कोई उपलब्ध मेनू नहीं है।",
          "payment_success": {
            "back_to_menu": "मेनू पर वापस जाएं",
            "failed1": "आपका भुगतान सफल नहीं हुआ था।",
            "failed2": "कृपया पुनः प्रयास करें या रेस्तरां स्टाफ से संपर्क करें।",
            "message": "आपके भुगतान के लिए धन्यवाद।",
            "message_takeaway": "आपका भुगतान सफल रहा है और आपका आदेश रेस्तरां को भेज दिया गया है।",
            "refunded1": "क्षमा करें, हम आपके आदेश को प्रसंसाधित नहीं कर सके।",
            "refunded2": "आपका भुगतान वापस कर दिया गया है।",
            "title": "भुगतान सफल",
            "view_receipt": "रसीद देखें",
            "waiting": "भुगतान पुष्टि की प्रतीक्षा कर रहा है"
          },
          "toast": {
            "bad_items": "आपके आदेश में कुछ आइटम अब उपलब्ध नहीं हैं और हटा दिए गए हैं",
            "location_closed": "इस स्थान पर वर्तमान में आर्डर स्वीकार नहीं किया जा रहा है",
            "order_placed": "आपका आदेश प्लेस किया गया है"
          },
          "view_basket": "झोला देखें",
          "view_bill": "बिल देखें"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "hr": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Celer",
        "cereals": "Gluten",
        "crustaceans": "Rakovi",
        "dairy": "Mliječni proizvodi",
        "eggs": "Jaja",
        "fish": "Riba",
        "lupin": "Slanutak",
        "mustard": "Senf",
        "nuts": "Orašasti plodovi",
        "peanuts": "Kikiriki",
        "sesame": "Sezam",
        "shellfish": "Školjkaši",
        "soy": "Soja",
        "sulphur": "Sumpor-dioksid"
      },
      "common": {
        "actions": "Actions",
        "back": "Natrag",
        "cancel": "Odustani",
        "confirm": "Potvrdi",
        "continue": "Nastavi",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bez glutena",
        "halal": "Halal",
        "kosher": "Košer",
        "teetotal": "Bez alkohola",
        "vegan": "Vegansko",
        "vegetarian": "Vegetarijansko"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Dodajte stavke s izbornika da biste počeli",
            "empty_title": "Vaša košarica je prazna",
            "go_to_checkout": "Idi na naplatu",
            "place_order": "Naruči",
            "title": "Vaša košarica",
            "total": "Ukupno"
          },
          "bill": {
            "go_to_payment": "Idi na plaćanje",
            "outstanding": "Preostali iznos",
            "paid": "Plaćeno",
            "title": "Vaš račun",
            "total": "Ukupno"
          },
          "change_menu": "Promijeni izbornik",
          "checkout": {
            "email": "E-pošta",
            "ending_in": "Kartica završava s %{last4}",
            "name": "Ime",
            "or": "ili",
            "pay": "Plati %{amount}",
            "previous_payment": "Ranije korištene kartice",
            "title": "Naplati",
            "total": "Ukupno",
            "use": "Plati"
          },
          "choose_menu": "Odaberite izbornik",
          "closed": "%{name} trenutno ne prihvaća narudžbe",
          "item": {
            "add_for": "Dodaj za %{price}",
            "choose_size": "Odaberite veličinu",
            "contains": "Sadrži:",
            "increase_quantity": "Povećaj količinu",
            "reduce_quantity": "Smanji količinu",
            "select_at_least": "Odaberite barem %{min} opcija.",
            "select_one": "Odaberite opciju."
          },
          "kcal_disclaimer": "Odrasle osobe trebaju oko 2000 kcal dnevno.",
          "no_menus": "Restoran trenutno nema dostupne izbornike.",
          "payment_success": {
            "back_to_menu": "Natrag na izbornik",
            "failed1": "Vaše plaćanje nije uspjelo.",
            "failed2": "Molimo pokušajte ponovno ili kontaktirajte osoblje restorana.",
            "message": "Hvala vam na plaćanju.",
            "message_takeaway": "Vaše plaćanje je uspješno i vaša narudžba je poslana restoranu.",
            "refunded1": "Nažalost, nismo mogli obraditi vašu narudžbu.",
            "refunded2": "Vaše plaćanje je vraćeno.",
            "title": "Plaćanje uspješno",
            "view_receipt": "Prikaži račun",
            "waiting": "Čekanje potvrde plaćanja"
          },
          "toast": {
            "bad_items": "Neki proizvodi u vašoj narudžbi više nisu dostupni i uklonjeni su",
            "location_closed": "Ova lokacija trenutno ne prihvaća narudžbe",
            "order_placed": "Vaša narudžba je zaprimljena"
          },
          "view_basket": "Pogledaj košaricu",
          "view_bill": "Pogledaj račun"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "hu": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Zeller",
        "cereals": "Glutén",
        "crustaceans": "Rákok",
        "dairy": "Tejtermékek",
        "eggs": "Tojás",
        "fish": "Hal",
        "lupin": "Lupin",
        "mustard": "Mustár",
        "nuts": "Diófélék",
        "peanuts": "Földimogyoró",
        "sesame": "Szezámmag",
        "shellfish": "Kagylófélék",
        "soy": "Szója",
        "sulphur": "Szulfitok"
      },
      "common": {
        "actions": "Actions",
        "back": "Vissza",
        "cancel": "Mégse",
        "confirm": "Megerősít",
        "continue": "Folytat",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Gluténmentes",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alkoholmentes",
        "vegan": "Vegán",
        "vegetarian": "Vegetáriánus"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Adj hozzá tételeket az étlapról a kezdéshez",
            "empty_title": "Az Ön kosara üres",
            "go_to_checkout": "Tovább az fizetéshez",
            "place_order": "Rendelés leadása",
            "title": "Az Ön kosara",
            "total": "Összesen"
          },
          "bill": {
            "go_to_payment": "Tovább a fizetéshez",
            "outstanding": "Fennmaradó egyenleg",
            "paid": "Kifizetve",
            "title": "Az Ön számlája",
            "total": "Összesen"
          },
          "change_menu": "Étlap megváltoztatása",
          "checkout": {
            "email": "Email",
            "ending_in": "Kártya végződése: %{last4}",
            "name": "Név",
            "or": "vagy",
            "pay": "Fizetés %{amount}",
            "previous_payment": "Korábban használt kártyák",
            "title": "Pénztár",
            "total": "Összesen",
            "use": "Fizet"
          },
          "choose_menu": "Válasszon egy étlapról",
          "closed": "%{name} jelenleg nem fogad rendeléseket",
          "item": {
            "add_for": "Hozzáadás %{price}-hoz",
            "choose_size": "Válasszon méretet",
            "contains": "Tartalmazza:",
            "increase_quantity": "Mennyiség növelése",
            "reduce_quantity": "Mennyiség csökkentése",
            "select_at_least": "Kérjük, válasszon legalább %{min} lehetőséget.",
            "select_one": "Kérjük, válasszon egy lehetőséget."
          },
          "kcal_disclaimer": "A felnőtteknek naponta körülbelül 2000 kcal szükséges.",
          "no_menus": "Az étterem jelenleg nem rendelkezik étlappal.",
          "payment_success": {
            "back_to_menu": "Vissza az étlaphoz",
            "failed1": "Az Ön fizetése sikertelen volt.",
            "failed2": "Kérjük, próbálja újra, vagy lépjen kapcsolatba az étterem személyzetével.",
            "message": "Köszönjük a fizetését.",
            "message_takeaway": "Az Ön fizetése sikeres volt, és a rendelése továbbításra került az étterem részére.",
            "refunded1": "Sajnáljuk, nem tudtuk feldolgozni a rendelését.",
            "refunded2": "Az Ön fizetése visszautalásra került.",
            "title": "Fizetés sikeres",
            "view_receipt": "Számla megtekintése",
            "waiting": "Fizetési megerősítésre vár"
          },
          "toast": {
            "bad_items": "Néhány tétel a rendeléséből már nem elérhető, és eltávolításra került",
            "location_closed": "Ez a hely jelenleg nem fogad rendeléseket",
            "order_placed": "Az Ön rendelése sikeresen rögzítésre került"
          },
          "view_basket": "Kosár megtekintése",
          "view_bill": "Számla megtekintése"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "id": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Seledri",
        "cereals": "Gluten",
        "crustaceans": "Krustasea",
        "dairy": "Laktosa",
        "eggs": "Telur",
        "fish": "Ikan",
        "lupin": "Lupin",
        "mustard": "Mustard",
        "nuts": "Kacang",
        "peanuts": "Kacang Tanah",
        "sesame": "Wijen",
        "shellfish": "Udang",
        "soy": "Kedelai",
        "sulphur": "Sulfida"
      },
      "common": {
        "actions": "Actions",
        "back": "Kembali",
        "cancel": "Batal",
        "confirm": "Konfirmasi",
        "continue": "Lanjutkan",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bebas Gluten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Non-alkohol",
        "vegan": "Vegan",
        "vegetarian": "Vegetarian"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Tambahkan item dari menu untuk memulai",
            "empty_title": "Keranjang Anda kosong",
            "go_to_checkout": "Pergi ke kasir",
            "place_order": "Tempatkan pesanan",
            "title": "Keranjang Anda",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Pergi ke pembayaran",
            "outstanding": "Saldo yang belum lunas",
            "paid": "Dibayar",
            "title": "Tagihan Anda",
            "total": "Total"
          },
          "change_menu": "Ubah menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Kartu berakhiran %{last4}",
            "name": "Nama",
            "or": "atau",
            "pay": "Bayar %{amount}",
            "previous_payment": "Kartu yang pernah digunakan",
            "title": "Kasir",
            "total": "Total",
            "use": "Bayar"
          },
          "choose_menu": "Pilih menu",
          "closed": "%{name} saat ini tidak menerima pesanan",
          "item": {
            "add_for": "Tambahkan untuk %{price}",
            "choose_size": "Pilih ukuran",
            "contains": "Berisi:",
            "increase_quantity": "Tambah jumlah",
            "reduce_quantity": "Kurangi jumlah",
            "select_at_least": "Harap pilih setidaknya %{min} opsi",
            "select_one": "Harap pilih satu opsi"
          },
          "kcal_disclaimer": "Dewasa membutuhkan sekitar 2000 kkal sehari.",
          "no_menus": "Restoran tidak memiliki menu yang tersedia saat ini",
          "payment_success": {
            "back_to_menu": "Kembali ke menu",
            "failed1": "Pembayaran Anda tidak berhasil",
            "failed2": "Silakan coba lagi atau hubungi staf restoran",
            "message": "Terima kasih atas pembayaran Anda",
            "message_takeaway": "Pembayaran Anda berhasil dan pesanan Anda telah dikirim ke restoran",
            "refunded1": "Maaf, kami tidak dapat memproses pesanan Anda",
            "refunded2": "Pembayaran Anda telah dikembalikan",
            "title": "Pembayaran berhasil",
            "view_receipt": "Lihat kwitansi",
            "waiting": "Menunggu konfirmasi pembayaran"
          },
          "toast": {
            "bad_items": "Beberapa item dalam pesanan Anda tidak lagi tersedia dan telah dihapus",
            "location_closed": "Lokasi ini saat ini tidak menerima pesanan",
            "order_placed": "Pesanan Anda telah ditempatkan"
          },
          "view_basket": "Lihat keranjang",
          "view_bill": "Lihat tagihan"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "is": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Seldari",
        "cereals": "Glúten",
        "crustaceans": "Krustasius",
        "dairy": "Susu",
        "eggs": "Telur",
        "fish": "Ikan",
        "lupin": "Lupin",
        "mustard": "Mustar",
        "nuts": "Kacang",
        "peanuts": "Kacang Tanah",
        "sesame": "Wijen",
        "shellfish": "Kerang",
        "soy": "Kedelai",
        "sulphur": "Suflit"
      },
      "common": {
        "actions": "Actions",
        "back": "Aftur",
        "cancel": "Hætta við",
        "confirm": "Staðfesta",
        "continue": "Halda áfram",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glútenlaust",
        "halal": "Halal",
        "kosher": "Kóser",
        "teetotal": "Áfengisfritt",
        "vegan": "Vegan",
        "vegetarian": "Grænmetisæta"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Bættu við hlutum úr matseðlinum til að byrja",
            "empty_title": "Karfan þín er tóm",
            "go_to_checkout": "Fara í borgun",
            "place_order": "Senda pöntun",
            "title": "Karfan þín",
            "total": "Samtals"
          },
          "bill": {
            "go_to_payment": "Fara í greiðslu",
            "outstanding": "Ógreitt upphæð",
            "paid": "Greitt",
            "title": "Reikningurinn þinn",
            "total": "Samtals"
          },
          "change_menu": "Skipta um matseðil",
          "checkout": {
            "email": "Netfang",
            "ending_in": "Kort sem endar á %{last4}",
            "name": "Nafn",
            "or": "eða",
            "pay": "Borga %{amount}",
            "previous_payment": "Notuð kort",
            "title": "Borgun",
            "total": "Samtals",
            "use": "Greiða"
          },
          "choose_menu": "Veldu matseðil",
          "closed": "%{name} samþykkir ekki pöntunum í augnablikinu.",
          "item": {
            "add_for": "Bæta við fyrir %{price}",
            "choose_size": "Veldu stærð",
            "contains": "Inniheldur:",
            "increase_quantity": "Auka magn",
            "reduce_quantity": "Minnka magn",
            "select_at_least": "Vinsamlegast veldu að minnsta kosti %{min} valkosti.",
            "select_one": "Vinsamlegast veldu valmöguleika."
          },
          "kcal_disclaimer": "Fullorðnir þurfa um 2000 kcal á dag.",
          "no_menus": "Veitingastaðurinn hefur enga tiltæka matseðla í augnablikinu.",
          "payment_success": {
            "back_to_menu": "Aftur á matseðilinn",
            "failed1": "Greiðslan þín mistókst.",
            "failed2": "Vinsamlegast reyndu aftur eða hafðu samband við starfsfólk veitingastaðarins.",
            "message": "Þakka þér fyrir greiðsluna.",
            "message_takeaway": "Greiðslan þín tókst og pöntunin þín hefur verið send til veitingastaðarins.",
            "refunded1": "Afsökun, við gátum ekki unnist með pöntunina þína.",
            "refunded2": "Greiðslan þín hefur verið endurgreidd.",
            "title": "Greiðsla tókst",
            "view_receipt": "Skoða kvittun",
            "waiting": "Bíð eftir staðfestingu á greiðslu"
          },
          "toast": {
            "bad_items": "Sumir hlutir í pöntuninni þinni eru ekki lengur tiltækir og hafa verið fjarlægðir",
            "location_closed": "Þessi staðsetning samþykkir ekki pöntunum í augnablikinu",
            "order_placed": "Pöntunin þín hefur verið sett"
          },
          "view_basket": "Skoða körfuna",
          "view_bill": "Skoða reikninginn"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "it": {
    "common": {
      "actions": "Azioni",
      "active": "Attivo",
      "add": "Aggiungi",
      "and_x_more": "e altri %{count}",
      "any": "Qualunque",
      "archive": "Archivia",
      "archived": "Archiviato",
      "are_you_sure": "Sei sicuro?",
      "back": "Indietro",
      "cancel": "Annulla",
      "choose": "Scegli",
      "close": "Chiudi",
      "closed": "Chiuso",
      "confirm": "Conferma",
      "confirm_delete": "Sei sicuro?",
      "continue": "Continua",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Elimina",
      "details": "Dettagli",
      "do_not_refresh_page": "Non aggiornare o chiudere la pagina.",
      "edit": "Modifica",
      "form_error_html": "Sembra che alcuni dati non siano validi. Per favore, controlla gli errori nel form e riprova. Se l'errore persiste, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contatta l'assistenza</a>.",
      "learn_more": "Per saperne di più",
      "minutes": "Minuti",
      "never": "Mai",
      "no": "No",
      "none": "Nessuno/a",
      "not_active": "Non attivo",
      "not_provided": "Assente",
      "processing": "Elaborazione",
      "remove": "Rimuovi",
      "restore": "Ripristina",
      "save": "Salva",
      "selected": "Selezionato",
      "service_types": {
        "at_table": "Al tavolo",
        "delivery": "Consegna",
        "takeaway": "Asporto"
      },
      "settings_saved": "Le impostazioni sono state salvate.",
      "show": "Mostra",
      "talk_to_an_expert": "Parla con un esperto",
      "view": "Mostra",
      "yes": "Sì"
    },
    "js": {
      "allergens": {
        "celery": "Sedano",
        "cereals": "Glutine",
        "crustaceans": "Crostacei",
        "dairy": "Latticini",
        "eggs": "Uova",
        "fish": "Pesce",
        "lupin": "Lupini",
        "mustard": "Mostarda",
        "nuts": "Noccioline",
        "peanuts": "Arachidi",
        "sesame": "Sesamo",
        "shellfish": "Molluschi",
        "soy": "Soia",
        "sulphur": "Sulfiti"
      },
      "common": {
        "actions": "Azioni",
        "back": "Indietro",
        "cancel": "Annulla",
        "confirm": "Conferma",
        "continue": "Continua",
        "delete": "Elimina",
        "save": "Salva"
      },
      "dietary_preferences": {
        "gluten_free": "Senza glutine",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Non alcolico",
        "vegan": "Vegano",
        "vegetarian": "Vegetariano"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Aggiungi prodotti dal menu per iniziare",
            "empty_title": "Il tuo carrello è vuoto",
            "go_to_checkout": "Vai al pagamento",
            "place_order": "Ordina",
            "title": "Il tuo carrello",
            "total": "Totale"
          },
          "bill": {
            "go_to_payment": "Vai al pagamento",
            "outstanding": "Saldo",
            "paid": "Pagato",
            "title": "Il conto",
            "total": "Totale"
          },
          "change_menu": "Cambia menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Carta che finisce con %{last4}",
            "name": "Nome",
            "or": "oppure",
            "pay": "Paga %{amount}",
            "previous_payment": "Carte usate in precedenza",
            "title": "Checkout",
            "total": "Totale",
            "use": "Paga"
          },
          "choose_menu": "Scegli un menu",
          "closed": "%{name} non accetta ordini al momento.",
          "item": {
            "add_for": "Aggiungi per %{price}",
            "choose_size": "Scegli una dimensione",
            "contains": "Contiene:",
            "increase_quantity": "Aumenta quantità",
            "reduce_quantity": "Riduci quantità",
            "select_at_least": "Per favore, seleziona almeno %{min} opzioni.",
            "select_one": "Per favore, seleziona un'opzione."
          },
          "kcal_disclaimer": "Gli adulti hanno bisogno di circa 2000 kcal al giorno.",
          "no_menus": "Il ristorante non ha menu attivi al momento.",
          "payment_success": {
            "back_to_menu": "Torna al menu",
            "failed1": "Il tuo pagamento non è andato a buon fine.",
            "failed2": "Per favore, riprova o contatta lo staff del ristorante.",
            "message": "Ti ringraziamo per aver effettuato il pagamento.",
            "message_takeaway": "Il tuo pagamento è stato effettuato con successo ed il tuo ordine è stato inviato.",
            "refunded1": "Ci dispiace, non siamo riusciti a processare il tuo ordine.",
            "refunded2": "Il tuo pagamento è stato rimborsato.",
            "title": "Pagamento effettuato",
            "view_receipt": "Vai alla ricevuta",
            "waiting": "In attesa di conferma del pagamento"
          },
          "toast": {
            "bad_items": "Alcuni prodotti nel tuo ordine non sono più disponibili e sono stati rimossi",
            "location_closed": "Il locale non accetta ordini al momento",
            "order_placed": "Il tuo ordine è stato effettuato"
          },
          "view_basket": "Vai al carrello",
          "view_bill": "Mostra il conto"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Aggiungi tutti i prodotti in una categoria",
                  "category_label": "Seleziona una categoria",
                  "item_label": "Seleziona un prodotto",
                  "single_item": "Aggiungi un solo prodotto",
                  "title": "Aggiungi prodotti"
                },
                "add_items": "Aggiungi prodotti",
                "fieldsets": {
                  "basic": {
                    "title": "Informazioni di base"
                  },
                  "items": {
                    "description": "Inserisci qui i prodotti che vanno contati in questa zona. Le modifiche sono salvate automaticamente.",
                    "title": "Prodotti"
                  }
                },
                "hints": {
                  "name": "Il nome della zona di stoccaggio"
                },
                "labels": {
                  "name": "Nome"
                },
                "no_items": "Non hai ancora aggiunto prodotti a questa zona."
              },
              "table": {
                "name": "Nome",
                "stock_increment": "Unità di inventario"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Ammontare",
            "balance": "Saldo",
            "card": "Carta",
            "failure": "Pagamento fallito",
            "no_terminals": "Nessun lettore di carte disponibile",
            "pick_terminal": "Seleziona un lettore di carte",
            "split_item": "Scegli cosa pagare",
            "split_person": "Dividi in parti uguali",
            "success": "Pagamento riuscito",
            "terminal_offline": "Il lettore di carte non è online",
            "tip": "Mancia",
            "try_again": "Riprova",
            "waiting_for_card": "In attesa di carta"
          },
          "split_person": {
            "out_of_post": "al tavolo",
            "out_of_pre": "di",
            "paying_for_post_one": "persona",
            "paying_for_post_other": "persone",
            "paying_for_pre": "Pagamento per",
            "to_split": "Da dividere"
          }
        },
        "code_assigner": {
          "error": "Il QR code è stato rifiutato: %{error}.",
          "invalid_qr_code": "Il QR code non è un codice tavolo valido.",
          "success_end": "Ottimo lavoro! Tutti i tavoli hanno ricevuto un codice QR.",
          "success_next": "Preso! Passiamo al tavolo %{number}."
        },
        "common": {
          "are_you_sure": "Sei sicuro?",
          "back": "Indietro",
          "cancel": "Annulla",
          "close": "Chiudi",
          "confirm": "Conferma",
          "edit": "Modifica",
          "processing": "Elaborazione",
          "save": "Salva"
        },
        "kitchen": {
          "exit": "Esci",
          "preparing": "Preparazione",
          "ready": "Pronto",
          "todo": "Da fare"
        },
        "opening_hours": {
          "add_hours": "Aggiungi orari",
          "close": "Chiudi",
          "closed": "Chiuso",
          "confirm": "Conferma",
          "days": [
            "Domenica",
            "Lunedì",
            "Martedì",
            "Mercoledì",
            "Giovedì",
            "Venerdì",
            "Sabato"
          ],
          "error": "Gli orari di apertura inseriti non rappresentano un intervallo di tempo possibile. Controlla gli orari e riprova.",
          "open": "Apri",
          "remove": "Rimuovi"
        },
        "ordering": {
          "add": "Aggiungi",
          "add_button": "Aggiungi per %{amount}",
          "add_more": "Aggiungi",
          "balance": "Saldo",
          "confirm": "Conferma ordine",
          "confirm_takeout": {
            "confirm": "Conferma",
            "email": "Email cliente",
            "name": "Nome cliente",
            "optional": "(opzionale)",
            "phone": "Telefono cliente",
            "title": "Conferma ordine"
          },
          "contains": "Contiene: ",
          "course": "Portata",
          "decrease": "Riduci quantità",
          "increase": "Incrementa quantità",
          "menus": "Menu",
          "notes": "Note",
          "open_item_name": "Nome",
          "open_item_notes": "Note",
          "open_item_price": "Prezzo",
          "payments": "Pagamenti",
          "placed_at": "Piazzato alle %{time}",
          "please_select_at_least": {
            "one": "Per favore, seleziona almeno una opzione",
            "other": "Per favore, seleziona almeno %{count} opzioni"
          },
          "please_select_no_more_than": {
            "one": "Per favore, seleziona non più di una opzione",
            "other": "Per favore, seleziona non più di %{count} opzioni"
          },
          "remove": "Rimuovi",
          "seated_at": "Seduti alle %{time}",
          "size": "Dimensione",
          "tips": "Mancia",
          "total": "Totale"
        },
        "payment_splitter": {
          "amount": "Importo del pagamento",
          "cancel": "Annulla pagamento",
          "collect": "Riscuoti pagamento",
          "each": "ciascuno",
          "include_service": "Includi il servizio (%{amount})",
          "minimum": "L'importo minimo è %{amount}",
          "paid": "pagato",
          "title": "Cosa vuoi pagare?"
        },
        "readers": {
          "cancel_pending_payment": "Annulla pagamento in sospeso",
          "could_not_collect": "Impossibile riscuotere il metodo di pagamento. Riconnetti al terminale.",
          "minimum_payment_amount": "L'importo minimo di pagamento è di €0,50.",
          "payment_failed": "Il pagamento non è stato effettuato con successo. Riprova.",
          "select_reader": "Seleziona terminale",
          "waiting_for_card": "In attesa della carta (clicca per annullare)"
        },
        "refunds": {
          "custom": {
            "description": "Quanto vuoi rimborsare?",
            "limit": "Massimo: %{amount}",
            "title": "Rimborsa un importo specifico"
          },
          "summary": {
            "available": "Su %{amount} totali",
            "kind": {
              "card": "Carta",
              "cash": "Contanti",
              "complimentary": "Omaggio"
            },
            "not_enough_allocated": "Per favore, aggiungi %{amount} alla ripartizione",
            "title": "Riepilogo",
            "title_allocate": "Per favore, distribuisci %{amount} ai seguenti pagamenti:",
            "title_full": "I seguenti importi saranno rimborsati:",
            "too_much_allocated": "Per favore, rimuovi %{amount} dalla ripartizione",
            "too_much_single": "Non puoi rimborsare più dell'importo originale"
          },
          "type": {
            "custom": "Un importo specifico",
            "custom_desc": "Per rimborsare un ammontare esatto.",
            "entire_bill": "L'intero conto",
            "entire_bill_desc": {
              "one": "Rimborseremo %{amount} a un pagamento.",
              "other": "Rimborseremo %{amount} a %{count} pagamenti."
            },
            "title": "Cosa vuoi rimborsare?"
          }
        },
        "requests": {
          "payment": "Vuole pagare",
          "waiter": "Ha chiesto un cameriere",
          "water": "Vuole acqua del rubinetto"
        },
        "stock_take": {
          "products": {
            "count_by": "Conta per",
            "last_purchase": "Ultimo acquisto",
            "name": "Nome",
            "stock": "Quantità",
            "total_value": "Valore totale",
            "unit_value": "Valore"
          },
          "submit": "Conferma e chiudi",
          "total_value": "Valore totale"
        }
      }
    },
    "units": {
      "cup": "Tazza",
      "each": "Unità",
      "fl_oz": "Oncia fluida",
      "g": "Grammi",
      "gal": "Gallone",
      "kg": "Kilogrammi",
      "l": "Litri",
      "lb": "Libbre",
      "mg": "Milligrammi",
      "ml": "Millilitri",
      "oz": "Once",
      "pint": "Pinta",
      "quart": "Quarto",
      "tbsp": "Cucchiaio",
      "tsp": "Cucchiaino",
      "us_cup": "Tazza (US)",
      "us_fl_oz": "Oncia fluida (US)",
      "us_gal": "Gallone (US)",
      "us_pint": "Pinta (US)",
      "us_quart": "Quarto (US)",
      "us_tbsp": "Cucchiaio (US)",
      "us_tsp": "Cucchiaino (US)"
    }
  },
  "ja": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "セロリ",
        "cereals": "グルテン",
        "crustaceans": "甲殻類",
        "dairy": "乳製品",
        "eggs": "卵",
        "fish": "魚",
        "lupin": "ルピナス",
        "mustard": "マスタード",
        "nuts": "ナッツ",
        "peanuts": "ピーナッツ",
        "sesame": "ごま",
        "shellfish": "甲殻類",
        "soy": "大豆",
        "sulphur": "亜硫酸塩"
      },
      "common": {
        "actions": "Actions",
        "back": "戻る",
        "cancel": "キャンセル",
        "confirm": "確認",
        "continue": "続行",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "グルテンフリー",
        "halal": "ハラール",
        "kosher": "コーシャ",
        "teetotal": "ノンアルコール",
        "vegan": "ヴィーガン",
        "vegetarian": "ベジタリアン"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "メニューからアイテムを追加して開始してください",
            "empty_title": "お客様のかごは空です",
            "go_to_checkout": "チェックアウトに進む",
            "place_order": "注文する",
            "title": "お客様のかご",
            "total": "合計"
          },
          "bill": {
            "go_to_payment": "支払いに進む",
            "outstanding": "未払い残高",
            "paid": "支払済み",
            "title": "お会計",
            "total": "合計"
          },
          "change_menu": "メニューを変更",
          "checkout": {
            "email": "メール",
            "ending_in": "%{last4}で終わるカード",
            "name": "名前",
            "or": "または",
            "pay": "%{amount}を支払う",
            "previous_payment": "以前に使用したカード",
            "title": "チェックアウト",
            "total": "合計",
            "use": "支払う"
          },
          "choose_menu": "メニューを選択",
          "closed": "%{name} は現在注文を受け付けていません",
          "item": {
            "add_for": "%{price} に追加",
            "choose_size": "サイズを選択",
            "contains": "含まれるもの：",
            "increase_quantity": "数量を増やす",
            "reduce_quantity": "数量を減らす",
            "select_at_least": "少なくとも %{min} のオプションを選択してください",
            "select_one": "オプションを選択してください"
          },
          "kcal_disclaimer": "大人は1日約2000kcal必要です。",
          "no_menus": "現在、レストランは利用可能なメニューがありません",
          "payment_success": {
            "back_to_menu": "メニューに戻る",
            "failed1": "お支払いが失敗しました",
            "failed2": "もう一度お試しください。またはレストランスタッフにお問い合わせください",
            "message": "お支払いいただきありがとうございます",
            "message_takeaway": "お支払いが完了し、ご注文はレストランに送信されました",
            "refunded1": "申し訳ありませんが、ご注文を処理できませんでした",
            "refunded2": "お支払いが返金されました",
            "title": "支払いが成功しました",
            "view_receipt": "レシートを表示",
            "waiting": "支払い確認を待っています"
          },
          "toast": {
            "bad_items": "ご注文の一部のアイテムは利用できなくなっており、削除されました",
            "location_closed": "現在、この場所は注文を受け付けていません",
            "order_placed": "お客様のご注文は完了しました"
          },
          "view_basket": "かごを見る",
          "view_bill": "請求書を見る"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "ko": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "셀러리",
        "cereals": "글루텐",
        "crustaceans": "갑각류",
        "dairy": "유제품",
        "eggs": "계란",
        "fish": "생선",
        "lupin": "루핀",
        "mustard": "겨자",
        "nuts": "견과류",
        "peanuts": "땅콩",
        "sesame": "참깨",
        "shellfish": "갑각류",
        "soy": "대두",
        "sulphur": "황황제제"
      },
      "common": {
        "actions": "Actions",
        "back": "뒤로 가기",
        "cancel": "취소",
        "confirm": "확인",
        "continue": "계속하기",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "글루텐 프리",
        "halal": "할랄",
        "kosher": "코셔",
        "teetotal": "논알콜",
        "vegan": "비건",
        "vegetarian": "채식주의자"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "시작하려면 메뉴에서 항목을 추가하세요",
            "empty_title": "장바구니가 비어 있습니다",
            "go_to_checkout": "결제하기",
            "place_order": "주문하기",
            "title": "장바구니",
            "total": "합계"
          },
          "bill": {
            "go_to_payment": "결제로 이동",
            "outstanding": "미지불 잔액",
            "paid": "지불 완료",
            "title": "고객 청구서",
            "total": "합계"
          },
          "change_menu": "메뉴 변경",
          "checkout": {
            "email": "이메일",
            "ending_in": "X로 끝나는 카드",
            "name": "이름",
            "or": "또는",
            "pay": "%{amount} 결제",
            "previous_payment": "이전에 사용한 카드",
            "title": "체크아웃",
            "total": "총액",
            "use": "지불"
          },
          "choose_menu": "메뉴 선택",
          "closed": "%{name}은(는) 현재 주문을 받지 않습니다.",
          "item": {
            "add_for": "%{price} 추가",
            "choose_size": "크기 선택",
            "contains": "포함 항목:",
            "increase_quantity": "수량 증가",
            "reduce_quantity": "수량 감소",
            "select_at_least": "적어도 %{min}개의 옵션을 선택하세요.",
            "select_one": "옵션을 선택하세요."
          },
          "kcal_disclaimer": "성인은 하루에 약 2000 칼로리가 필요합니다.",
          "no_menus": "해당 식당은 현재 메뉴를 제공하지 않습니다.",
          "payment_success": {
            "back_to_menu": "메뉴로 돌아가기",
            "failed1": "결제가 실패했습니다.",
            "failed2": "다시 시도하거나 식당 스태프에 문의하세요.",
            "message": "결제해 주셔서 감사합니다.",
            "message_takeaway": "결제가 완료되었으며 주문이 식당에 전송되었습니다.",
            "refunded1": "주문을 처리할 수 없습니다.",
            "refunded2": "결제가 환불되었습니다.",
            "title": "결제가 완료되었습니다",
            "view_receipt": "영수증 보기",
            "waiting": "결제 확인 대기 중"
          },
          "toast": {
            "bad_items": "주문 중 제품 중 일부는 더 이상 제공되지 않아 삭제되었습니다",
            "location_closed": "이 위치는 현재 주문을 받지 않습니다.",
            "order_placed": "주문이 완료되었습니다"
          },
          "view_basket": "장바구니 보기",
          "view_bill": "청구서 보기"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "lb": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selleräi",
        "cereals": "Gluten",
        "crustaceans": "Krëbseder",
        "dairy": "Mëllechprodukter",
        "eggs": "Eeër",
        "fish": "Fësch",
        "lupin": "Lupin",
        "mustard": "Müscht",
        "nuts": "Nësser",
        "peanuts": "Erdnësser",
        "sesame": "Sesam",
        "shellfish": "Schelldéier",
        "soy": "Soja",
        "sulphur": "Schwefel"
      },
      "common": {
        "actions": "Actions",
        "back": "Zréck",
        "cancel": "Ofbriechen",
        "confirm": "Bestätegen",
        "continue": "Weidermachen",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenfräi",
        "halal": "Halal",
        "kosher": "Koscher",
        "teetotal": "Non-alkoholesch",
        "vegan": "Vegan",
        "vegetarian": "Vegetaresch"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Füügt Artikelen vum Menü derbäi fir unzefänken",
            "empty_title": "Är Korf ass eidel",
            "go_to_checkout": "Gitt zum Checkout",
            "place_order": "Bestellt",
            "title": "Äre Korf",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Gitt zum Bezuelen",
            "outstanding": "Ausstehend Betrag",
            "paid": "Bezuelt",
            "title": "Är Rechnung",
            "total": "Total"
          },
          "change_menu": "Menü änneren",
          "checkout": {
            "email": "E-Mail",
            "ending_in": "Kaart mat den Zifferen %{last4}",
            "name": "Numm",
            "or": "oder",
            "pay": "Bezuelen %{amount}",
            "previous_payment": "Fréier benotz Kaarten",
            "title": "Checkout",
            "total": "Total",
            "use": "Bezuelen"
          },
          "choose_menu": "Wiel e Menü aus",
          "closed": "%{name} akzeptéiert aktuell keng Orders.",
          "item": {
            "add_for": "Dobäi leien fir %{price}",
            "choose_size": "Gréisst erausfannen",
            "contains": "Beleet:",
            "increase_quantity": "Quantitéit eropsetzen",
            "reduce_quantity": "Quantitéit erofsetzen",
            "select_at_least": "Bitt wählt mindestens %{min} Optiounen aus.",
            "select_one": "Bitt wählt eng Optioun aus."
          },
          "kcal_disclaimer": "Erwuessener brauchen ronn 2000 kcal pro Dag.",
          "no_menus": "Den Restaurant huet aktuell keng Menü'en disponibel.",
          "payment_success": {
            "back_to_menu": "Zréck zum Menü",
            "failed1": "Är Bezuelung war net erfollegräich.",
            "failed2": "Probeiert et nach eng Kéier oder kontaktéiert den Restaurantpersonal.",
            "message": "Merci fir är Bezuelung.",
            "message_takeaway": "Är Bezuelung war erfollegräich an Är Bestellung gouf un den Restaurant geschéckt.",
            "refunded1": "Leider, mir konnten Är Bestellung net veraarbechten.",
            "refunded2": "Är Bezuelung gouf zréckbezuelt.",
            "title": "Bezuelung erfollegräich",
            "view_receipt": "Rechnung weisen",
            "waiting": "Wart op Bezuelungsbestätegung"
          },
          "toast": {
            "bad_items": "E puer Artikel an Ärer Bestellung sinn net méi disponibel an goufen erausgeholl",
            "location_closed": "Dës Plaz akzeptéiert aktuell keng Orders",
            "order_placed": "Är Bestellung ass opginn"
          },
          "view_basket": "Kuerf weisen",
          "view_bill": "Rechnung weisen"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "lt": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selerija",
        "cereals": "Glitimas",
        "crustaceans": "Krustacejai",
        "dairy": "Pienas",
        "eggs": "Kiaušiniai",
        "fish": "Žuvis",
        "lupin": "Lupinas",
        "mustard": "Garstyčios",
        "nuts": "Riešutai",
        "peanuts": "Riešutai",
        "sesame": "Sezamas",
        "shellfish": "Vėžiagyviai",
        "soy": "Sojos produktai",
        "sulphur": "Sulfitai"
      },
      "common": {
        "actions": "Actions",
        "back": "Atgal",
        "cancel": "Atšaukti",
        "confirm": "Patvirtinti",
        "continue": "Tęsti",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Be gliuteno",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Nealkoholiniai",
        "vegan": "Vegan",
        "vegetarian": "Vegetariškas"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Pridėkite elementus iš meniu, kad pradėtumėte",
            "empty_title": "Jūsų krepšelis yra tuščias",
            "go_to_checkout": "Eikite į atsiskaitymą",
            "place_order": "Užsakyti",
            "title": "Jūsų krepšelis",
            "total": "Iš viso"
          },
          "bill": {
            "go_to_payment": "Eikite į mokėjimą",
            "outstanding": "Neapmokėta suma",
            "paid": "Apmokėta",
            "title": "Jūsų sąskaita",
            "total": "Iš viso"
          },
          "change_menu": "Pakeisti meniu",
          "checkout": {
            "email": "El. paštas",
            "ending_in": "Kortelė, kurios paskutiniai skaičiai - %{last4}",
            "name": "Vardas",
            "or": "ar",
            "pay": "Apmokėti %{amount}",
            "previous_payment": "Ankstesniai naudoti kortai",
            "title": "Atsiskaitymas",
            "total": "Iš viso",
            "use": "Apmokėti"
          },
          "choose_menu": "Pasirinkite meniu",
          "closed": "%{name} šiuo metu nepriima užsakymų.",
          "item": {
            "add_for": "Pridėti už %{price}",
            "choose_size": "Pasirinkite dydį",
            "contains": "Sudėtyje:",
            "increase_quantity": "Padidinti kiekį",
            "reduce_quantity": "Sumažinti kiekį",
            "select_at_least": "Prašome pasirinkti bent %{min} variantus.",
            "select_one": "Prašome pasirinkti variantą."
          },
          "kcal_disclaimer": "Suaugusieji per dieną vidutiniškai turi suvartoti apie 2000 kcal.",
          "no_menus": "Šiuo metu restoranas neturi meniu.",
          "payment_success": {
            "back_to_menu": "Grįžti į meniu",
            "failed1": "Jūsų mokėjimas buvo neįvykdytas.",
            "failed2": "Prašome bandyti dar kartą arba susisiekti su restorano personalu.",
            "message": "Dėkojame už jūsų mokėjimą.",
            "message_takeaway": "Jūsų mokėjimas buvo sėkmingas ir jūsų užsakymas buvo išsiųstas į restoraną.",
            "refunded1": "Atsiprašome, mes negalėjome apdoroti jūsų užsakymo.",
            "refunded2": "Jūsų mokėjimas buvo grąžintas.",
            "title": "Mokėjimas sėkmingas",
            "view_receipt": "Peržiūrėti kvitą",
            "waiting": "Laukiama mokėjimo patvirtinimo"
          },
          "toast": {
            "bad_items": "Kai kurie jūsų užsakymo elementai daugiau nebepasiekiami ir buvo pašalinti",
            "location_closed": "Šiuo metu ši vieta nepriima užsakymų",
            "order_placed": "Jūsų užsakymas buvo įvykdytas"
          },
          "view_basket": "Peržiūrėti krepšelį",
          "view_bill": "Peržiūrėti sąskaitą"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "lv": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selerija",
        "cereals": "Glutēns",
        "crustaceans": "Garneles",
        "dairy": "Piena produkti",
        "eggs": "Olas",
        "fish": "Zivis",
        "lupin": "Lupīns",
        "mustard": "Garšvielas",
        "nuts": "Rieksti",
        "peanuts": "Zemesrieksti",
        "sesame": "Ķimenes",
        "shellfish": "Jūras veltes",
        "soy": "Sojas",
        "sulphur": "Sulfiti"
      },
      "common": {
        "actions": "Actions",
        "back": "Atpakaļ",
        "cancel": "Atcelt",
        "confirm": "Apstiprināt",
        "continue": "Turpināt",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bezglutēna",
        "halal": "Halāls",
        "kosher": "Košers",
        "teetotal": "Bezalkoholisks",
        "vegan": "Vegāns",
        "vegetarian": "Veģetārietis"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Pievienojiet vienumus no ēdienkarte, lai sāktu",
            "empty_title": "Jūsu grozs ir tukšs",
            "go_to_checkout": "Doties uz izrakstīšanos",
            "place_order": "Veikt pasūtījumu",
            "title": "Jūsu grozs",
            "total": "Kopā"
          },
          "bill": {
            "go_to_payment": "Doties uz maksājumu",
            "outstanding": "Atlikusī atlikme",
            "paid": "Samaksāts",
            "title": "Jūsu rēķins",
            "total": "Kopā"
          },
          "change_menu": "Mainīt ēdienkarti",
          "checkout": {
            "email": "E-pasts",
            "ending_in": "Karte, kas beidzas ar %{last4}",
            "name": "Vārds",
            "or": "vai",
            "pay": "Samaksāt %{amount}",
            "previous_payment": "Iepriekš izmantotās kartes",
            "title": "Izrakstīties",
            "total": "Kopā",
            "use": "Samaksāt"
          },
          "choose_menu": "Izvēlieties ēdienkarti",
          "closed": "%{name} šobrīd nepieņem pasūtījumus.",
          "item": {
            "add_for": "Pievienot %{price}",
            "choose_size": "Izvēlieties izmēru",
            "contains": "Satur:",
            "increase_quantity": "Palielināt daudzumu",
            "reduce_quantity": "Samazināt daudzumu",
            "select_at_least": "Lūdzu, atlasiet vismaz %{min} opcijas.",
            "select_one": "Lūdzu, atlasiet opciju."
          },
          "kcal_disclaimer": "Pieaugušajiem dienā nepieciešams aptuveni 2000 kcal.",
          "no_menus": "Restorānam pašlaik nav pieejamu ēdienkāršu.",
          "payment_success": {
            "back_to_menu": "Atpakaļ uz ēdienkarti",
            "failed1": "Jūsu maksājums nebija veiksmīgs.",
            "failed2": "Lūdzu, mēģiniet vēlreiz vai sazinieties ar restorāna personālu.",
            "message": "Paldies par jūsu maksājumu.",
            "message_takeaway": "Jūsu maksājums bija veiksmīgs un jūsu pasūtījums ir nosūtīts restorānam.",
            "refunded1": "Atvainojiet, mēs nevarējām apstrādāt jūsu pasūtījumu.",
            "refunded2": "Jūsu maksājums ir atmaksāts.",
            "title": "Maksājums veiksmīgs",
            "view_receipt": "Skatīt čeku",
            "waiting": "Gaidām maksājuma apstiprinājumu"
          },
          "toast": {
            "bad_items": "Daudzi jūsu pasūtījuma vienumi vairs nav pieejami un ir noņemti",
            "location_closed": "Šajā vietā pašlaik netiek pieņemti pasūtījumi",
            "order_placed": "Jūsu pasūtījums ir veikts"
          },
          "view_basket": "Skatīt grozu",
          "view_bill": "Skatīt rēķinu"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "mk": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Селери",
        "cereals": "Глутен",
        "crustaceans": "Ракообразни",
        "dairy": "Млеко",
        "eggs": "Јајца",
        "fish": "Риба",
        "lupin": "Лупин",
        "mustard": "Сенф",
        "nuts": "Ореви",
        "peanuts": "Кафеави лешници",
        "sesame": "Сусам",
        "shellfish": "Шкампи",
        "soy": "Соја",
        "sulphur": "Сулфити"
      },
      "common": {
        "actions": "Actions",
        "back": "Назад",
        "cancel": "Откажи",
        "confirm": "Потврди",
        "continue": "Продолжи",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Без глутен",
        "halal": "Халал",
        "kosher": "Кошер",
        "teetotal": "Неалкохолно",
        "vegan": "Веган",
        "vegetarian": "Вегетаријански"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Додадете предмети од мениот за да започнете",
            "empty_title": "Вашата кошничка е празна",
            "go_to_checkout": "Оди на наплата",
            "place_order": "Нарачај",
            "title": "Вашата кошничка",
            "total": "Вкупно"
          },
          "bill": {
            "go_to_payment": "Оди на плаќање",
            "outstanding": "Неплатен биланс",
            "paid": "Платено",
            "title": "Вашиот сметка",
            "total": "Вкупно"
          },
          "change_menu": "Промени мени",
          "checkout": {
            "email": "Е-пошта",
            "ending_in": "Картичка која завршува со %{last4}",
            "name": "Име",
            "or": "или",
            "pay": "Плати %{amount}",
            "previous_payment": "Претходно користени картички",
            "title": "Наплата",
            "total": "Вкупно",
            "use": "Плати"
          },
          "choose_menu": "Изберете мени",
          "closed": "%{name} во моментов не прима нарачки",
          "item": {
            "add_for": "Додадете за %{price}",
            "choose_size": "Изберете големина",
            "contains": "Содржи:",
            "increase_quantity": "Зголемете количина",
            "reduce_quantity": "Намалете количина",
            "select_at_least": "Ве молиме изберете најмалку %{min} опции",
            "select_one": "Ве молиме изберете опција"
          },
          "kcal_disclaimer": "Возрасните потребуваат околу 2000 калории на ден.",
          "no_menus": "Ресторанот моментално нема достапни менија",
          "payment_success": {
            "back_to_menu": "Назад кон мени",
            "failed1": "Вашето плаќање не успеа",
            "failed2": "Ве молиме обидете се повторно или контактирајте го персоналот на ресторанот",
            "message": "Ви благодариме за плаќањето",
            "message_takeaway": "Вашето плаќање беше успешно и вашата нарачка е испратена до ресторанот",
            "refunded1": "Извинете, не можевме да ја обработиме вашата нарачка",
            "refunded2": "Вашето плаќање е вратено",
            "title": "Плаќањето е успешно",
            "view_receipt": "Прегледајте распорака",
            "waiting": "Чекање на потврда за плаќање"
          },
          "toast": {
            "bad_items": "Некои предмети во вашата нарачка повеќе не се достапни и се отстранети",
            "location_closed": "Оваа локација моментално не прима нарачки",
            "order_placed": "Вашата нарачка е извршена"
          },
          "view_basket": "Преглед на кошничка",
          "view_bill": "Преглед на сметка"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "mr": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "केलरी",
        "cereals": "ग्लूटेन",
        "crustaceans": "क्रस्टेशियन्स",
        "dairy": "डेयरी",
        "eggs": "अंडे",
        "fish": "मासा",
        "lupin": "लूपिन",
        "mustard": "मस्टर्ड",
        "nuts": "डागडाण्यांचे",
        "peanuts": "शेंगदाण्यांचे",
        "sesame": "तिल",
        "shellfish": "कोबी",
        "soy": "सोय",
        "sulphur": "सल्फाइट्स"
      },
      "common": {
        "actions": "Actions",
        "back": "मागे",
        "cancel": "रद्द करा",
        "confirm": "पुष्टी करा",
        "continue": "सुरु ठेवा",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "ग्लूटेन मुक्त",
        "halal": "हलाल",
        "kosher": "कोशेर",
        "teetotal": "नॉन-एल्कोहोलिक",
        "vegan": "शाकाहारी",
        "vegetarian": "शाकाहारी"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "प्रारंभ करण्यासाठी मेनूतील आयटम जोडा",
            "empty_title": "आपली टोकरी रिक्त आहे",
            "go_to_checkout": "चेकआउटला जा",
            "place_order": "ऑर्डर द्या",
            "title": "आपली टोकरी",
            "total": "एकूण"
          },
          "bill": {
            "go_to_payment": "पेमेंटला जा",
            "outstanding": "शेष रक्कम",
            "paid": "भरलेले",
            "title": "आपली बिल",
            "total": "एकूण"
          },
          "change_menu": "मेनू बदला",
          "checkout": {
            "email": "ईमेल",
            "ending_in": "%{last4} समाप्त होणारे कार्ड",
            "name": "नाव",
            "or": "किंवा",
            "pay": "%{amount} देय",
            "previous_payment": "मागील वापरलेले कार्ड",
            "title": "चेकआउट",
            "total": "एकूण",
            "use": "भरा"
          },
          "choose_menu": "मेनू निवडा",
          "closed": "%{name} आता ऑर्डर स्वीकारायला उपलब्ध नाही.",
          "item": {
            "add_for": "%{price} साठी जोडा",
            "choose_size": "साईझ निवडा",
            "contains": "समाविष्ट केले:",
            "increase_quantity": "मात्र व्यापार वाढवा",
            "reduce_quantity": "मात्र व्यापार कमी करा",
            "select_at_least": "किमान %{min} पर्याय निवडा.",
            "select_one": "कृपया एक पर्याय निवडा."
          },
          "kcal_disclaimer": "प्रौढांच्या दररोज २००० कॅलरीची आवश्यकता आहे.",
          "no_menus": "रेस्टॉरंट आत्ता उपलब्ध मेनू नाही.",
          "payment_success": {
            "back_to_menu": "मेनूवर परत जा",
            "failed1": "आपला पेमेंट असफळ झाला.",
            "failed2": "कृपया पुन्हा प्रयत्न करा किव्हा रेस्टॉरंट स्टाफला संपर्क साधा.",
            "message": "आपल्या पेमेंटसाठी धन्यवाद.",
            "message_takeaway": "आपला पेमेंट सफळ झाला आणि आपला ऑर्डर रेस्टॉरंटला पाठविला गेला आहे.",
            "refunded1": "माफ करा, आम्ही आपल्या ऑर्डरला प्रक्रिया करू शकलो नाही.",
            "refunded2": "आपला पेमेंट रिफंड केला गेला आहे.",
            "title": "पेमेंट सफळ",
            "view_receipt": "रसीद पाहा",
            "waiting": "पेमेंट स्थिरीकरणासाठी प्रतीक्षा करीत आहोत"
          },
          "toast": {
            "bad_items": "तुमच्या ऑर्डरमध्ये काही आयटम उपलब्ध नाहीत आणि त्यांना काढून टाकले गेले आहेत.",
            "location_closed": "या स्थानावर आत्ता ऑर्डर स्वीकारला जात नाही.",
            "order_placed": "आपला ऑर्डर दिला गेला आहे"
          },
          "view_basket": "टोकरी पाहा",
          "view_bill": "बिल पाहा"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "mt": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Karfus",
        "cereals": "Ġluten",
        "crustaceans": "Krustaċejn",
        "dairy": "Ħalib",
        "eggs": "Bajd",
        "fish": "Ħut",
        "lupin": "Lupin",
        "mustard": "Tewm",
        "nuts": "Ġewż",
        "peanuts": "Fwid",
        "sesame": "Tilwien",
        "shellfish": "Koktel",
        "soy": "Soya",
        "sulphur": "Sulfiti"
      },
      "common": {
        "actions": "Actions",
        "back": "Arja",
        "cancel": "Ikkanzell",
        "confirm": "Ikkonferma",
        "continue": "Ikompli",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "B'xejn ġluten",
        "halal": "Halal",
        "kosher": "Kożer",
        "teetotal": "Non-alċoħoliku",
        "vegan": "Vegan",
        "vegetarian": "Veġetarjan"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Żid prodotti mill-menu biex tibda",
            "empty_title": "Il-kaxxa tiegħek hija vojta",
            "go_to_checkout": "Mur lejn il-ħlas",
            "place_order": "Poġġi l-ordni",
            "title": "Il-kaxxa tiegħek",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Mur għall-ħlas",
            "outstanding": "Saldu baġit",
            "paid": "Mħallas",
            "title": "Il-kont tiegħek",
            "total": "Total"
          },
          "change_menu": "Ibbadil il-menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Karta li tmiem f'%{last4}",
            "name": "Isem",
            "or": "jew",
            "pay": "Ħlas %{amount}",
            "previous_payment": "Karti li ġiet jużata preċedentement",
            "title": "Ħlas",
            "total": "Total",
            "use": "Ħlas"
          },
          "choose_menu": "Agħżel menu",
          "closed": "%{name} ma jħallasx l-ordni filwaqt li",
          "item": {
            "add_for": "Żid għal %{price}",
            "choose_size": "Agħżel daqs",
            "contains": "Mkun:",
            "increase_quantity": "Żid il-kwantità",
            "reduce_quantity": "Ħafna lill-kwantità",
            "select_at_least": "Jekk jogħġbok agħżel mill-inqas %{min} għażliet.",
            "select_one": "Jekk jogħġbok agħżel għażla."
          },
          "kcal_disclaimer": "Adulti jeħtiegħu madwar 2000 kcal kuljum.",
          "no_menus": "Ir-ristorant m'għandux menus disponibbli fil-mument.",
          "payment_success": {
            "back_to_menu": "Lura għall-menu",
            "failed1": "Il-ħlas tiegħek kien mhux suċċessu.",
            "failed2": "Jekk jogħġbok erġa pprova jew ikkuntattja l-istaff tar-ristorant.",
            "message": "Grazzi għall-ħlas tiegħek.",
            "message_takeaway": "Il-ħlas tiegħek kien suċċessu u l-ordni tiegħek diġà intbagħtu lill-ristorant.",
            "refunded1": "Sajn, ma nistgħux inproċessaw l-ordni tiegħek.",
            "refunded2": "Il-ħlas tiegħek ġie irrestitwit.",
            "title": "Ħlas suċċessu",
            "view_receipt": "Ara ir-riċevuta",
            "waiting": "Qed tistenna konferma tal-ħlas"
          },
          "toast": {
            "bad_items": "Xi prodotti fil-ħlas tiegħek ma huma l-iktar disponibbli u nqraw minnha",
            "location_closed": "Din il-lokalità bħalissa ma taċċettax l-ordni",
            "order_placed": "L-ordni tiegħek giet imsejra"
          },
          "view_basket": "Ara l-kaxxa",
          "view_bill": "Ara l-kont"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "nl": {
    "common": {
      "actions": "Acties",
      "active": "Actief",
      "add": "Toevoegen",
      "and_x_more": "en nog %{count}",
      "any": "Elk",
      "archive": "Archiveren",
      "archived": "Gearchiveerd",
      "are_you_sure": "Weet je het zeker?",
      "back": "Terug",
      "cancel": "Annuleren",
      "choose": "Kiezen",
      "close": "Sluiten",
      "closed": "Gesloten",
      "confirm": "Bevestigen",
      "confirm_delete": "Weet je het zeker?",
      "continue": "Doorgaan",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Verwijderen",
      "details": "Details",
      "do_not_refresh_page": "Vernieuw de pagina niet of sluit deze niet.",
      "edit": "Bewerken",
      "form_error_html": "Sommige van de ingediende gegevens zijn ongeldig. Controleer het formulier op fouten en probeer het opnieuw. Als het probleem aanhoudt, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">neem contact op met de klantenservice</a>.",
      "learn_more": "Meer informatie",
      "minutes": "Minutes",
      "never": "Nooit",
      "no": "Nee",
      "none": "Geen",
      "not_active": "Niet actief",
      "not_provided": "Niet verstrekt",
      "processing": "Verwerken",
      "remove": "Verwijderen",
      "restore": "Herstellen",
      "save": "Opslaan",
      "selected": "Geselecteerd",
      "service_types": {
        "at_table": "Aan tafel",
        "delivery": "Levering",
        "takeaway": "Afhalen"
      },
      "settings_saved": "De instellingen zijn opgeslagen.",
      "show": "Tonen",
      "talk_to_an_expert": "Praat met een expert",
      "view": "Bekijken",
      "yes": "Ja"
    },
    "js": {
      "allergens": {
        "celery": "Selderij",
        "cereals": "Gluten",
        "crustaceans": "Schaaldieren",
        "dairy": "Zuivel",
        "eggs": "Eieren",
        "fish": "Vis",
        "lupin": "Lupine",
        "mustard": "Mosterd",
        "nuts": "Noten",
        "peanuts": "Pinda's",
        "sesame": "Sesam",
        "shellfish": "Weekdieren",
        "soy": "Soja",
        "sulphur": "Sulfieten"
      },
      "common": {
        "actions": "Actions",
        "back": "Terug",
        "cancel": "Annuleren",
        "confirm": "Bevestigen",
        "continue": "Doorgaan",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenvrij",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alcoholvrij",
        "vegan": "Veganistisch",
        "vegetarian": "Vegetarisch"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Voeg items uit het menu toe om te beginnen",
            "empty_title": "Uw mandje is leeg",
            "go_to_checkout": "Ga naar de kassa",
            "place_order": "Bestelling plaatsen",
            "title": "Uw mandje",
            "total": "Totaal"
          },
          "bill": {
            "go_to_payment": "Ga naar betaling",
            "outstanding": "Openstaand bedrag",
            "paid": "Betaald",
            "title": "Uw rekening",
            "total": "Totaal"
          },
          "change_menu": "Menu wijzigen",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Kaart eindigend op %{last4}",
            "name": "Naam",
            "or": "of",
            "pay": "Betaling van %{amount}",
            "previous_payment": "Eerder gebruikte kaarten",
            "title": "Afrekenen",
            "total": "Totaal",
            "use": "Betalen"
          },
          "choose_menu": "Kies een menu",
          "closed": "%{name} accepteert op dit moment geen bestellingen.",
          "item": {
            "add_for": "Toevoegen voor %{price}",
            "choose_size": "Kies een maat",
            "contains": "Bevat:",
            "increase_quantity": "Hoeveelheid verhogen",
            "reduce_quantity": "Hoeveelheid verlagen",
            "select_at_least": "Selecteer minimaal %{min} opties.",
            "select_one": "Selecteer een optie."
          },
          "kcal_disclaimer": "Volwassenen hebben ongeveer 2000 kcal per dag nodig.",
          "no_menus": "Het restaurant heeft op dit moment geen beschikbare menu's.",
          "payment_success": {
            "back_to_menu": "Terug naar het menu",
            "failed1": "Uw betaling was niet succesvol.",
            "failed2": "Probeer het opnieuw of neem contact op met het restaurant personeel.",
            "message": "Dank u voor uw betaling.",
            "message_takeaway": "Uw betaling was succesvol en uw bestelling is doorgestuurd naar het restaurant.",
            "refunded1": "Sorry, we konden uw bestelling niet verwerken.",
            "refunded2": "Uw betaling is terugbetaald.",
            "title": "Betaling geslaagd",
            "view_receipt": "Bekijk ontvangstbewijs",
            "waiting": "Wachten op betalingsbevestiging"
          },
          "toast": {
            "bad_items": "Sommige items in uw bestelling zijn niet langer beschikbaar en zijn verwijderd",
            "location_closed": "Deze locatie accepteert momenteel geen bestellingen",
            "order_placed": "Uw bestelling is geplaatst"
          },
          "view_basket": "Bekijk winkelmand",
          "view_bill": "Bekijk rekening"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centimeters",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grammen",
      "gal": "Gallon",
      "kg": "Kilogrammen",
      "l": "Liters",
      "lb": "Ponden",
      "mg": "Milligrams",
      "ml": "Milliliters",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "no": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selleri",
        "cereals": "Gluten",
        "crustaceans": "Skalldyr",
        "dairy": "Meieri",
        "eggs": "Egg",
        "fish": "Fisk",
        "lupin": "Lupin",
        "mustard": "Sennep",
        "nuts": "Nøtter",
        "peanuts": "Peanøtter",
        "sesame": "Sesam",
        "shellfish": "Skalldyr",
        "soy": "Soyabønner",
        "sulphur": "Sulfitter"
      },
      "common": {
        "actions": "Actions",
        "back": "Tilbake",
        "cancel": "Avbryt",
        "confirm": "Bekreft",
        "continue": "Fortsett",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenfri",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alkoholfri",
        "vegan": "Vegansk",
        "vegetarian": "Vegetarisk"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Legg til elementer fra menyen for å komme i gang",
            "empty_title": "Handlekurven din er tom",
            "go_to_checkout": "Gå til kassen",
            "place_order": "Legg ordre",
            "title": "Handlekurven din",
            "total": "Totalt"
          },
          "bill": {
            "go_to_payment": "Gå til betaling",
            "outstanding": "Utestående saldo",
            "paid": "Betalt",
            "title": "Din regning",
            "total": "Totalt"
          },
          "change_menu": "Endre meny",
          "checkout": {
            "email": "E-post",
            "ending_in": "Kort som slutter på %{last4}",
            "name": "Navn",
            "or": "eller",
            "pay": "Betal %{amount}",
            "previous_payment": "Tidligere brukte kort",
            "title": "Kasse",
            "total": "Totalt",
            "use": "Betale"
          },
          "choose_menu": "Velg en meny",
          "closed": "%{name} godtar for øyeblikket ikke bestillinger.",
          "item": {
            "add_for": "Legg til for %{price}",
            "choose_size": "Velg en størrelse",
            "contains": "Inneholder:",
            "increase_quantity": "Øk antall",
            "reduce_quantity": "Reduser antall",
            "select_at_least": "Vennligst velg minst %{min} alternativer.",
            "select_one": "Vennligst velg et alternativ."
          },
          "kcal_disclaimer": "Voksne trenger omtrent 2000 kcal per dag.",
          "no_menus": "Restauranten har ingen tilgjengelige menyer for øyeblikket.",
          "payment_success": {
            "back_to_menu": "Tilbake til menyen",
            "failed1": "Betalingen din var ikke vellykket.",
            "failed2": "Vennligst prøv igjen eller kontakt restaurantpersonalet.",
            "message": "Takk for betalingen din.",
            "message_takeaway": "Betalingen din var vellykket og bestillingen din er sendt til restauranten.",
            "refunded1": "Beklager, vi kunne ikke behandle bestillingen din.",
            "refunded2": "Betalingen din er refundert.",
            "title": "Betaling vellykket",
            "view_receipt": "Vis kvittering",
            "waiting": "Venter på betalingsbekreftelse"
          },
          "toast": {
            "bad_items": "Noen elementer i bestillingen din er ikke lenger tilgjengelige og er fjernet",
            "location_closed": "Denne lokasjonen godtar for øyeblikket ikke bestillinger",
            "order_placed": "Bestillingen din er plassert"
          },
          "view_basket": "Vis handlekurv",
          "view_bill": "Vis regning"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "pl": {
    "common": {
      "actions": "Działania",
      "active": "Aktywny",
      "add": "Dodaj",
      "and_x_more": "i %{count} więcej",
      "any": "Dowolny",
      "archive": "Archiwum",
      "archived": "Zarchiwizowane",
      "are_you_sure": "Czy na pewno?",
      "back": "Powrót",
      "cancel": "Anuluj",
      "choose": "Wybierz",
      "close": "Zamknij",
      "closed": "Zamknięte",
      "confirm": "Potwierdź",
      "confirm_delete": "Czy na pewno?",
      "continue": "Kontynuuj",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Usuń",
      "details": "Szczegóły",
      "do_not_refresh_page": "Nie odświeżaj ani nie zamykaj strony.",
      "edit": "Edytuj",
      "form_error_html": "Niektóre z przesłanych danych są nieprawidłowe. Proszę sprawdzić formularz pod kątem błędów i spróbować ponownie. Jeśli problem będzie się powtarzał, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">skontaktuj się z obsługą klienta</a>.",
      "learn_more": "Dowiedz się więcej",
      "minutes": "Minutes",
      "never": "Nigdy",
      "no": "Nie",
      "none": "Brak",
      "not_active": "Nieaktywny",
      "not_provided": "Nieobecny",
      "processing": "Przetwarzanie",
      "remove": "Usuń",
      "restore": "Przywróć",
      "save": "Zapisz",
      "selected": "Wybrane",
      "service_types": {
        "at_table": "Przy stoliku",
        "delivery": "Dostawa",
        "takeaway": "Na wynos"
      },
      "settings_saved": "Ustawienia zostały zapisane.",
      "show": "Pokaż",
      "talk_to_an_expert": "Porozmawiaj z ekspertem",
      "view": "Zobacz",
      "yes": "Tak"
    },
    "js": {
      "allergens": {
        "celery": "Seler",
        "cereals": "Gluten",
        "crustaceans": "Skorupiaki",
        "dairy": "Nabiał",
        "eggs": "Jajka",
        "fish": "Ryby",
        "lupin": "Lupina",
        "mustard": "Musztarda",
        "nuts": "Orzechy",
        "peanuts": "Orzeszki ziemne",
        "sesame": "Sezam",
        "shellfish": "Skorupiaki",
        "soy": "Soja",
        "sulphur": "Siarczyny"
      },
      "common": {
        "actions": "Actions",
        "back": "Wstecz",
        "cancel": "Anuluj",
        "confirm": "Potwierdź",
        "continue": "Kontynuuj",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bezglutenowe",
        "halal": "Halal",
        "kosher": "Koszerne",
        "teetotal": "Bezalkoholowe",
        "vegan": "Wegańskie",
        "vegetarian": "Wegetariańskie"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Dodaj pozycje z menu, aby rozpocząć",
            "empty_title": "Twój koszyk jest pusty",
            "go_to_checkout": "Przejdź do kasy",
            "place_order": "Złóż zamówienie",
            "title": "Twój koszyk",
            "total": "Łącznie"
          },
          "bill": {
            "go_to_payment": "Przejdź do płatności",
            "outstanding": "Niezapłacony balans",
            "paid": "Zapłacone",
            "title": "Twój rachunek",
            "total": "Razem"
          },
          "change_menu": "Zmień menu",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Karta kończąca się na %{last4}",
            "name": "Imię",
            "or": "lub",
            "pay": "Zapłać %{amount}",
            "previous_payment": "Poprzednio używane karty",
            "title": "Kasa",
            "total": "Razem",
            "use": "Zapłać"
          },
          "choose_menu": "Wybierz menu",
          "closed": "%{name} aktualnie nie przyjmuje zamówień.",
          "item": {
            "add_for": "Dodaj do %{price}",
            "choose_size": "Wybierz rozmiar",
            "contains": "Zawiera:",
            "increase_quantity": "Zwiększ ilość",
            "reduce_quantity": "Zmniejsz ilość",
            "select_at_least": "Prosimy wybierz co najmniej %{min} opcji.",
            "select_one": "Prosimy wybierz opcję."
          },
          "kcal_disclaimer": "Dorośli potrzebują około 2000 kcal dziennie.",
          "no_menus": "Restauracja w tej chwili nie ma dostępnych menu.",
          "payment_success": {
            "back_to_menu": "Powrót do menu",
            "failed1": "Twoja płatność nie powiodła się.",
            "failed2": "Spróbuj ponownie lub skontaktuj się z personelem restauracji.",
            "message": "Dziękujemy za Twoją płatność.",
            "message_takeaway": "Twoja płatność została zrealizowana pomyślnie i Twoje zamówienie zostało przesłane do restauracji.",
            "refunded1": "Przepraszamy, nie mogliśmy zrealizować Twojego zamówienia.",
            "refunded2": "Twoja płatność została zwrócona.",
            "title": "Płatność zakończona sukcesem.",
            "view_receipt": "Zobacz paragon.",
            "waiting": "Oczekiwanie na potwierdzenie płatności."
          },
          "toast": {
            "bad_items": "Niektóre pozycje w Twoim zamówieniu nie są już dostępne i zostały usunięte.",
            "location_closed": "Ta lokalizacja obecnie nie przyjmuje zamówień.",
            "order_placed": "Twoje zamówienie zostało złożone."
          },
          "view_basket": "Zobacz koszyk.",
          "view_bill": "Zobacz rachunek."
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centymetry",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Gramy",
      "gal": "Gallon",
      "kg": "Kilogramy",
      "l": "Litry",
      "lb": "Funty",
      "mg": "Milligrams",
      "ml": "Mililitry",
      "oz": "Uncje",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "pt": {
    "common": {
      "actions": "Ações",
      "active": "Ativo",
      "add": "Adicionar",
      "and_x_more": "e mais %{count}",
      "any": "Qualquer",
      "archive": "Arquivar",
      "archived": "Arquivado",
      "are_you_sure": "Tem certeza?",
      "back": "Voltar",
      "cancel": "Cancelar",
      "choose": "Escolher",
      "close": "Fechar",
      "closed": "Fechado",
      "confirm": "Confirmar",
      "confirm_delete": "Tem certeza?",
      "continue": "Continuar",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Excluir",
      "details": "Detalhes",
      "do_not_refresh_page": "Não atualize ou feche a página.",
      "edit": "Editar",
      "form_error_html": "Algumas das informações enviadas são inválidas. Por favor, verifique o formulário em busca de erros e tente novamente. Se o problema persistir, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">entre em contato com o suporte ao cliente</a>.",
      "learn_more": "Saiba mais",
      "minutes": "Minutes",
      "never": "Nunca",
      "no": "Não",
      "none": "Nenhum",
      "not_active": "Não ativo",
      "not_provided": "Ausente",
      "processing": "Processando",
      "remove": "Remover",
      "restore": "Restaurar",
      "save": "Salvar",
      "selected": "Selecionado",
      "service_types": {
        "at_table": "Na mesa",
        "delivery": "Entrega",
        "takeaway": "Para viagem"
      },
      "settings_saved": "As configurações foram salvas.",
      "show": "Mostrar",
      "talk_to_an_expert": "Falar com um especialista",
      "view": "Visualizar",
      "yes": "Sim"
    },
    "js": {
      "allergens": {
        "celery": "Aipo",
        "cereals": "Glúten",
        "crustaceans": "Crustáceos",
        "dairy": "Laticínios",
        "eggs": "Ovos",
        "fish": "Peixe",
        "lupin": "Lupino",
        "mustard": "Mostarda",
        "nuts": "Nozes",
        "peanuts": "Amendoim",
        "sesame": "Gergelim",
        "shellfish": "Mariscos",
        "soy": "Soja",
        "sulphur": "Sulfitos"
      },
      "common": {
        "actions": "Actions",
        "back": "Voltar",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "continue": "Continuar",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Sem glúten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Não alcoólico",
        "vegan": "Vegano",
        "vegetarian": "Vegetariano"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Adicione itens do menu para começar",
            "empty_title": "Seu carrinho está vazio",
            "go_to_checkout": "Ir para o checkout",
            "place_order": "Fazer pedido",
            "title": "Seu carrinho",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Ir para pagamento",
            "outstanding": "Saldo pendente",
            "paid": "Pago",
            "title": "Sua conta",
            "total": "Total"
          },
          "change_menu": "Trocar menu",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Cartão terminando em %{last4}",
            "name": "Nome",
            "or": "ou",
            "pay": "Pagar %{amount}",
            "previous_payment": "Cartões usados anteriormente",
            "title": "Checkout",
            "total": "Total",
            "use": "Pagar"
          },
          "choose_menu": "Escolha um menu",
          "closed": "%{name} não está aceitando pedidos no momento.",
          "item": {
            "add_for": "Adicionar para %{price}",
            "choose_size": "Escolha um tamanho",
            "contains": "Contém:",
            "increase_quantity": "Aumentar quantidade",
            "reduce_quantity": "Reduzir quantidade",
            "select_at_least": "Por favor, selecione pelo menos %{min} opções.",
            "select_one": "Por favor, selecione uma opção."
          },
          "kcal_disclaimer": "Os adultos precisam de cerca de 2000 kcal por dia.",
          "no_menus": "O restaurante não possui menus disponíveis no momento.",
          "payment_success": {
            "back_to_menu": "Voltar para o menu",
            "failed1": "Seu pagamento não foi bem-sucedido.",
            "failed2": "Por favor, tente novamente ou entre em contato com a equipe do restaurante.",
            "message": "Obrigado pelo seu pagamento.",
            "message_takeaway": "Seu pagamento foi bem-sucedido e seu pedido foi enviado para o restaurante.",
            "refunded1": "Desculpe, não conseguimos processar seu pedido.",
            "refunded2": "Seu pagamento foi reembolsado.",
            "title": "Pagamento bem-sucedido",
            "view_receipt": "Ver recibo",
            "waiting": "Aguardando confirmação de pagamento"
          },
          "toast": {
            "bad_items": "Alguns itens do seu pedido não estão mais disponíveis e foram removidos",
            "location_closed": "Este local não está aceitando pedidos no momento",
            "order_placed": "Seu pedido foi realizado"
          },
          "view_basket": "Ver cesta",
          "view_bill": "Ver conta"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centímetros",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Gramas",
      "gal": "Gallon",
      "kg": "Quilogramas",
      "l": "Litros",
      "lb": "Libras",
      "mg": "Milligrams",
      "ml": "Mililitros",
      "oz": "Onças",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "ro": {
    "common": {
      "actions": "Acțiuni",
      "active": "Activ",
      "add": "Adăugați",
      "and_x_more": "și %{count} mai multe",
      "any": "Oricare",
      "archive": "Arhivă",
      "archived": "Arhivat",
      "are_you_sure": "Sunteți sigur?",
      "back": "Înapoi",
      "cancel": "Anulați",
      "choose": "Alegeți",
      "close": "Închideți",
      "closed": "Închis",
      "confirm": "Confirmați",
      "confirm_delete": "Sunteți sigur?",
      "continue": "Continuați",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Ștergeți",
      "details": "Detalii",
      "do_not_refresh_page": "Nu reîmprospătați sau închideți pagina.",
      "edit": "Editați",
      "form_error_html": "Unele dintre datele introduse sunt invalide. Vă rugăm să verificați formularul pentru erori și să încercați din nou. Dacă problema persistă, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contactați suportul pentru clienți</a>.",
      "learn_more": "Aflați mai multe",
      "minutes": "Minutes",
      "never": "Niciodată",
      "no": "Nu",
      "none": "Niciunul",
      "not_active": "Neactiv",
      "not_provided": "Nu este furnizat",
      "processing": "Procesare",
      "remove": "Eliminați",
      "restore": "Restaurați",
      "save": "Salvați",
      "selected": "Selectat",
      "service_types": {
        "at_table": "La masă",
        "delivery": "Livrare",
        "takeaway": "Luați cu voi"
      },
      "settings_saved": "Setările au fost salvate.",
      "show": "Afișați",
      "talk_to_an_expert": "Vorbiți cu un expert",
      "view": "Vizualizați",
      "yes": "Da"
    },
    "js": {
      "allergens": {
        "celery": "Ţelină",
        "cereals": "Gluten",
        "crustaceans": "Crustacee",
        "dairy": "Produse lactate",
        "eggs": "Ouă",
        "fish": "Pește",
        "lupin": "Lupin",
        "mustard": "Muștar",
        "nuts": "Nuci",
        "peanuts": "Arahide",
        "sesame": "Semințe de susan",
        "shellfish": "Scoici",
        "soy": "Soia",
        "sulphur": "Sulfiți"
      },
      "common": {
        "actions": "Actions",
        "back": "Înapoi",
        "cancel": "Anulare",
        "confirm": "Confirmare",
        "continue": "Continuare",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Fără gluten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Non-alcoolic",
        "vegan": "Vegan",
        "vegetarian": "Vegetarian"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Adaugă produse din meniu pentru a începe",
            "empty_title": "Coșul tău este gol",
            "go_to_checkout": "Du-te la plată",
            "place_order": "Plasează comanda",
            "title": "Coșul tău",
            "total": "Total"
          },
          "bill": {
            "go_to_payment": "Du-te la plată",
            "outstanding": "Sold restant",
            "paid": "Plătit",
            "title": "Factura ta",
            "total": "Total"
          },
          "change_menu": "Schimbă meniul",
          "checkout": {
            "email": "Email",
            "ending_in": "Cardul se termină în %{last4}",
            "name": "Nume",
            "or": "sau",
            "pay": "Plătește %{amount}",
            "previous_payment": "Carduri folosite anterior",
            "title": "Plată",
            "total": "Total",
            "use": "Plătește"
          },
          "choose_menu": "Alege un meniu",
          "closed": "%{name} nu acceptă în prezent comenzi.",
          "item": {
            "add_for": "Adaugă pentru %{price}",
            "choose_size": "Alege o dimensiune",
            "contains": "Conține:",
            "increase_quantity": "Creste cantitatea",
            "reduce_quantity": "Scade cantitatea",
            "select_at_least": "Te rugăm să selectezi cel puțin %{min} opțiuni.",
            "select_one": "Te rugăm să selectezi o opțiune."
          },
          "kcal_disclaimer": "Adulții au nevoie de aproximativ 2000 de calorii pe zi.",
          "no_menus": "Restaurantul nu are meniuri disponibile în acest moment.",
          "payment_success": {
            "back_to_menu": "Înapoi la meniu",
            "failed1": "Plata ta dvs. a fost nereușită.",
            "failed2": "Vă rugăm să încercați din nou sau să contactați personalul restaurantului.",
            "message": "Vă mulțumim pentru plata efectuată.",
            "message_takeaway": "Plata dvs. a fost reușită și comanda dvs. a fost trimisă la restaurant.",
            "refunded1": "Ne pare rău, nu am putut procesa comanda dvs.",
            "refunded2": "Plata dvs. a fost rambursată.",
            "title": "Plată reușită",
            "view_receipt": "Vizualizare chitanță",
            "waiting": "Așteptare confirmare plată"
          },
          "toast": {
            "bad_items": "Unele produse din comanda dvs. nu mai sunt disponibile și au fost eliminate",
            "location_closed": "Această locație nu acceptă în prezent comenzi",
            "order_placed": "Comanda dvs. a fost plasată"
          },
          "view_basket": "Vizualizare coș",
          "view_bill": "Vizualizare factură"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cm": "Centimetri",
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grame",
      "gal": "Gallon",
      "kg": "Kilograme",
      "l": "Litri",
      "lb": "Livre",
      "mg": "Milligrams",
      "ml": "Mililitri",
      "oz": "Uncii",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "ru": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Сельдерей",
        "cereals": "Глютен",
        "crustaceans": "Ракообразные",
        "dairy": "Молочные продукты",
        "eggs": "Яйца",
        "fish": "Рыба",
        "lupin": "Люпин",
        "mustard": "Горчица",
        "nuts": "Орехи",
        "peanuts": "Арахис",
        "sesame": "Кунжут",
        "shellfish": "Моллюски",
        "soy": "Соевые бобы",
        "sulphur": "Сульфиты"
      },
      "common": {
        "actions": "Actions",
        "back": "Назад",
        "cancel": "Отмена",
        "confirm": "Подтвердить",
        "continue": "Продолжить",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Без глютена",
        "halal": "Халяль",
        "kosher": "Кошерно",
        "teetotal": "Безалкогольное",
        "vegan": "Веганское",
        "vegetarian": "Вегетарианское"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Добавьте блюда из меню, чтобы начать",
            "empty_title": "Ваша корзина пуста",
            "go_to_checkout": "Перейти к оформлению заказа",
            "place_order": "Оформить заказ",
            "title": "Ваша корзина",
            "total": "Итого"
          },
          "bill": {
            "go_to_payment": "Перейти к оплате",
            "outstanding": "Остаток задолженности",
            "paid": "Оплачено",
            "title": "Ваш счет",
            "total": "Итого"
          },
          "change_menu": "Изменить меню",
          "checkout": {
            "email": "Электронная почта",
            "ending_in": "Карта с последними цифрами %{last4}",
            "name": "Имя",
            "or": "или",
            "pay": "Оплатить %{amount}",
            "previous_payment": "Ранее использованные карты",
            "title": "Оформить заказ",
            "total": "Итого",
            "use": "Оплатить"
          },
          "choose_menu": "Выберите меню",
          "closed": "%{name} в настоящее время не принимает заказы",
          "item": {
            "add_for": "Добавить к %{price}",
            "choose_size": "Выберите размер",
            "contains": "Содержит:",
            "increase_quantity": "Увеличить количество",
            "reduce_quantity": "Уменьшить количество",
            "select_at_least": "Пожалуйста, выберите как минимум %{min} варианта.",
            "select_one": "Пожалуйста, выберите вариант."
          },
          "kcal_disclaimer": "Взрослому человеку требуется около 2000 ккал в день.",
          "no_menus": "Ресторан в настоящее время не предлагает меню.",
          "payment_success": {
            "back_to_menu": "Вернуться в меню",
            "failed1": "Оплата не прошла успешно.",
            "failed2": "Пожалуйста, попробуйте еще раз или свяжитесь с персоналом ресторана.",
            "message": "Спасибо за вашу оплату.",
            "message_takeaway": "Ваш платеж прошел успешно, и ваш заказ отправлен в ресторан.",
            "refunded1": "Извините, мы не смогли обработать ваш заказ.",
            "refunded2": "Ваш платеж был возвращен.",
            "title": "Платеж успешно выполнен",
            "view_receipt": "Просмотреть квитанцию",
            "waiting": "Ожидание подтверждения оплаты"
          },
          "toast": {
            "bad_items": "Некоторые товары в вашем заказе больше не доступны и были удалены",
            "location_closed": "Это местоположение в настоящее время не принимает заказы",
            "order_placed": "Ваш заказ принят"
          },
          "view_basket": "Просмотреть корзину",
          "view_bill": "Просмотреть счет"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "sk": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Zeler",
        "cereals": "Pšenica",
        "crustaceans": "Korýšte",
        "dairy": "Mliečne výrobky",
        "eggs": "Vajcia",
        "fish": "Ryby",
        "lupin": "Lupina",
        "mustard": "Horčica",
        "nuts": "Orechy",
        "peanuts": "Arašidy",
        "sesame": "Sezam",
        "shellfish": "Mäkkýše",
        "soy": "Sójové produkty",
        "sulphur": "Síran"
      },
      "common": {
        "actions": "Actions",
        "back": "Späť",
        "cancel": "Zrušiť",
        "confirm": "Potvrdiť",
        "continue": "Pokračovať",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bezlepkové",
        "halal": "Halal",
        "kosher": "Košer",
        "teetotal": "Nealkoholické",
        "vegan": "Vegánske",
        "vegetarian": "Vegetariánske"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Pridajte položky z ponuky, aby ste začali",
            "empty_title": "Váš košík je prázdny",
            "go_to_checkout": "Prejsť na pokladňu",
            "place_order": "Odoslať objednávku",
            "title": "Váš košík",
            "total": "Celkom"
          },
          "bill": {
            "go_to_payment": "Prejsť na platbu",
            "outstanding": "Zostávajúca suma",
            "paid": "Zaplatené",
            "title": "Vaša faktúra",
            "total": "Celkom"
          },
          "change_menu": "Zmeniť ponuku",
          "checkout": {
            "email": "E-mail",
            "ending_in": "Karta končiaca na %{last4}",
            "name": "Meno",
            "or": "alebo",
            "pay": "Zaplatiť %{amount}",
            "previous_payment": "Predtým používané karty",
            "title": "Pokladňa",
            "total": "Celkom",
            "use": "Platiť"
          },
          "choose_menu": "Vyberte ponuku",
          "closed": "%{name} v súčasnosti neprijíma objednávky",
          "item": {
            "add_for": "Pridať pre %{price}",
            "choose_size": "Vyberte veľkosť",
            "contains": "Obsahuje:",
            "increase_quantity": "Zvýšiť množstvo",
            "reduce_quantity": "Znížiť množstvo",
            "select_at_least": "Prosím, vyberte aspoň %{min} možností.",
            "select_one": "Prosím, vyberte možnosť."
          },
          "kcal_disclaimer": "Dospelí potrebujú denne približne 2000 kcal.",
          "no_menus": "Reštaurácia momentálne nemá k dispozícii žiadne menu.",
          "payment_success": {
            "back_to_menu": "Späť na ponuku",
            "failed1": "Vaša platba bola neúspešná.",
            "failed2": "Skúste to prosím znova alebo kontaktujte personál reštaurácie.",
            "message": "Ďakujeme za vašu platbu.",
            "message_takeaway": "Vaša platba bola úspešná a vaša objednávka bola odoslaná reštaurácii.",
            "refunded1": "Ospravedlňujeme sa, vašu objednávku sme nemohli spracovať.",
            "refunded2": "Vaša platba bola vrátená.",
            "title": "Platba úspešná",
            "view_receipt": "Zobraziť účet",
            "waiting": "Čaká sa na potvrdenie platby"
          },
          "toast": {
            "bad_items": "Niektoré položky vo vašej objednávke už nie sú k dispozícii a boli odstránené",
            "location_closed": "Táto lokalita momentálne neprijíma objednávky",
            "order_placed": "Vaša objednávka bola odoslaná"
          },
          "view_basket": "Zobraziť košík",
          "view_bill": "Zobraziť faktúru"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "sl": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Zelena zelena",
        "cereals": "Gluten",
        "crustaceans": "Raki",
        "dairy": "Mlečni izdelki",
        "eggs": "Jajca",
        "fish": "Riba",
        "lupin": "Lupin",
        "mustard": "Gorčica",
        "nuts": "Oreščki",
        "peanuts": "Arašidi",
        "sesame": "Sezam",
        "shellfish": "Morski sadeži",
        "soy": "Soja",
        "sulphur": "Sulfiti"
      },
      "common": {
        "actions": "Actions",
        "back": "Nazaj",
        "cancel": "Prekliči",
        "confirm": "Potrdi",
        "continue": "Nadaljuj",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Brez glutena",
        "halal": "Halal",
        "kosher": "Košer",
        "teetotal": "Brez alkohola",
        "vegan": "Vegansko",
        "vegetarian": "Vegetarijansko"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Dodajte predmete iz menija, da začnete",
            "empty_title": "Vaša košarica je prazna",
            "go_to_checkout": "Pojdi na blagajno",
            "place_order": "Oddaj naročilo",
            "title": "Vaša košarica",
            "total": "Skupaj"
          },
          "bill": {
            "go_to_payment": "Pojdi na plačilo",
            "outstanding": "Preostali znesek",
            "paid": "Plačano",
            "title": "Vaš račun",
            "total": "Skupaj"
          },
          "change_menu": "Spremeni meni",
          "checkout": {
            "email": "E-pošta",
            "ending_in": "Kartica se konča na %{last4}",
            "name": "Ime",
            "or": "ali",
            "pay": "Plačaj %{amount}",
            "previous_payment": "Prej uporabljeni kartici",
            "title": "Blagajna",
            "total": "Skupaj",
            "use": "Plačaj"
          },
          "choose_menu": "Izberite meni",
          "closed": "%{name} trenutno ne sprejema naročil.",
          "item": {
            "add_for": "Dodaj za %{price}",
            "choose_size": "Izberite velikost",
            "contains": "Vsebuje:",
            "increase_quantity": "Povečaj količino",
            "reduce_quantity": "Zmanjšaj količino",
            "select_at_least": "Izberite vsaj %{min} možnosti.",
            "select_one": "Izberite možnost."
          },
          "kcal_disclaimer": "Odrasli potrebujejo približno 2000 kcal na dan.",
          "no_menus": "Restavracija trenutno nima razpoložljivih menijev.",
          "payment_success": {
            "back_to_menu": "Nazaj na meni",
            "failed1": "Vaše plačilo ni uspelo.",
            "failed2": "Poskusite znova ali stopite v stik s osebjem restavracije.",
            "message": "Hvala za vaše plačilo.",
            "message_takeaway": "Vaše plačilo je bilo uspešno in vaše naročilo je bilo poslano v restavracijo.",
            "refunded1": "Oprostite, naročila ne moremo obdelati.",
            "refunded2": "Vaše plačilo je bilo vrnjeno.",
            "title": "Plačilo uspešno",
            "view_receipt": "Ogled računa",
            "waiting": "Čakanje na potrditev plačila"
          },
          "toast": {
            "bad_items": "Nekateri predmeti v vašem naročilu niso več na voljo in so bili odstranjeni",
            "location_closed": "Ta lokacija trenutno ne sprejema naročil",
            "order_placed": "Vaše naročilo je bilo oddano"
          },
          "view_basket": "Ogled košarice",
          "view_bill": "Ogled računa"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "sq": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selino",
        "cereals": "Gluten",
        "crustaceans": "Karkinoide",
        "dairy": "Produkte qumështi",
        "eggs": "Veze",
        "fish": "Peshk",
        "lupin": "Lupinë",
        "mustard": "Mustardë",
        "nuts": "Arra",
        "peanuts": "Kikirikë",
        "sesame": "Susam",
        "shellfish": "Fruta deti",
        "soy": "Soje",
        "sulphur": "Sulfitë"
      },
      "common": {
        "actions": "Actions",
        "back": "Prapa",
        "cancel": "Anulo",
        "confirm": "Konfirmo",
        "continue": "Vazhdo",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Pa hulumtime të lirë nga gluteni",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Jo-alkoolike",
        "vegan": "Vegan",
        "vegetarian": "Vegjetarian"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Shto artikuj nga menuja për të filluar",
            "empty_title": "Shporta jote është e zbrazët",
            "go_to_checkout": "Shko te pagesa",
            "place_order": "Vendos porosinë",
            "title": "Shporta jote",
            "total": "Totali"
          },
          "bill": {
            "go_to_payment": "Shko te pagesa",
            "outstanding": "Bilanc i pazbërthyer",
            "paid": "E paguar",
            "title": "Fatura jote",
            "total": "Totali"
          },
          "change_menu": "Ndrysho menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Karta duke u mbyllur në %{last4}",
            "name": "Emri",
            "or": "ose",
            "pay": "Paguaj %{amount}",
            "previous_payment": "Kartat e përdorura më parë",
            "title": "Pagesa",
            "total": "Totali",
            "use": "Paguaj"
          },
          "choose_menu": "Zgjidh një menu",
          "closed": "%{name} aktualisht nuk pranon porosi.",
          "item": {
            "add_for": "Shto për %{price}",
            "choose_size": "Zgjidh një madhësi",
            "contains": "Përmban:",
            "increase_quantity": "Shto sasi",
            "reduce_quantity": "Zvogëlo sasi",
            "select_at_least": "Ju lutemi zgjidhni të paktën %{min} opsione.",
            "select_one": "Ju lutemi zgjidhni një opsion."
          },
          "kcal_disclaimer": "Të rriturit nevojiten rreth 2000 kcal në ditë.",
          "no_menus": "Restorani aktualisht nuk ka menute të disponueshme.",
          "payment_success": {
            "back_to_menu": "Kthehu te menuja",
            "failed1": "Pagesa jote dështoi.",
            "failed2": "Ju lutemi provoni përsëri ose kontaktoni stafin e restoranit.",
            "message": "Faleminderit për pagesën tuaj.",
            "message_takeaway": "Pagesa juaj ishte e suksesshme dhe porosia juaj është dërguar te restorani.",
            "refunded1": "Na vjen keq, nuk mund të përpunojmë porosinë tuaj.",
            "refunded2": "Pagesa juaj është kthyer.",
            "title": "Pagesa e suksesshme",
            "view_receipt": "Shiko faturën",
            "waiting": "Duke pritur për konfirmimin e pagesës"
          },
          "toast": {
            "bad_items": "Disa artikuj në porosinë tuaj nuk janë më të disponueshëm dhe janë larguar",
            "location_closed": "Kjo vendndodhje aktualisht nuk pranon porosi",
            "order_placed": "Porosia juaj është vendosur"
          },
          "view_basket": "Shiko shportën",
          "view_bill": "Shiko faturën"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "sr": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Celer",
        "cereals": "Gluten",
        "crustaceans": "Rakovi",
        "dairy": "Mleko",
        "eggs": "Jaja",
        "fish": "Riba",
        "lupin": "Ljupin",
        "mustard": "Mlevena senfna zrna",
        "nuts": "Orasi",
        "peanuts": "Kikiriki",
        "sesame": "Susam",
        "shellfish": "Školjke",
        "soy": "Soja",
        "sulphur": "Sulfidi"
      },
      "common": {
        "actions": "Actions",
        "back": "Nazad",
        "cancel": "Otkaži",
        "confirm": "Potvrdi",
        "continue": "Nastavi",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Bez glutena",
        "halal": "Halal",
        "kosher": "Košer",
        "teetotal": "Bez alkohola",
        "vegan": "Vegansko",
        "vegetarian": "Vegetarijansko"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Dodajte stavke sa menija da biste počeli",
            "empty_title": "Vaša korpa je prazna",
            "go_to_checkout": "Idi na kasu",
            "place_order": "Naruči",
            "title": "Vaša korpa",
            "total": "Ukupno"
          },
          "bill": {
            "go_to_payment": "Idi na plaćanje",
            "outstanding": "Preostali iznos",
            "paid": "Plaćeno",
            "title": "Vaš račun",
            "total": "Ukupno"
          },
          "change_menu": "Promeni meni",
          "checkout": {
            "email": "Email",
            "ending_in": "Kartica koja se završava brojem %{last4}",
            "name": "Ime",
            "or": "ili",
            "pay": "Plati %{amount}",
            "previous_payment": "Prethodno korišćene kartice",
            "title": "Kasa",
            "total": "Ukupno",
            "use": "Plati"
          },
          "choose_menu": "Izaberite meni",
          "closed": "%{name} trenutno ne prihvata porudžbine.",
          "item": {
            "add_for": "Dodaj za %{price}",
            "choose_size": "Izaberite veličinu",
            "contains": "Sadrži:",
            "increase_quantity": "Povećaj količinu",
            "reduce_quantity": "Smanji količinu",
            "select_at_least": "Molimo izaberite barem %{min} opcija.",
            "select_one": "Molimo izaberite opciju."
          },
          "kcal_disclaimer": "Odrasle osobe trebaju oko 2000 kcal dnevno.",
          "no_menus": "Restoran trenutno nema dostupne menije.",
          "payment_success": {
            "back_to_menu": "Nazad na meni",
            "failed1": "Vaše plaćanje nije uspelo.",
            "failed2": "Molimo pokušajte ponovo ili kontaktirajte osoblje restorana.",
            "message": "Hvala vam na plaćanju.",
            "message_takeaway": "Vaše plaćanje je uspešno i vaša porudžbina je poslata restoranu.",
            "refunded1": "Žao nam je, nismo mogli da obradimo vašu porudžbinu.",
            "refunded2": "Vaše plaćanje je vraćeno.",
            "title": "Plaćanje uspešno",
            "view_receipt": "Pogledajte račun",
            "waiting": "Čekanje potvrde plaćanja"
          },
          "toast": {
            "bad_items": "Neki artikli u vašoj porudžbini više nisu dostupni i uklonjeni su",
            "location_closed": "Ova lokacija trenutno ne prihvata porudžbine",
            "order_placed": "Vaša porudžbina je uspešno završena"
          },
          "view_basket": "Pogledajte korpu",
          "view_bill": "Pogledajte račun"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "sv": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Selleri",
        "cereals": "Gluten",
        "crustaceans": "Kräftdjur",
        "dairy": "Mejeri",
        "eggs": "Ägg",
        "fish": "Fisk",
        "lupin": "Lupin",
        "mustard": "Senap",
        "nuts": "Nötter",
        "peanuts": "Jordnötter",
        "sesame": "Sesam",
        "shellfish": "Skaldjur",
        "soy": "Soja",
        "sulphur": "Sulfiter"
      },
      "common": {
        "actions": "Actions",
        "back": "Tillbaka",
        "cancel": "Avbryt",
        "confirm": "Bekräfta",
        "continue": "Fortsätt",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutenfri",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Alkoholfri",
        "vegan": "Vegan",
        "vegetarian": "Vegetarisk"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Lägg till objekt från menyn för att komma igång",
            "empty_title": "Din kundkorg är tom",
            "go_to_checkout": "Gå till kassan",
            "place_order": "Slutför beställning",
            "title": "Din kundkorg",
            "total": "Totalt"
          },
          "bill": {
            "go_to_payment": "Gå till betalning",
            "outstanding": "Återstående belopp",
            "paid": "Betald",
            "title": "Din faktura",
            "total": "Totalt"
          },
          "change_menu": "Ändra meny",
          "checkout": {
            "email": "E-post",
            "ending_in": "Kort som slutar på %{last4}",
            "name": "Namn",
            "or": "eller",
            "pay": "Betala %{amount}",
            "previous_payment": "Tidigare använda kort",
            "title": "Kassan",
            "total": "Totalt",
            "use": "Betala"
          },
          "choose_menu": "Välj en meny",
          "closed": "%{name} tar för närvarande inte emot beställningar",
          "item": {
            "add_for": "Lägg till för %{price}",
            "choose_size": "Välj en storlek",
            "contains": "Innehåller:",
            "increase_quantity": "Öka antal",
            "reduce_quantity": "Minska antal",
            "select_at_least": "Vänligen välj minst %{min} alternativ",
            "select_one": "Vänligen välj ett alternativ"
          },
          "kcal_disclaimer": "Vuxna behöver cirka 2000 kcal per dag.",
          "no_menus": "Restaurangen har för närvarande inga tillgängliga menyer",
          "payment_success": {
            "back_to_menu": "Tillbaka till menyn",
            "failed1": "Din betalning misslyckades",
            "failed2": "Försök igen eller kontakta restaurangpersonalen",
            "message": "Tack för din betalning",
            "message_takeaway": "Din betalning var framgångsrik och din beställning har skickats till restaurangen",
            "refunded1": "Tyvärr kunde vi inte behandla din beställning",
            "refunded2": "Din betalning har återbetalats",
            "title": "Betalning lyckades",
            "view_receipt": "Visa kvitto",
            "waiting": "Väntar på betalningsbekräftelse"
          },
          "toast": {
            "bad_items": "Vissa objekt i din beställning är inte längre tillgängliga och har tagits bort",
            "location_closed": "Denna plats tar för närvarande inte emot beställningar",
            "order_placed": "Din beställning har lagts"
          },
          "view_basket": "Visa kundkorg",
          "view_bill": "Visa faktura"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "tr": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Kereviz",
        "cereals": "Glüten",
        "crustaceans": "Kabuklu deniz hayvanları",
        "dairy": "Süt ürünleri",
        "eggs": "Yumurta",
        "fish": "Balık",
        "lupin": "Lupin",
        "mustard": "Hardal",
        "nuts": "Fındık",
        "peanuts": "Fıstık",
        "sesame": "Susam",
        "shellfish": "Kabuklu deniz hayvanları",
        "soy": "Soya",
        "sulphur": "Sülfür dioksit"
      },
      "common": {
        "actions": "Actions",
        "back": "Geri",
        "cancel": "İptal",
        "confirm": "Onayla",
        "continue": "Devam",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Glutensiz",
        "halal": "Helal",
        "kosher": "Koşer",
        "teetotal": "Alkolsüz",
        "vegan": "Vegan",
        "vegetarian": "Vejetaryen"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Başlamak için menüden ürün ekleyin",
            "empty_title": "Sepetiniz boş",
            "go_to_checkout": "Ödemeye git",
            "place_order": "Sipariş ver",
            "title": "Sepetiniz",
            "total": "Toplam"
          },
          "bill": {
            "go_to_payment": "Ödemeye git",
            "outstanding": "Ödenmemiş bakiye",
            "paid": "Ödenen",
            "title": "Faturanız",
            "total": "Toplam"
          },
          "change_menu": "Menüyü değiştir",
          "checkout": {
            "email": "E-posta",
            "ending_in": "%{last4} ile biten kart",
            "name": "İsim",
            "or": "veya",
            "pay": "Ödeme Yap %{amount}",
            "previous_payment": "Daha önce kullanılan kartlar",
            "title": "Ödeme",
            "total": "Toplam",
            "use": "Öde"
          },
          "choose_menu": "Menü seç",
          "closed": "%{name} şu anda sipariş kabul etmiyor.",
          "item": {
            "add_for": "%{price} için ekle",
            "choose_size": "Bir boyut seç",
            "contains": "İçindekiler:",
            "increase_quantity": "Miktarı artır",
            "reduce_quantity": "Miktarı azalt",
            "select_at_least": "Lütfen en az %{min} seçenek belirleyin.",
            "select_one": "Lütfen bir seçenek belirleyin."
          },
          "kcal_disclaimer": "Yetişkinlerin günlük olarak yaklaşık 2000 kaloriye ihtiyacı vardır.",
          "no_menus": "Restoran şu anda mevcut menü sunmamaktadır.",
          "payment_success": {
            "back_to_menu": "Menüye geri dön",
            "failed1": "Ödemeniz başarısız oldu.",
            "failed2": "Lütfen tekrar deneyin veya restoran personeline başvurun.",
            "message": "Ödemeniz için teşekkürler.",
            "message_takeaway": "Ödemeniz başarılı oldu ve siparişiniz restorana iletilmiştir.",
            "refunded1": "Üzgünüz, siparişinizi işleme koyamadık.",
            "refunded2": "Ödemeniz iade edildi.",
            "title": "Ödeme başarılı",
            "view_receipt": "Fişi görüntüle",
            "waiting": "Ödeme onayı bekleniyor"
          },
          "toast": {
            "bad_items": "Siparişinizdeki bazı ürünler artık mevcut değil ve çıkarıldı",
            "location_closed": "Bu konum şu anda sipariş kabul etmiyor",
            "order_placed": "Siparişiniz alındı"
          },
          "view_basket": "Sepeti görüntüle",
          "view_bill": "Faturayı görüntüle"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "uk": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Селера",
        "cereals": "Глютен",
        "crustaceans": "Ракоподібні",
        "dairy": "Молоко",
        "eggs": "Яйця",
        "fish": "Риба",
        "lupin": "Люпін",
        "mustard": "Гірчиця",
        "nuts": "Горіхи",
        "peanuts": "Арахіс",
        "sesame": "Кунжут",
        "shellfish": "Молюски",
        "soy": "Соя",
        "sulphur": "Сульфіти"
      },
      "common": {
        "actions": "Actions",
        "back": "Назад",
        "cancel": "Скасувати",
        "confirm": "Підтвердити",
        "continue": "Продовжити",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Безглютеновий",
        "halal": "Халяль",
        "kosher": "Кошерний",
        "teetotal": "Безалкогольний",
        "vegan": "Веганський",
        "vegetarian": "Вегетаріанський"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Додайте елементи з меню, щоб почати",
            "empty_title": "Ваш кошик порожній",
            "go_to_checkout": "Перейти до оформлення замовлення",
            "place_order": "Зробити замовлення",
            "title": "Ваш кошик",
            "total": "Загальна сума"
          },
          "bill": {
            "go_to_payment": "Перейти до оплати",
            "outstanding": "Залишок до сплати",
            "paid": "Сплачено",
            "title": "Ваш рахунок",
            "total": "Усього"
          },
          "change_menu": "Змінити меню",
          "checkout": {
            "email": "Електронна пошта",
            "ending_in": "Карта, закінчується на %{last4}",
            "name": "Ім'я",
            "or": "або",
            "pay": "Оплатити %{amount}",
            "previous_payment": "Користувані раніше картки",
            "title": "Оформлення замовлення",
            "total": "Усього",
            "use": "Оплатити"
          },
          "choose_menu": "Виберіть меню",
          "closed": "%{name} наразі не приймає замовлення.",
          "item": {
            "add_for": "Додати до %{price}",
            "choose_size": "Вибрати розмір",
            "contains": "Містить:",
            "increase_quantity": "Збільшити кількість",
            "reduce_quantity": "Зменшити кількість",
            "select_at_least": "Будь ласка, виберіть принаймні %{min} варіанти.",
            "select_one": "Будь ласка, виберіть один варіант."
          },
          "kcal_disclaimer": "Дорослим потрібно близько 2000 ккал на день.",
          "no_menus": "У ресторану наразі немає доступних меню.",
          "payment_success": {
            "back_to_menu": "Повернутися до меню",
            "failed1": "Ваш платіж не вдалося.",
            "failed2": "Спробуйте ще раз або зв'яжіться з персоналом ресторану.",
            "message": "Дякуємо за оплату.",
            "message_takeaway": "Ваш платіж пройшов успішно, і ваше замовлення відправлено в ресторан.",
            "refunded1": "Вибачте, ми не змогли обробити ваше замовлення.",
            "refunded2": "Ваш платіж був повернутий.",
            "title": "Оплата успішна",
            "view_receipt": "Переглянути квитанцію",
            "waiting": "Очікування підтвердження оплати"
          },
          "toast": {
            "bad_items": "Деякі елементи у вашому замовленні більше не доступні і були видалені",
            "location_closed": "Ця локація наразі не приймає замовлення",
            "order_placed": "Ваше замовлення прийнято"
          },
          "view_basket": "Переглянути кошик",
          "view_bill": "Переглянути рахунок"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "vi": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "Cần tây",
        "cereals": "Gluten",
        "crustaceans": "Tôm cua",
        "dairy": "Sữa và sản phẩm từ sữa",
        "eggs": "Trứng",
        "fish": "Cá",
        "lupin": "Đậu lăng",
        "mustard": "Mù tạc",
        "nuts": "Hạt",
        "peanuts": "Đậu phộng",
        "sesame": "Mè",
        "shellfish": "Hải sản có vỏ",
        "soy": "Đậu nành",
        "sulphur": "Sunphit"
      },
      "common": {
        "actions": "Actions",
        "back": "Quay lại",
        "cancel": "Hủy bỏ",
        "confirm": "Xác nhận",
        "continue": "Tiếp tục",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "Không chứa gluten",
        "halal": "Halal",
        "kosher": "Kosher",
        "teetotal": "Không cồn",
        "vegan": "Thực dưỡng",
        "vegetarian": "Ăn chay"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "Thêm mục từ menu để bắt đầu",
            "empty_title": "Giỏ hàng của bạn đang trống",
            "go_to_checkout": "Đi đến thanh toán",
            "place_order": "Đặt hàng",
            "title": "Giỏ hàng của bạn",
            "total": "Tổng cộng"
          },
          "bill": {
            "go_to_payment": "Đi đến thanh toán",
            "outstanding": "Dư nợ chưa thanh toán",
            "paid": "Đã thanh toán",
            "title": "Hóa đơn của bạn",
            "total": "Tổng cộng"
          },
          "change_menu": "Thay đổi menu",
          "checkout": {
            "email": "Email",
            "ending_in": "Thẻ kết thúc bằng %{last4}",
            "name": "Tên",
            "or": "hoặc",
            "pay": "Thanh toán %{amount}",
            "previous_payment": "Thẻ đã sử dụng trước đó",
            "title": "Thanh toán",
            "total": "Tổng cộng",
            "use": "Thanh toán"
          },
          "choose_menu": "Chọn một menu",
          "closed": "%{name} hiện không chấp nhận đơn hàng.",
          "item": {
            "add_for": "Thêm cho %{price}",
            "choose_size": "Chọn kích thước",
            "contains": "Bao gồm:",
            "increase_quantity": "Tăng số lượng",
            "reduce_quantity": "Giảm số lượng",
            "select_at_least": "Vui lòng chọn ít nhất %{min} tùy chọn.",
            "select_one": "Vui lòng chọn một tùy chọn."
          },
          "kcal_disclaimer": "Người lớn cần khoảng 2000 kcal mỗi ngày.",
          "no_menus": "Nhà hàng hiện không có menu nào khả dụng.",
          "payment_success": {
            "back_to_menu": "Quay lại menu",
            "failed1": "Thanh toán của bạn không thành công.",
            "failed2": "Vui lòng thử lại hoặc liên hệ với nhân viên nhà hàng.",
            "message": "Cám ơn bạn đã thanh toán.",
            "message_takeaway": "Thanh toán của bạn thành công và đơn hàng đã được gửi đến nhà hàng.",
            "refunded1": "Xin lỗi, chúng tôi không thể xử lý đơn hàng của bạn.",
            "refunded2": "Thanh toán của bạn đã được hoàn trả.",
            "title": "Thanh toán thành công",
            "view_receipt": "Xem biên lai",
            "waiting": "Đang chờ xác nhận thanh toán"
          },
          "toast": {
            "bad_items": "Một số mục trong đơn hàng của bạn không còn khả dụng và đã bị xóa",
            "location_closed": "Vị trí này hiện không chấp nhận đơn hàng",
            "order_placed": "Đơn hàng của bạn đã được đặt"
          },
          "view_basket": "Xem giỏ hàng",
          "view_bill": "Xem hóa đơn"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "zh-Hans": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "芹菜",
        "cereals": "麸质",
        "crustaceans": "甲壳类动物",
        "dairy": "乳制品",
        "eggs": "鸡蛋",
        "fish": "鱼类",
        "lupin": "罗勒",
        "mustard": "芥末",
        "nuts": "坚果",
        "peanuts": "花生",
        "sesame": "芝麻",
        "shellfish": "贝类",
        "soy": "大豆",
        "sulphur": "亚硫酸盐"
      },
      "common": {
        "actions": "Actions",
        "back": "返回",
        "cancel": "取消",
        "confirm": "确认",
        "continue": "继续",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "无麸质",
        "halal": "清真",
        "kosher": "犹太教食规",
        "teetotal": "无酒精",
        "vegan": "纯素",
        "vegetarian": "素食者"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "从菜单中添加项目以开始",
            "empty_title": "您的购物篮为空",
            "go_to_checkout": "去结账",
            "place_order": "下订单",
            "title": "您的购物篮",
            "total": "总计"
          },
          "bill": {
            "go_to_payment": "前往付款",
            "outstanding": "未结余额",
            "paid": "已支付",
            "title": "您的账单",
            "total": "总计"
          },
          "change_menu": "更改菜单",
          "checkout": {
            "email": "电子邮件",
            "ending_in": "卡片结尾为%{last4}",
            "name": "姓名",
            "or": "或",
            "pay": "支付 %{amount}",
            "previous_payment": "之前使用过的卡片",
            "title": "结账",
            "total": "总计",
            "use": "支付"
          },
          "choose_menu": "选择菜单",
          "closed": "%{name}目前不接受订单。",
          "item": {
            "add_for": "为%{price}添加",
            "choose_size": "选择尺寸",
            "contains": "包含：",
            "increase_quantity": "增加数量",
            "reduce_quantity": "减少数量",
            "select_at_least": "请至少选择%{min}个选项。",
            "select_one": "请选择一个选项。"
          },
          "kcal_disclaimer": "成人每天需要大约2000卡路里。",
          "no_menus": "餐厅目前没有可用菜单。",
          "payment_success": {
            "back_to_menu": "返回菜单",
            "failed1": "您的付款未成功。",
            "failed2": "请重试或联系餐厅工作人员。",
            "message": "感谢您的付款。",
            "message_takeaway": "您的付款成功，订单已发送至餐厅。",
            "refunded1": "抱歉，我们无法处理您的订单。",
            "refunded2": "您的付款已退款。",
            "title": "付款成功",
            "view_receipt": "查看收据",
            "waiting": "等待付款确认"
          },
          "toast": {
            "bad_items": "您订单中的一些商品已不可用并已被移除",
            "location_closed": "此位置暂时不接受订单",
            "order_placed": "您的订单已下达"
          },
          "view_basket": "查看篮子",
          "view_bill": "查看账单"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  },
  "zh-Hant": {
    "common": {
      "actions": "Actions",
      "active": "Active",
      "add": "Add",
      "and_x_more": "and %{count} more",
      "any": "Any",
      "archive": "Archive",
      "archived": "Archived",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "choose": "Choose",
      "close": "Close",
      "closed": "Closed",
      "confirm": "Confirm",
      "confirm_delete": "Are you sure?",
      "continue": "Continue",
      "copyright_statement_html": "&copy;&nbsp;2023 qoda.ly",
      "delete": "Delete",
      "details": "Details",
      "do_not_refresh_page": "Do not refresh or close page.",
      "edit": "Edit",
      "form_error_html": "Some of the submitted data is invalid. Please check the form for errors and try again. If the issue persists, <a class=\"font-semibold underline\" href=\"mailto:help@qoda.ly\">contact customer support</a>.",
      "learn_more": "Learn more",
      "minutes": "Minutes",
      "never": "Never",
      "no": "No",
      "none": "None",
      "not_active": "Not active",
      "not_provided": "Not provided",
      "processing": "Processing",
      "remove": "Remove",
      "restore": "Restore",
      "save": "Save",
      "selected": "Selected",
      "service_types": {
        "at_table": "At table",
        "delivery": "Delivery",
        "takeaway": "Takeaway"
      },
      "settings_saved": "The settings have been saved.",
      "show": "Show",
      "talk_to_an_expert": "Talk to an expert",
      "view": "View",
      "yes": "Yes"
    },
    "js": {
      "allergens": {
        "celery": "芹菜",
        "cereals": "麩質",
        "crustaceans": "甲殼類",
        "dairy": "乳製品",
        "eggs": "雞蛋",
        "fish": "魚類",
        "lupin": "羽扇豆",
        "mustard": "芥末",
        "nuts": "堅果",
        "peanuts": "花生",
        "sesame": "芝麻",
        "shellfish": "貝類",
        "soy": "大豆",
        "sulphur": "亞硫酸鹽"
      },
      "common": {
        "actions": "Actions",
        "back": "返回",
        "cancel": "取消",
        "confirm": "確認",
        "continue": "繼續",
        "delete": "Delete",
        "save": "Save"
      },
      "dietary_preferences": {
        "gluten_free": "無麩質",
        "halal": "清真",
        "kosher": "猶太食法",
        "teetotal": "無酒精",
        "vegan": "純素",
        "vegetarian": "素食"
      },
      "guest": {
        "ordering": {
          "basket": {
            "empty_text": "從菜單中添加商品開始",
            "empty_title": "您的購物籃是空的",
            "go_to_checkout": "前往結帳",
            "place_order": "下訂單",
            "title": "您的購物籃",
            "total": "總金額"
          },
          "bill": {
            "go_to_payment": "前往付款",
            "outstanding": "未付餘額",
            "paid": "已支付",
            "title": "您的帳單",
            "total": "總金額"
          },
          "change_menu": "變更菜單",
          "checkout": {
            "email": "電子郵件",
            "ending_in": "卡片結尾為 %{last4}",
            "name": "姓名",
            "or": "或",
            "pay": "支付 %{amount}",
            "previous_payment": "先前使用過的卡片",
            "title": "結帳",
            "total": "總金額",
            "use": "支付"
          },
          "choose_menu": "選擇菜單",
          "closed": "%{name}目前不接受訂單。",
          "item": {
            "add_for": "為%{price}添加",
            "choose_size": "選擇尺寸",
            "contains": "包含:",
            "increase_quantity": "增加數量",
            "reduce_quantity": "減少數量",
            "select_at_least": "請至少選擇%{min}個選項。",
            "select_one": "請選擇一個選項。"
          },
          "kcal_disclaimer": "成人每天需要約2000卡路里。",
          "no_menus": "餐廳目前沒有可用的菜單。",
          "payment_success": {
            "back_to_menu": "返回菜單",
            "failed1": "您的付款未成功。",
            "failed2": "請重試或聯繫餐廳工作人員。",
            "message": "感謝您的付款。",
            "message_takeaway": "您的付款已成功，訂單已發送到餐廳。",
            "refunded1": "抱歉，我們無法處理您的訂單。",
            "refunded2": "您的付款已退款。",
            "title": "付款成功",
            "view_receipt": "查看收據",
            "waiting": "等待付款確認"
          },
          "toast": {
            "bad_items": "您訂單中的某些商品已不再提供，已被移除",
            "location_closed": "此地點目前不接受訂單",
            "order_placed": "您的訂單已下訂"
          },
          "view_basket": "查看購物籃",
          "view_bill": "查看帳單"
        }
      },
      "hub": {
        "admin": {
          "inventory": {
            "taking_sheets": {
              "form": {
                "add": {
                  "category": "Add all items from a category",
                  "category_label": "Choose a category",
                  "item_label": "Choose a product",
                  "single_item": "Add a single item",
                  "title": "Add products"
                },
                "add_items": "Add products",
                "fieldsets": {
                  "basic": {
                    "title": "Basic information"
                  },
                  "items": {
                    "description": "Enter the items that need to be counted in this inventory sheet. Changes are saved automatically.",
                    "title": "Items"
                  }
                },
                "hints": {
                  "name": "The name of the inventory sheet"
                },
                "labels": {
                  "name": "Name"
                },
                "no_items": "You haven't added any items to this inventory sheet yet."
              },
              "table": {
                "name": "Name",
                "stock_increment": "Inventory unit"
              }
            }
          }
        },
        "bill": {
          "payment": {
            "amount": "Amount",
            "balance": "Balance",
            "card": "Card",
            "failure": "Payment failed",
            "no_terminals": "No card readers available",
            "pick_terminal": "Select a card reader",
            "split_item": "Pick items to pay for",
            "split_person": "Split evenly",
            "success": "Payment successful",
            "terminal_offline": "The card reader is offline",
            "tip": "Tip",
            "try_again": "Try again",
            "waiting_for_card": "Waiting for card"
          },
          "split_person": {
            "out_of_post": "at the table",
            "out_of_pre": "out of",
            "paying_for_post_one": "person",
            "paying_for_post_other": "people",
            "paying_for_pre": "Paying for",
            "to_split": "Splitting"
          }
        },
        "code_assigner": {
          "error": "An error occurred: %{error}",
          "invalid_qr_code": "This QR code is not a valid table code.",
          "success_end": "Great job! All tables have been assigned a QR code.",
          "success_next": "Got it! Moving to table %{number}."
        },
        "common": {
          "are_you_sure": "Are you sure?",
          "back": "Back",
          "cancel": "Cancel",
          "close": "Close",
          "confirm": "Confirm",
          "edit": "Edit",
          "processing": "Processing",
          "save": "Save"
        },
        "kitchen": {
          "exit": "Exit",
          "preparing": "Preparing",
          "ready": "Ready",
          "todo": "To do"
        },
        "opening_hours": {
          "add_hours": "Add hours",
          "close": "Close",
          "closed": "Closed",
          "confirm": "Confirm",
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "error": "The opening hours you entered do not represent a valid time range. Please check the times and try again.",
          "open": "Open",
          "remove": "Remove"
        },
        "ordering": {
          "add": "Add",
          "add_button": "Add for %{amount}",
          "add_more": "Add more",
          "balance": "Balance",
          "confirm": "Confirm order",
          "confirm_takeout": {
            "confirm": "Confirm",
            "email": "Customer email",
            "name": "Customer name",
            "optional": "(optional)",
            "phone": "Customer phone",
            "title": "Confirm your order"
          },
          "contains": "Contains: ",
          "course": "Course",
          "decrease": "Decrese quantity",
          "increase": "Increase quantity",
          "menus": "Menus",
          "notes": "Notes",
          "open_item_name": "Name",
          "open_item_notes": "Notes",
          "open_item_price": "Price",
          "payments": "Payments",
          "placed_at": "Placed at %{time}",
          "please_select_at_least": {
            "one": "Please select at least one option",
            "other": "Please select at least %{count} options"
          },
          "please_select_no_more_than": {
            "one": "Please select no more than one option",
            "other": "Please select no more than %{count} options"
          },
          "remove": "Remove",
          "seated_at": "Seated at %{time}",
          "size": "Size",
          "tips": "Tips",
          "total": "Total"
        },
        "payment_splitter": {
          "amount": "Payment amount",
          "cancel": "Cancel payment",
          "collect": "Collect payment",
          "each": "each",
          "include_service": "Include service (%{amount})",
          "minimum": "The minimum amount is %{amount}",
          "paid": "paid",
          "title": "Paying for"
        },
        "readers": {
          "cancel_pending_payment": "Cancel pending payment",
          "could_not_collect": "Could not collect payment method. Please reconnect to the terminal.",
          "minimum_payment_amount": "The minimum payment amount is €0.50",
          "payment_failed": "Payment was not taken successfully. Please try again.",
          "select_reader": "Select terminal",
          "waiting_for_card": "Waiting for card (click to cancel)"
        },
        "refunds": {
          "custom": {
            "description": "How much do you want to refund?",
            "limit": "Max: %{amount}",
            "title": "Custom amount refund"
          },
          "summary": {
            "available": "Out of %{amount}",
            "kind": {
              "card": "Card",
              "cash": "Cash",
              "complimentary": "Complimentary"
            },
            "not_enough_allocated": "Please add %{amount} to the above allocations",
            "title": "Review",
            "title_allocate": "Please allocate %{amount} to the following payments:",
            "title_full": "The following amounts will be fully refunded:",
            "too_much_allocated": "Please remove %{amount} from the above allocations",
            "too_much_single": "You cannot refund more than the original payment"
          },
          "type": {
            "custom": "A custom amount",
            "custom_desc": "If you want to refund a specific amount.",
            "entire_bill": "The entire bill",
            "entire_bill_desc": {
              "one": "%{amount} will be refunded to one payment.",
              "other": "%{amount} will be refunded to %{count} payments."
            },
            "title": "What do you want to refund?"
          }
        },
        "requests": {
          "payment": "Wants to pay",
          "waiter": "Asked for a waiter",
          "water": "Wants tap water"
        },
        "stock_take": {
          "products": {
            "count_by": "Count by",
            "last_purchase": "Last purchase",
            "name": "Name",
            "stock": "Stock",
            "total_value": "Total value",
            "unit_value": "Value"
          },
          "submit": "Submit",
          "total_value": "Total value"
        }
      }
    },
    "units": {
      "cup": "Cup",
      "each": "Each",
      "fl_oz": "Fluid Ounce",
      "g": "Grams",
      "gal": "Gallon",
      "kg": "Kilograms",
      "l": "Litres",
      "lb": "Pounds",
      "mg": "Milligrams",
      "ml": "Millilitres",
      "oz": "Ounces",
      "pint": "Pint",
      "quart": "Quart",
      "tbsp": "Tablespoon",
      "tsp": "Teaspoon",
      "us_cup": "Cup (US)",
      "us_fl_oz": "Fluid Ounce (US)",
      "us_gal": "Gallon (US)",
      "us_pint": "Pint (US)",
      "us_quart": "Quart (US)",
      "us_tbsp": "Tablespoon (US)",
      "us_tsp": "Teaspoon (US)"
    }
  }
}