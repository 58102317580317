import { useContext } from "react"
import { CurrencyContext } from "./CurrencyContext"

export default function Price({ cents }: { cents: number }) {
  const { iso, subunits } = useContext(CurrencyContext)

  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: iso,
  })

  return formatter.format(cents / subunits)
}
