import React from "react"
import Button from "@react-shared/Button"
import { Product } from "../../graphql/graphql"
import deleteConfirm from "./DeleteConfirm"
import { useMutation } from "@apollo/client"
import { removeTakingSheetItemMutation } from "../../mutations"

import { t } from "../../i18n"

type Props = {
  product: Product
  locationId: string
  takingSheetId: string
  refetch: () => void
}

export default function ProductRow({ product, locationId, takingSheetId, ...props }: Props) {
  const [remove, { loading, error }] = useMutation(removeTakingSheetItemMutation, {
    variables: {
      locationId,
      takingSheetId,

      id: product.id,
    },
  })

  const onDelete = async () => {
    const result = await deleteConfirm()
    if (result) {
      remove().then(() => props.refetch())
    }
  }

  return (
    <tr key={product.id} className={"even:bg-gray-100 odd:bg-white"}>
      <td className={"px-3 py-2"}>{product.name}</td>
      <td className={"px-3 py-2"}>
        {(product.unitOfMeasure === "EACH" &&
          ((product.eachName &&
            `${product.eachName}` +
              (product.stockIncrement != 1 ? ` (${product.stockIncrement})` : "")) ||
            `${product.stockIncrement}`)) ||
          `${product.stockIncrement} ${unitName(product.unitOfMeasure)}`}
      </td>
      <td className={"px-3 py-2 text-end"}>
        <Button kind={"danger"} size={"xsmall"} loading={loading} onClick={() => onDelete()}>
          {t("js.common.delete")}
        </Button>
      </td>
    </tr>
  )
}

function unitName(key: string): string {
  return t("units." + key.toLowerCase()).toLowerCase()
}
