import React, { useEffect } from "react"
import UnsavedAlert from "./UnsavedAlert"

type Props = {
  children: React.ReactNode
  action?: string
  method?: "get" | "post" | "put" | "delete" | "patch"
} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export default function Form({ children, action, method, ...rest }: Props) {
  const [dirty, setDirty] = React.useState(false)

  const csrfToken = document.querySelector("meta[name=csrf-token]")?.getAttribute("content")!

  method = method || "post"

  return (
    <form
      method={method === "get" ? "get" : "post"}
      action={action || ""}
      onChange={() => setDirty(true)}
      onSubmit={() => setDirty(false)}
      {...rest}>
      <UnsavedAlert enabled={dirty} />
      <input type="hidden" name="authenticity_token" value={csrfToken} autoComplete="off" />
      {method !== "get" && <input type="hidden" name="_method" value={method} />}
      {children}
    </form>
  )
}
