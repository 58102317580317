import { gql } from "../graphql"

export const getTakingSheetItems = gql(`
  query getTakingSheetItems($locationId: ID!, $takingSheetId: ID!) {
    inventoryTakingSheet(locationId: $locationId, id: $takingSheetId) {
      items {
        __typename
        ... on Preparation {
          id
          name
          batchSize
          unitOfMeasure
        }
        ... on Product {
          id
          name
          stockIncrement
          unitOfMeasure
          eachName
          category {
            id name
          }
        }
      }
    }
  }`
)

export const getStockTakeItems = gql(`
  query getStockTake($locationId: ID!, $stockTakeId: ID!) {
    inventoryStockTake(locationId: $locationId, id: $stockTakeId) {
      items {
        id
        quantity
        unitCostCents
        item {
          __typename
          ... on Preparation {
            id
            name
            batchSize
            unitOfMeasure
          }
          ... on Product {
            id
            name
            stockIncrement
            unitOfMeasure
            eachName
            category {
              id name
            }
          }
        }
      }
    }
  }`
)

export const getInventoryCategories = gql(`
  query getInventoryCategories {
    inventoryCategories(onlyChildren: true) {
      id
      name
    }
  }`
)

export const addItemToInventoryTakingSheet = gql(`
  mutation addItemToInventoryTakingSheet($locationId: ID!, $takingSheetId: ID!, $itemId: ID!) {
    addItemToInventoryTakingSheet(input: { locationId: $locationId, takingSheetId: $takingSheetId, itemId: $itemId }) {
      ok
    }
  }`
)

export const addCategoryToInventoryTakingSheet = gql(`
  mutation addCategoryToInventoryTakingSheet($locationId: ID!, $takingSheetId: ID!, $categoryId: ID!) {
    addCategoryToInventoryTakingSheet(input: { locationId: $locationId, takingSheetId: $takingSheetId, categoryId: $categoryId }) {
      ok
    }
  }`
)

export const removeTakingSheetItemMutation = gql(`
  mutation removeInventoryTakingSheetItem($locationId: ID!, $takingSheetId: ID!, $id: ID!) {
    removeInventoryTakingSheetItem(input: { locationId: $locationId, takingSheetId: $takingSheetId, id: $id }) {
      ok
    }
  }`
)

export const inventorySearch = gql(`
  query inventorySearch($query: String!) {
    inventorySearch(query: $query) {
      __typename
      ... on Preparation {
        id
        name
        batchSize
        unitOfMeasure
      }
      ... on Product {
        id
        name
        stockIncrement
        unitOfMeasure
        eachName
        category {
          id name
        }
      }
    }
  }`
)

export const updateStockTakeItem = gql(`
  mutation updateStockTakeItem(
    $locationId: ID!,
    $stockTakeId: ID!,
    $id: ID!,
    $quantity: Float,
    $unitCostCents: Int
  ) {
    updateStockTakeItem(
      input: {
        locationId: $locationId,
        stockTakeId: $stockTakeId,
        stockTakeItemId: $id,
        quantity: $quantity,
        unitCostCents: $unitCostCents
      }
    ) {
      id
      quantity
      unitCostCents
    }
  }`
)


export const submitStockTake = gql(`
  mutation submitStockTake(
    $locationId: ID!,
    $id: ID!
  ) {
    submitStockTake(
      input: {
        locationId: $locationId,
        stockTakeId: $id
      }
    ) {
      ok
    }
  }`
)