import React, { useState, useContext, useEffect } from "react"

import private_api from "@private-api/index"
import { useNavigate, useParams } from "react-router"
import AppContext from "./AppContext"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import Waiting from "@react-shared/Icons/Waiting"
import Button from "@react-shared/Button"
import Success from "@react-shared/Icons/Success"
import Failure from "@react-shared/Icons/Failure"

import { t } from "../../i18n"

export default function TerminalWait() {
  const { id } = useParams()
  const { amount, tip, bill, selectedItems, payingQuotas, newGuests } = useContext(AppContext)
  const { iso } = useContext(CurrencyContext)
  const navigate = useNavigate()

  const [gatewayId, setGatewayId] = useState<string | null>(null)
  const [paymentStatus, setPaymentStatusInner] = useState<"pending" | "success" | "failure">("pending")
  const [terminalOffline, setTerminalOffline] = useState(false)

  // We're accessing state in an event listener, so we need to use a ref
  const payStatusRef = React.useRef(paymentStatus);
  const setPaymentStatus = (status: typeof paymentStatus) => {
    payStatusRef.current = status
    setPaymentStatusInner(status)
  }

  useEffect(() => {
    const interval = setInterval(checkStatus, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [gatewayId])

  function cancelBeacon(e: Event) {
    if (e.type !== "visibilitychange" || document.visibilityState === "hidden") {
      if (payStatusRef.current === "pending" && id) {
        private_api.Hub.CardReaders.CancelPaymentWithBeacon(id!)
      }
      navigate("/")
    }
  }

  function checkStatus() {
    if (!gatewayId) {
      return
    }

    private_api.Hub.Bills.RetrieveTerminalIntent(gatewayId).then(({ status }) => {
      if (status === "succeeded") {
        setPaymentStatus("success")
        setTimeout(() => {
          location.reload()
        }, 2000)
      } else if (status === "failed") {
        setPaymentStatus("failure")
      }
    })
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", cancelBeacon)
    document.addEventListener("beforeunload", cancelBeacon)
    document.addEventListener("unload", cancelBeacon)
    document.addEventListener("pagehide", cancelBeacon)

    private_api.Hub.Bills.CreateTerminalIntent(bill.id, {
      amount_cents: amount || 0,
      tip_cents: tip,
      currency_iso: iso,
      paying_items: selectedItems,
      paying_quotas: payingQuotas,
      new_guests: newGuests,
    })
    .then(intent => private_api.Hub.CardReaders.Offload(id!, intent.id))
    .then(result => {
      if (result.success) {
        setGatewayId(result.gateway_id)
      } else {
        if (result.code === "terminal_reader_offline") {
          setTerminalOffline(true)
        }
        setPaymentStatus("failure")
      }
    }).catch(() => {
      setPaymentStatus("failure")
    })

    return () => {
      document.removeEventListener("visibilitychange", cancelBeacon)

      // Cancel the payment intent
      if (payStatusRef.current === "pending" && id) {
        private_api.Hub.CardReaders.CancelPayment(id!)
      }
    }
  }, [])

  switch (paymentStatus) {
    case "pending":
      return (
        <>
          <div className="text-xl font-bold text-center">{t("js.hub.bill.payment.waiting_for_card")}</div>
          <div className="text-center mx-auto">
            <Waiting height={96} className="mx-auto my-6" />
          </div>
          <Button kind="warning" onClick={() => {
            private_api.Hub.CardReaders.CancelPayment(id!)
            navigate("/")
          }}>{t("js.common.cancel")}</Button>
        </>
      )
    case "success":
      return (
        <>
          <div className="text-xl font-bold text-center">{t("js.hub.bill.payment.success")}</div>
          <div className="text-center mx-auto">
            <Success height={96} className="mx-auto my-6 text-green-600" />
          </div>
        </>
      )
    case "failure":
      return (
        <>
          <div className="text-xl font-bold text-center">{t("js.hub.bill.payment.failure")}</div>
          {terminalOffline && <div className="text-center mx-auto text-red-600">{t("js.hub.bill.payment.terminal_offline")}</div>}
          <div className="text-center mx-auto">
            <Failure height={96} className="mx-auto my-6 text-red-600" />
          </div>
          <div className="flex justify-center space-x-4">
            <Button kind="plain" onClick={() => navigate("/")}>{t("js.common.back")}</Button>
            <Button kind="primary" onClick={() => { navigate(`/terminals`) }}>{t("js.hub.bill.payment.try_again")}</Button>
          </div>
        </>
      )
  }
}
