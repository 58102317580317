import { baseUrl, request } from "@private-api/request"

type IssueRefundParams = {
  refunds: { payment_id: string, amount: number }[]
}

export function IssueRefund(billId: string, params: IssueRefundParams) {
  const url = `${baseUrl()}/hub/bills/${billId}/refunds`
  const method = "POST"

  return request<null, IssueRefundParams>(url, method, params)
}

type CreateTerminalIntentParams = {
  amount_cents: number
  tip_cents: number
  currency_iso: string
  paying_items?: { [key: string]: number[] },
  paying_quotas?: number,
  new_guests?: number
}

export function CreateTerminalIntent(billId: string, params: CreateTerminalIntentParams) {
  const url = `${baseUrl()}/hub/bills/${billId}/terminal-intents`
  const method = "POST"

  return request<{ id: string }, CreateTerminalIntentParams>(url, method, params)
}

export function RetrieveTerminalIntent(terminalIntentId: string) {
  const url = `${baseUrl()}/hub/terminal-intents/${terminalIntentId}`
  const method = "GET"

  return request<{ status: string }, null>(url, method)
}

export default {
  CreateTerminalIntent,
  IssueRefund,
  RetrieveTerminalIntent
}
