import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountAdmin", "other"]

  declare accountAdminTarget: HTMLInputElement
  declare otherTargets: HTMLInputElement[]

  connect() {
    this.toggleAdmin()
  }

  toggleAdmin() {
    if (this.accountAdminTarget.checked) {
      this.otherTargets.forEach((target) => {
        target.checked = true
        target.readOnly = true
      })
    } else {
      this.otherTargets.forEach((target) => {
        target.readOnly = false
      })
    }
  }
}
