import { Root, createRoot } from "react-dom/client"
import { Controller } from "@hotwired/stimulus"
import React from "react"

import DayOfWeekBarChart from "../react/DayOfWeekBarChart"
import DailySalesLineChart from "../react/DailySalesLineChart"
import HubBillPayment from "../react/HubBillPayment"
import HubBillRefund from "../react/HubBillRefund"
import OpeningHoursEditor from "../react/OpeningHoursEditor"
import TimeOfDayBarChart from "../react/TimeOfDayBarChart"
import ToastApp from "../react/ToastApp"
import HubTakingSheetConfigurator from "../react/HubTakingSheetConfigurator"
import HubStockTake from "../react/HubStockTake"

const knownComponents = {
  DailySalesLineChart,
  DayOfWeekBarChart,
  HubBillPayment,
  HubBillRefund,
  HubStockTake,
  HubTakingSheetConfigurator,
  OpeningHoursEditor,
  TimeOfDayBarChart,
  ToastApp,
}

export default class ReactApp extends Controller {
  static values = { component: String, props: Object }

  declare componentValue: string
  declare propsValue: Object

  root: Root

  connect() {
    this.root = createRoot(this.element)
    const key = this.componentValue as keyof typeof knownComponents
    const Component = knownComponents[key]
    if (Component) {
      // @ts-ignore - we can't possibly know the type of propsValue
      this.root.render(<Component {...this.propsValue} />)
    } else {
      console.error(`Unknown component: ${this.componentValue}`)
    }
  }

  disconnect() {
    this.root.unmount()

    console.log("root unmounted, html:", this.element)
  }
}
