import { I18n } from "i18n-js"
import translations from "./translations.json"

export const i18n = new I18n()
i18n.store(translations)
i18n.defaultLocale = "en"
i18n.enableFallback = true

export default i18n
const t = i18n.t.bind(i18n)
export { t }
