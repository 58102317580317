import React from "react"
import { HubOrderingProps } from "./types"

import { HashRouter } from "react-router-dom"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import { LineItemsContext, LineItemsWriteContext } from "./LineItemsContext"
import { useBasket } from "@react-shared/hooks"
import { MenuContext } from "./MenuContext"

import Sidebar from "./Sidebar"
import App from "./App"

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const apolloClient = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache()
});

export default function HubOrdering(props: HubOrderingProps) {
  const { basket, addLineItem, addOpenItem, incrementLineItem, decrementLineItem } = useBasket()

  const contextValue = {
    ...props,
    getMenu(menuId: string) {
      const { menus } = props
      return menus.find((menu) => menu.id === menuId)
    },
    getMenuGroup(menuGroupId: string) {
      const { menu_groups } = props
      return menu_groups.find((menu_group) => menu_group.id === menuGroupId)
    },
    getItem(itemId: string) {
      const { items } = props
      return items.find((item) => item.id === itemId)
    },
    getModifierGroup(modifierGroupId: string) {
      const { modifier_groups } = props
      return modifier_groups.find((modifier_group) => modifier_group.id === modifierGroupId)
    },
  }

  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <MenuContext.Provider value={contextValue}>
          <CurrencyContext.Provider
            value={{ iso: props.currency_iso, subunits: props.currency_subunits }}>
            <LineItemsWriteContext.Provider
              value={{ addLineItem, addOpenItem, incrementLineItem, decrementLineItem }}>
              <LineItemsContext.Provider value={{ lineItems: basket }}>
                <HashRouter>
                  <div className="flex flex-col md:flex-row gap-12 md:gap-4">
                    <div className="flex-1 text-sm">
                      <App />
                    </div>
                    <Sidebar items={props.items} basket={basket} bill={props.bill} />
                  </div>
                </HashRouter>
              </LineItemsContext.Provider>
            </LineItemsWriteContext.Provider>
          </CurrencyContext.Provider>
        </MenuContext.Provider>
      </ApolloProvider>
    </React.StrictMode>
  )
}
