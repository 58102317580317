import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["minMax", "radio"]

  declare minMaxTarget: HTMLDivElement
  declare radioTargets: HTMLInputElement[]

  connect() {
    this.radioTargets.forEach((radio) => {
      if (radio.checked) {
        this.setMinMax(radio.value === "true")
      }
    })
  }

  toggleMinMax(e: Event) {
    const enabled = (e.target as HTMLInputElement).value

    this.setMinMax(enabled === "true")
  }

  setMinMax(enabled: boolean) {
    if (enabled) {
      this.minMaxTarget.classList.remove("hidden")
      this.minMaxTarget.querySelectorAll("input").forEach((input) => {
        input.disabled = false
      })
    } else {
      this.minMaxTarget.classList.add("hidden")
      this.minMaxTarget.querySelectorAll("input").forEach((input) => {
        input.disabled = true
      })
    }
  }
}
