import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "../Breadcrumb"
import PosButton from "../PosButton"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../i18n"

export default function MenuIndex() {
  const { bill, menus, open_items } = useContext(MenuContext)
  const navigate = useNavigate()

  const backAction = () => {
    if (bill.persisted) {
      window.location.replace(`/hub/bills/${bill.id}`)
    } else {
      window.location.replace(`/hub/takeaway`)
    }
  }

  useEffect(() => {
    if (menus.length === 1 && menus[0].available) {
      navigate(`/menus/${menus[0].id}`, { replace: true })
    }
  })

  return (
    <div>
      <Breadcrumb segments={[{ name: t("js.hub.ordering.menus"), url: null }]} />
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        <button
          onClick={backAction}
          className="w-full text-white flex items-center justify-center p-3 h-16 rounded-md bg-gray-800">
          {t("js.common.back")}
        </button>
        {menus.map((menu) => (
          <li key={menu.id}>
            <PosButton
              tag="button"
              onClick={() =>
                menu.available ? navigate(`/menus/${menu.id}`, { replace: true }) : null
              }
              text_color={menu.available ? menu.text_color : "text-white"}
              bg_color={menu.available ? menu.color : "gray"}
              className="p-3">
              {menu.name}
            </PosButton>
          </li>
        ))}
        {open_items.map((openItem) => (
          <li key={openItem.id}>
            <PosButton
              tag="button"
              onClick={() => navigate(`/open-items/${openItem.id}`, { replace: true })}
              text_color={openItem.text_color}
              bg_color={openItem.color}
              className="p-3">
              {openItem.name}
            </PosButton>
          </li>
        ))}
      </ul>
    </div>
  )
}
