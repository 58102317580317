import React, { useContext } from "react"
import { useParams, useNavigate } from "react-router"
import BackButton from "../BackButton"
import Breadcrumb from "../Breadcrumb"
import PosButton from "../PosButton"
import { MenuContext } from "@hub-ordering/MenuContext"

import { t } from "../../../i18n"

export default function MenuGroup() {
  const { menuId, id } = useParams()

  const { getMenu, getMenuGroup, getItem } = useContext(MenuContext)

  const menu = getMenu(menuId!)
  const menu_group = getMenuGroup(id!)
  const navigate = useNavigate()

  // Handle discarded entities that are still referenced
  if (!menu || !menu_group) {
    return null
  }

  return (
    <div>
      <Breadcrumb
        segments={[
          { name: t("js.hub.ordering.menus"), url: "/" },
          { name: menu.name, url: `/menus/${menuId}` },
          { name: menu_group.name, url: null },
        ]}
      />
      <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        <BackButton target={`/menus/${menuId}`} />
        {menu_group.items.map((itemId) => {
          const item = getItem(itemId)
          if (!item || item.availability < 0) {
            return null
          }

          const available = item.availability > 0

          return (
            <li key={item.id}>
              <PosButton
                tag="button"
                onClick={() =>
                  available
                    ? navigate(`/menus/${menuId}/menu-groups/${id}/items/${itemId}`, {
                        replace: true,
                      })
                    : null
                }
                text_color={available ? item.text_color : "text-white"}
                bg_color={available ? item.color : "gray"}
                className="p-3">
                {item.name}
              </PosButton>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
