import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.replace)
  }

  disconnect() {
    this.element.removeEventListener("click", this.replace)
  }

  replace = () => {
    this.element.innerHTML = "Translating..."
  }
}
