import React from 'react'

export default function FieldError(props: {}) {
  return (
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pe-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden={true} className="h-5 w-5 text-red-600 dark:text-red-400">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </div>
  )
}
