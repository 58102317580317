import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "button"]

  declare fieldTarget: HTMLInputElement
  declare buttonTarget: HTMLButtonElement

  toggle = () => {
    if (this.fieldTarget.type === "password") {
      this.fieldTarget.type = "text"
    } else {
      this.fieldTarget.type = "password"
    }

    const text = this.buttonTarget.textContent
    this.buttonTarget.textContent = this.buttonTarget.dataset.altText || ""
    this.buttonTarget.dataset.altText = text || ""

    const aria = this.buttonTarget.getAttribute("aria-label")
    this.buttonTarget.setAttribute("aria-label", this.buttonTarget.dataset.altAriaLabel || "")
    this.buttonTarget.dataset.altAriaLabel = aria || ""
  }
}
