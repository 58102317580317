import { HubBill } from "@react-shared/types"

export type HubOrderingMenu = {
  id: string
  name: string
  color: string
  text_color: string
  menu_groups: string[]
  available: boolean
}

export type HubOrderingMenuGroup = {
  id: string
  name: string
  color: string
  text_color: string
  items: string[]
  available: boolean
}

export type HubOrderingItem = {
  id: string
  name: string
  color: string
  text_color: string
  modifier_groups: string[]
  allergens: string[]
  default_course_id: string | null
  price_cents: number
  unit_of_measure?: string
  sizes?: {
    id: string
    name: string
    price_cents: number
  }[]
  sku?: string
  availability: number
}

export type HubOrderingModifierGroup = {
  id: string
  name: string
  modifier_options: HubOrderingModifierOption[]
  multiple_selection: boolean
  min_selections: number
  max_selections: number
}

export type HubOrderingModifierOption = {
  id: string
  name: string
  priceCents: number
  allow_multiple: boolean
}

export type HubOrderingLineItem = {
  quantity: number
  menuId: string
  menuGroupId: string
  itemId: string
  modifiers: HubOrderingSelectedModifiers
  unitPriceCents: number
  netTotalCents: number
  notes: string
  sizeId: string | null
  courseId: string | null
}

export type HubOrderingOpenLineItem = {
  name: string
  quantity: number
  openItemId: string
  unitPriceCents: number
  netTotalCents: number
  notes: string
  courseId: string | null
}

export type HubOrderingSelectedModifiers = {
  modifierGroupId: string
  modifierOptions: {
    modifierOptionId: string
    name: string
    quantity: number
    unitPriceCents: number
  }[]
}[]

export type HubOrderingCourse = {
  id: string
  name: string
}

export type HubOrderingOpenItem = {
  id: string
  name: string
  color: string
  text_color: string
  default_course_id: string | null
}

export type HubOrderingProps = {
  location_id: string,
  currency_iso: string
  currency_subunits: number
  menus: HubOrderingMenu[]
  menu_groups: HubOrderingMenuGroup[]
  items: HubOrderingItem[]
  modifier_groups: HubOrderingModifierGroup[]
  courses: HubOrderingCourse[]
  open_items: HubOrderingOpenItem[]

  bill: HubBill
}

export function isLineItem(
  lineItem: HubOrderingLineItem | HubOrderingOpenLineItem,
): lineItem is HubOrderingLineItem {
  return (lineItem as HubOrderingOpenLineItem).openItemId === undefined
}
