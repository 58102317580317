import React, { useCallback, useEffect } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import { getInventoryCategories, inventorySearch } from "../../mutations"
import debounce from "../../shared/debounce"

import Combobox from "@react-shared/Forms/Combobox"
import { Category } from "../../graphql/graphql"

import { t } from "../../i18n"

type Props = {
  onChange: (selection: string) => void
}

export default function CategorySearch(props: Props) {
  const { data} = useQuery(getInventoryCategories)

  const [visibleData, setVisibleData] = React.useState<Category[]>([])

  useEffect(() => {
    setVisibleData(data?.inventoryCategories || [])
  }, [data])

  return (
    <Combobox
      onChange={selection => props.onChange(selection.id)}
      onInputChanged={input => {
        setVisibleData(
          data?.inventoryCategories.filter(category => category.name.toLowerCase().includes(input.toLowerCase())) || []
        )
      }}
      itemToString={item => (item ? item.name : '')}
      label={t("js.hub.admin.inventory.taking_sheets.form.add.category_label")}
      data={visibleData}
      itemToKey={item => item.id}
      renderItem={item => item.name} />
  )
}
