import { CurrencyContext } from "@react-shared/CurrencyContext"
import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AppContext from "./AppContext"
import Button from "@react-shared/Button"
import Price from "@react-shared/Price"

import { t } from "../../i18n"
import { CurrencyField } from "@react-shared/Forms/CurrencyField"

export default function Home() {
  const currency = useContext(CurrencyContext)

  const { amount, setAmount, tip, setTip, bill, payment_methods } = useContext(AppContext)

  const subunits = currency.subunits
  const decimalPlaces = Math.log10(subunits)

  const navigate = useNavigate()

  const [amountStr, setAmountStr] = React.useState<string>(
    amount ? (amount / subunits).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }) : ""
  )

  const handleAmountChange = (amountCents: number, amountStr: string) => {
    const cents = amountCents
    setAmount(cents)
    setAmountStr(amountStr)

    if (isNaN(cents)) {
      setTip(0)
    } else {
      setTip(Math.max(cents - bill.balance_cents, 0))
    }
  }

  const [method, setMethod] = React.useState<string | null>(null)
  const self = React.useRef<HTMLDivElement>(null)

  const savePayment = () => {
    self.current?.closest("form")?.submit()
  }

  const testSubmit = (e: KeyboardEvent) => {
    if (e.key === "Enter" && (method === null || amount === null || isNaN(amount) || amount <= 0)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  useEffect(() => {
    self.current?.closest("form")?.addEventListener("keydown", testSubmit)

    return () => {
      self.current?.closest("form")?.removeEventListener("keydown", testSubmit)
    }
  })

  return (
    <div className="w-full" ref={self}>
      <div className="flex gap-4">
        <div className="flex-1 flex flex-col gap-4 space-y-4">
          <div className="btn-group w-full">
            <label htmlFor="payment_amount" className="sr-only">
              {t("js.hub.bill.payment.amount")}
            </label>
            <input type="hidden" name="payment[payment_method]" value={method || ""} />
            <CurrencyField
              id="payment_amount"
              name="payment[amount]"
              autoFocus={true}
              amountStr={amountStr}
              onChange={handleAmountChange}
              currencyIso={currency.iso}
              subunits={subunits}
            />
          </div>
          <hr className="my-2" />
          <div className="grid grid-cols-2 gap-4">
            <Button
              onClick={() => navigate("/terminals")}
              kind="primary"
              disabled={amount === null || isNaN(amount) || amount <= 0}
            >
              {t("js.hub.bill.payment.card")}
            </Button>
            {payment_methods.map((payment_method) => (
              <Button
                key={payment_method.id}
                kind="plain"
                onClick={() => setMethod(payment_method.id)}
                active={method === payment_method.id}>
                {payment_method.name}
              </Button>
            ))}
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-3">
          {bill.balance_cents > 0 && (
            <Button
              kind="plain"
              onClick={() => {
                setAmount(bill.balance_cents)
                setAmountStr((bill.balance_cents / subunits).toLocaleString(undefined, {
                  minimumFractionDigits: decimalPlaces,
                  maximumFractionDigits: decimalPlaces
                }))
                setTip(0)
              }}>
              {t("js.hub.bill.payment.balance")}
              <div className="text-xs text-gray-500">
                <Price cents={bill.balance_cents} />
              </div>
            </Button>
          )}
          <Button kind="plain">
            {t("js.hub.bill.payment.tip")}
            <div className="text-xs text-gray-500">
              <Price cents={tip} />
            </div>
          </Button>
          <input type="hidden" value={tip} name="payment[tip_cents]" />
          <Button kind="plain" onClick={() => navigate("/split-person")}>
            {t("js.hub.bill.payment.split_person")}
          </Button>
          <Button kind="plain" onClick={() => navigate("/split-item")}>
            {t("js.hub.bill.payment.split_item")}
          </Button>
          <Button
            kind="success"
            disabled={method === null || amount === null || isNaN(amount) || amount <= 0}
            onClick={savePayment}>
            {t("js.common.confirm")}
          </Button>
        </div>
      </div>
    </div>
  )
}
