import { createContext } from "react"
import { LineItemModifierGroup, LineItemModifierOption } from "../../../../graphql/graphql"

export const ModifiersContext = createContext({
  modifiers: [] as LineItemModifierGroup[],
  sizeId: null as string | null,
  setUniqueModifier: (_modifierGroupId: string, _modifierOption: LineItemModifierOption) => { },
  setMultiModifier: (
    _modifierGroupId: string,
    _modifierOption: LineItemModifierOption,
    _quantity: number,
  ) => { },
  setSizeId: (_sizeId: string | null) => { },
})
