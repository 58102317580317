
import React from "react"

type SelectFieldProps = {
  className?: string
  children: React.ReactNode
} & React.InputHTMLAttributes<HTMLSelectElement>

export default function SelectField({ className, children, ...props }: SelectFieldProps) {
  return (
    <select
      className={`
        block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset
        focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6
        bg-white dark:bg-white/5
        text-gray-900 dark:text-white
        ring-gray-300 dark:ring-white/10
        focus:ring-indigo-600 dark:focus:ring-indigo-500
        ${className || ""}`}
      {...props}
    >
      {children}
    </select>
  )
}
