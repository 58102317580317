import Price from "@react-shared/Price"
import { PaymentOrRefund } from "@react-shared/types"
import React from "react"

export type TransactionProps = {
  transaction: PaymentOrRefund
}

export default function Transaction({ transaction }: TransactionProps) {
  return (
    <div className="w-full">
      <div className="flex">
        {(transaction.payment_method === "custom" && (
          <span>{transaction.payment_method_name}</span>
        ) || (
            <span>
              {transaction.card_brand} *{transaction.card_last4}
            </span>
          ))}
        <div className="ms-auto">
          <span>
            <Price cents={transaction.amount_cents} />
          </span>
        </div>
      </div>
      <div className="text-xs">{transaction.created_at}</div>
    </div>
  )
}
