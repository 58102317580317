import { Controller } from "@hotwired/stimulus"

import I18n from "../i18n"

export default class extends Controller {
  static targets = ["kind", "dismissButton"]

  connect() {
    document.addEventListener("request:new", this.incoming)
    document.addEventListener("request:dismiss", this.dismiss)
  }

  disconnect() {
    document.removeEventListener("request:new", this.incoming)
    document.removeEventListener("request:dismiss", this.dismiss)
  }

  incoming = (ev: CustomEvent) => {
    const obj = ev.detail.object

    this.element.classList.add("d-flex")
    this.element.classList.remove("d-none")

    // @ts-ignore
    this.kindTarget.textContent = I18n.t(`js.hub.requests.${obj.kind}`)
    // @ts-ignore
    this.dismissButtonTarget.href = `/hub/location_requests/${obj.id}/dismiss`
  }

  dismiss = () => {
    this.element.classList.remove("d-flex")
    this.element.classList.add("d-none")
  }
}
