import { useContext, useState } from "react"
import { AppContext } from "../GuestOrdering/AppContext"
import { OrderRequestLineItem } from "../GuestOrdering/LineItemsContext"
import { LineItemModifierGroup, LineItemModifierOption } from "../../graphql/graphql"

export function useServiceType() {
  const { service_type } = useContext(AppContext)
  return service_type
}

export function useSelectedModifiers() {
  const [modifiers, setModifiers] = useState<LineItemModifierGroup[]>([])

  const setUniqueModifier = (modifierGroupId: string, modifierOption: LineItemModifierOption) => {
    const newModifiers = [...modifiers]

    const existingGroup = newModifiers.find((group) => group.modifierGroupId === modifierGroupId)
    if (existingGroup) {
      existingGroup.modifierOptions = [modifierOption]
    } else {
      newModifiers.push({ id: "", modifierGroupId, modifierOptions: [modifierOption] })
    }

    setModifiers(newModifiers)
  }

  const setMultiModifier = (modifierGroupId: string, modifierOption: LineItemModifierOption, quantity: number) => {
    const newModifiers = [...modifiers]

    const existingGroup = newModifiers.find((group) => group.modifierGroupId === modifierGroupId)
    if (existingGroup) {
      const existingOption = existingGroup.modifierOptions.find((option) => option.modifierOptionId === modifierOption.modifierOptionId)
      if (existingOption) {
        if (quantity === 0) {
          existingGroup.modifierOptions = existingGroup.modifierOptions.filter((option) => option.modifierOptionId !== modifierOption.modifierOptionId)
        } else {
          existingOption.quantity = quantity
        }
      } else {
        existingGroup.modifierOptions.push({ ...modifierOption, quantity })
      }
    } else {
      newModifiers.push({ id: modifierGroupId, modifierGroupId, modifierOptions: [{ ...modifierOption, quantity }] })
    }

    setModifiers(newModifiers)
  }

  return { modifiers, setUniqueModifier, setMultiModifier }
}

const normalizeModifiersForComparison = (modifiers: OrderRequestLineItem["modifiers"]): string => {
  return modifiers
    .map((modifier) => {
      return `${modifier.modifierGroupId}:${modifier.modifierOptions
        .map((option) => `${option.modifierOptionId}^${option.quantity}`)
        .sort()
        .join(",")}`
    })
    .sort()
    .join(";")
}

export function useBasket() {
  const [basket, setBasket] = useState<OrderRequestLineItem[]>([])

  const addLineItem = (data: OrderRequestLineItem) => {
    data.netTotalCents = data.unitPriceCents * data.quantity

    // Try to find an existing line item with the same item id, size id, modifiers, and notes
    const existingLineItem = basket.find(
      (lineItem) =>
        lineItem.itemId == data.itemId &&
        lineItem.sizeId == data.sizeId &&
        lineItem.notes == data.notes &&
        normalizeModifiersForComparison(lineItem.modifiers) ==
          normalizeModifiersForComparison(data.modifiers),
    )

    if (existingLineItem) {
      existingLineItem.quantity += data.quantity
      existingLineItem.netTotalCents = existingLineItem.unitPriceCents * existingLineItem.quantity
      setBasket([...basket])
    } else {
      setBasket([...basket, data])
    }
  }

  const addOpenItem = (data: OrderRequestLineItem) => {
    setBasket([...basket, data])
  }

  const incrementLineItem = (index: number) => {
    const newBasket = [...basket]
    newBasket[index].quantity += 1
    newBasket[index].netTotalCents = newBasket[index].unitPriceCents * newBasket[index].quantity
    setBasket(newBasket)
  }

  const decrementLineItem = (index: number) => {
    const newBasket = [...basket]
    newBasket[index].quantity -= 1
    newBasket[index].netTotalCents = newBasket[index].unitPriceCents * newBasket[index].quantity
    if (newBasket[index].quantity == 0) {
      newBasket.splice(index, 1)
    }
    setBasket(newBasket)
  }

  const removeLineItems = (indices: number[]) => {
    const newBasket = basket.filter((_, index) => !indices.includes(index))
    setBasket(newBasket)
  }

  return { basket, setBasket, addLineItem, addOpenItem, incrementLineItem, decrementLineItem, removeLineItems }
}