import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  declare readonly menuTarget: HTMLElement

  toggle(e: Event) {
    e.preventDefault()
    e.stopPropagation()

    if (this.menuTarget.classList.contains("hidden")) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.menuTarget.classList.remove("hidden")
    document.addEventListener("click", this.hide)
  }

  hide = () => {
    this.menuTarget.classList.add("hidden")
  }
}
