import React, { useEffect, useRef } from "react"

type Props = {
  children: React.ReactNode
  additionalClasses?: string
  additionalInnerClasses?: string
  withDarkMode?: boolean
  onClose(): void
}

export default function Dialog({ children, additionalClasses, additionalInnerClasses, withDarkMode, onClose }: Props) {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    const dialog = dialogRef.current

    dialog?.removeAttribute("open")
    dialog?.showModal()

    document.body.classList.add("overflow-hidden")
    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  const testClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      dialogRef.current?.close()
    }
  }

  const darkClasses = "dark:bg-gray-800 dark:text-white dark:shadow-gray-600"

  return (
    <dialog
      onClick={testClose}
      onClose={onClose}
      ref={dialogRef}
      className={`select-none flex items-center overflow-hidden outline-none bg-transparent w-full max-sm:max-w-full max-sm:!max-h-full h-full ${additionalClasses}`}
      style={{ maxHeight: "calc(100% - 3rem)" }}>
      <div className={`relative max-sm:h-full sm:rounded sm:max-w-xl w-full mx-auto shadow-md bg-white flex flex-col max-h-full ${additionalInnerClasses} ${withDarkMode ? darkClasses : ""}`}>
        {children}
      </div>
    </dialog>
  )
}
