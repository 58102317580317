import React, { useContext, useEffect, useState } from "react"
import AppContext from "./AppContext"

import { useNavigate } from "react-router"
import { CardReader } from "@private-api/Hub/CardReaders"

import { t } from "../../i18n"
import Button from "@react-shared/Button"

export default function CardReaders() {
  const { card_readers } = useContext(AppContext)

  const navigate = useNavigate()

  const pickTerminal = async (terminal: CardReader) => {
    // Go to the terminal spinner page
    navigate(`/terminals/${terminal.id}`)
  }

  useEffect(() => {
    if (card_readers.length === 1) {
      pickTerminal(card_readers[0])
    }
  }, [])

  return (
    <>
      <div className="text-xl font-bold text-center mb-6">{t("js.hub.bill.payment.pick_terminal")}</div>

      {card_readers.length === 0 && (
        <div className="text-center mx-auto mb-6">
          {t("js.hub.bill.payment.no_terminals")}
        </div>
      ) || (
          <ul className="mb-6 text-lg border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white divide-y divide-gray-200 dark:divide-gray-600">
            {card_readers.map((terminal) => (
              <li key={terminal.id} className="px-6 py-4 cursor-pointer" onClick={() => pickTerminal(terminal)} role="button">
                {terminal.name}
              </li>
            ))}
          </ul>
        )}

      <Button kind="plain" onClick={() => navigate("/")}>{t("js.common.back")}</Button>
    </>
  )
}
