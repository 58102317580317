import { LockPlugin, PresetPlugin, RangePlugin, easepick } from "@easepick/bundle"
import { Core } from "@easepick/core"
import { Controller } from "@hotwired/stimulus"

const dateToISO = (date: Date) => {
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset * 60 * 1000))
  return date.toISOString().split('T')[0]
}

export default class extends Controller<HTMLInputElement> {
  static values = {
    format: String,
    min: String,
    max: String,
    isRange: Boolean
  }

  static targets = ["input", "start", "end"]

  declare formatValue: string
  declare minValue: string
  declare maxValue: string
  declare isRangeValue: boolean

  declare inputTarget: HTMLInputElement
  declare startTarget: HTMLInputElement
  declare endTarget: HTMLInputElement

  picker: Core | null = null

  connect() {
    this.picker = new easepick.create({
      element: this.inputTarget,
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"
      ],
      firstDay: 1,
      zIndex: 10,
      lang: navigator.language,
      date: this.inputTarget.value,
      format: this.formatValue,
      plugins: [
        LockPlugin,
      ].concat(
        this.isRangeValue ? [RangePlugin, PresetPlugin] as any : []
      ),
      LockPlugin: {
        minDate: this.minValue,
        maxDate: this.maxValue,
        presets: false
      },
      RangePlugin: this.isRangeValue ? {
        strict: true,
      } : undefined,
      setup: (picker) => {
        picker.on('select', (e) => {
          this.startTarget.value = dateToISO(e.detail.start)
          this.endTarget.value = dateToISO(e.detail.end)
          this.startTarget.form?.submit()
        });
      },
    })
  }

  disconnect() {
    this.picker?.destroy()
  }
}
