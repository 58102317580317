import "./shared/sentry"

import React from "react"
import { createRoot } from "react-dom/client"

// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"

import "./controllers/index.mjs"

/**
 * Replace the default turbo-confirm dialog that uses the native browser confirm.
 * This renders a fancy dialog element instead.
 */
Turbo.setConfirmMethod((message: string) => {
  const dialog = document.querySelector("dialog#turbo-confirm") as HTMLDialogElement
  if (!dialog) {
    return Promise.resolve(false)
  }

  const para = dialog.querySelector("p")
  if (para) {
    para.textContent = message
  }

  dialog.returnValue = ""
  dialog.showModal()

  return new Promise((resolve) => {
    dialog.addEventListener(
      "close",
      () => {
        resolve(dialog.returnValue == "confirm")
      },
      { once: true },
    )
  })
})

import HubOrdering from "./react/HubOrdering/index"
import I18n from "./i18n"
import { ToastOptions } from "react-hot-toast"

document.addEventListener("turbo:load", () => {
  const locale = document.documentElement.lang
  I18n.locale = locale

  const hubOrderingRoot = document.getElementById("hub-ordering-root")
  if (hubOrderingRoot) {
    const props = JSON.parse(hubOrderingRoot.dataset.props!)
    createRoot(hubOrderingRoot).render(<HubOrdering {...props} />)
  }
})

document.addEventListener("turbo:submit-start", (event: CustomEvent) => {
  let submitter = event.detail.formSubmission?.submitter

  if (!submitter) {
    if (window.lastClickedLink) {
      submitter = window.lastClickedLink
    } else {
      return
    }
  }

  if (submitter.dataset.loadingSpinner && (submitter.tagName === "BUTTON" || submitter.tagName === "A")) {
    const spinnerCont = document.createElement("span")
    spinnerCont.classList.add("loading-spinner")

    let color = submitter.dataset.loadingSpinnerColor || "text-white"

    spinnerCont.innerHTML = `
      <svg class="animate-spin inline-block -ms-1 me-3 h-5 w-5 ${color}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>`

    submitter.insertBefore(spinnerCont, submitter.firstChild)
  }
})

document.addEventListener("turbo:submit-end", (event: CustomEvent) => {
  let submitter = event.detail.formSubmission?.submitter

  if (!submitter) {
    if (window.lastClickedLink) {
      submitter = window.lastClickedLink
      window.lastClickedLink = null
    } else {
      return
    }
  }

  if (submitter.dataset.loadingSpinner && (submitter.tagName === "BUTTON" || submitter.tagName === "A")) {
    const spinnerCont = submitter.querySelector("span.loading-spinner")
    if (spinnerCont) {
      spinnerCont.remove()
    }
  }

  if (submitter.dataset.successMessage && event.detail.success) {
    sendToast("success", submitter.dataset.successMessage, {
      duration: 2000,
      style: {
        background: "#15803D",
        color: "white",
      },
    })
  }
})

export function sendToast(type: "error" | "success", message: string, options: ToastOptions = {}) {
  document.dispatchEvent(
    new CustomEvent("qly:toast", {
      detail: { type, message, options },
    }),
  )
}

document.addEventListener("click", (event) => {
  const link = event.target.closest("a[data-turbo-method]")
  window.lastClickedLink = link
})

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

// Live reload for development
if (process.env.NODE_ENV === "development") {
  const source = new EventSource("http://localhost:5678")
  source.onmessage = (event) => {
    if (event.data === "reload") {
      window.location.reload()
    }
  }

  // Apollo error messages
  loadDevMessages();
  loadErrorMessages();
}
