
import Button from "@react-shared/Button";
import React from "react";
import HubBillTicket from "../HubBillTicket";
import { LineItemsContext } from "./LineItemsContext";

import { t } from "../../i18n";
import { HubOrderingLineItem, HubOrderingOpenLineItem, HubOrderingProps, HubOrderingSelectedModifiers } from "./types"
import { useLocation, useNavigate } from "react-router";
import { useMutation } from "@apollo/client"
import { hubPlaceOrder } from "../../mutations"

type Props = {
  bill: HubOrderingProps["bill"];
  items: HubOrderingProps["items"];
  basket: (HubOrderingLineItem | HubOrderingOpenLineItem)[];
}

export function isOpenLineItem(lineItem: HubOrderingLineItem | HubOrderingOpenLineItem): lineItem is HubOrderingOpenLineItem {
  return (lineItem as HubOrderingOpenLineItem).openItemId !== undefined
}

export default function Sidebar(props: Props) {
  const navigate = useNavigate()
  const location = useLocation()

  const [confirmAtTableOrder, { loading }] = useMutation(hubPlaceOrder, {
    variables: {
      billId: props.bill.id,
      lineItems: props.basket.map((lineItem) => {
        if (isOpenLineItem(lineItem)) {
          return {
            openLineItem: {
              openItemId: lineItem.openItemId,
              name: lineItem.name,
              quantity: lineItem.quantity,
              unitPriceCents: lineItem.unitPriceCents,
              courseId: lineItem.courseId,
            }
          }
        } else {
          return {
            menuLineItem: {
              menuId: lineItem.menuId,
              menuGroupId: lineItem.menuGroupId,
              itemId: lineItem.itemId,
              sizeId: lineItem.sizeId,
              quantity: lineItem.quantity,
              courseId: lineItem.courseId,
              notes: lineItem.notes,
              modifiers: lineItem.modifiers.map((group) => ({
                modifierGroupId: group.modifierGroupId,
                modifierOptions: group.modifierOptions.map((option) => ({
                  modifierOptionId: option.modifierOptionId,
                  quantity: option.quantity
                }))
              }))
            }
          }
        }
      })
    },
    onCompleted: (data) => {
      if (data.hubPlaceOrder!.accepted) {
        window.location.href = "/hub/bills/" + props.bill.id
      }
    }
  })

  const confirm = async () => {
    if (props.basket.length === 0) {
      return
    }

    if (props.bill.at_table) {
      confirmAtTableOrder()
    } else {
      navigate("/confirm-takeout", { state: { previousLocation: location } })
    }
  }

  return (
    <div className="basis-80 lg:basis-96">
      <div className="flex justify-between items-center gap-4 mb-4">
        <Button
          disabled={props.basket.length === 0}
          size="large"
          kind="primary"
          className="flex-1"
          onClick={confirm}>
          {t("js.hub.ordering.confirm")}
        </Button>
      </div>
      <LineItemsContext.Provider value={{ lineItems: props.basket }}>
        <HubBillTicket items={props.items} bill={props.bill} />
      </LineItemsContext.Provider>
    </div>
  );
}
