import { baseUrl, request } from "../../request"

type Start3DSPayload = {
  location_id: string
  amount_cents: number
  checkout_card_token: string
  channel_data: {
    channel: string
    java_enabled: boolean
    javascript_enabled: boolean
    language: string
    color_depth: number
    screen_height: number
    screen_width: number
    timezone: number
  }
}

export type Start3DSResponse = {
  id: string
  next_actions: any[]
  _links: {
    three_ds_method_url: {
      href: string
    }
    issuer_fingerprint: {
      href: string
    }
  }
  protocol_version: string
  session_secret: string
  transaction_id: string

  acs?: {
    reference_number: string
    transaction_id: string
    operator_id: string
    url: string
    challenge_mandated: boolean
    authentication_type: string
  }

  fingerprint_iframe?: string
}

type IssuerFingerprintPayload = {
  three_ds_method_completion: "Y" | "N"
  issuer_fingerprint_url: string
  session_secret: string
}

type CreateChallengePayload = {
  transaction_id: string
  acs_transaction_id: string
  message_version: string
  acs_url: string
}

export function Start(params: Start3DSPayload) {
  const url = `${baseUrl()}/payments/checkout_com/three_d_secure/start`
  const method = "POST"

  return request<Start3DSResponse, Start3DSPayload>(url, method, params)
}

export function StartHosted(params: Start3DSPayload) {
  const url = `${baseUrl()}/payments/checkout_com/three_d_secure/start_hosted`
  const method = "POST"

  return request<{ redirect: string }, Start3DSPayload>(url, method, params)
}

export function IssuerFingerprint(params: IssuerFingerprintPayload) {
  const url = `${baseUrl()}/payments/checkout_com/three_d_secure/issuer_fingerprint`
  const method = "POST"

  return request<Start3DSResponse, IssuerFingerprintPayload>(url, method, params)
}

export function CreateChallenge(params: CreateChallengePayload) {
  const url = `${baseUrl()}/payments/checkout_com/three_d_secure/create_challenge`
  const method = "POST"

  return request<string, CreateChallengePayload>(url, method, params, { asText: true })
}

export default {
  Start,
  StartHosted,
  IssuerFingerprint,
  CreateChallenge,
}
