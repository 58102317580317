import React, { useMemo } from "react"

type Props = {
  legend: React.ReactNode
  description?: React.ReactNode
  children: React.ReactNode
  layout?: "default" | "wide"
} & React.HTMLAttributes<HTMLFieldSetElement>

export default function Fieldset(props: Props) {
  const legendId = useMemo(() => `legend-${Math.random().toString(36).slice(2)}`, []);
  const { children, legend, description, ...rest } = props;

  const layout = props.layout || "default";

  return (
    <fieldset
      className={`grid grid-cols-1 ${layout == "default" ? "md:grid-cols-3" : ""} gap-x-8 gap-y-10 border-b border-gray-900/10 dark:border-white/10 pb-12`}
      role="group"
      aria-labelledby={legendId}
      {...rest}>
      <div>
        <legend className="text-base font-semibold leading-7 text-gray-900 dark:text-white" id={legendId}>
          {legend}
          {description && (
            <div className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300 space-y-1">
              {description}
            </div>
          )}
        </legend>
      </div>

      {children}
    </fieldset>
  )
}
