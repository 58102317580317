import React from "react"
import { LoadingSpinner } from "@react-shared/LoadingSpinner"

type ButtonProps = {
  children: React.ReactNode
  kind: "primary" | "danger" | "warning" | "success" | "plain"
  size?: "xsmall" | "regular" | "large"
  disabled?: boolean
  className?: string
  active?: boolean
  loading?: boolean
  isSubmit?: boolean
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const TYPE_CLASSES = {
  primary:
    "bg-slate-800 hover:bg-slate-700 focus-visible:outline-indigo-600 \
            dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus-visible:outline-indigo-600 \
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 \
            text-white border-transparent",
  danger:
    "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 \
           dark:bg-red-500 dark:hover:bg-red-400 dark:focus-visible:outline-red-500 \
           focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 \
           text-white border-transparent",
  warning:
    "bg-yellow-700 hover:bg-yellow-800 focus-visible:outline-yellow-700 \
            dark:bg-yellow-800 dark:hover:bg-yellow-700 dark:focus-visible:outline-yellow-600 \
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 \
            text-white border-transparent",
  success:
    "bg-green-700 hover:bg-green-600 focus-visible:outline-green-700 \
            dark:bg-green-600 dark:hover:bg-green-500 dark:focus-visible:outline-green-600 \
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 \
            text-white border-transparent",
  plain: "bg-white hover:bg-gray-100 border-[1.25px] border-gray-300 text-gray-900",
  disabled: "!bg-gray-300 !text-gray-400 dark:!bg-gray-700 dark:!border-gray-700"
}

const SIZE_CLASSES = {
  xsmall: "px-1 py-1 text-xs",
  small: "px-2.5 py-1.5 text-xs",
  regular: "px-3.5 py-2.5 text-sm",
  large: "px-8 py-3 text-base",
}

export default function Button(props: ButtonProps) {
  const {
    children,
    kind,
    active,
    loading,
    isSubmit,
    ...rest } = props
  const size = props.size || "regular"

  let classes =
    "inline-block whitespace-nowrap rounded-md shadow-sm font-semibold select-none text-center truncate border-[1.25px]"
  if (props.disabled) {
    classes += " cursor-not-allowed " + TYPE_CLASSES.disabled
  } else {
    classes += " cursor-pointer " + TYPE_CLASSES[kind]
  }

  if (active) {
    classes += " ring-2 ring-offset-4 ring-blue/50"
  }

  classes += " " + SIZE_CLASSES[size]
  classes += " " + props.className

  return (
    <button
      type={isSubmit ? "submit" : "button"}
      {...rest}
      onClick={(props.disabled || props.loading) ? undefined : rest.onClick}
      className={classes}>
      {loading && <LoadingSpinner />}
      {children}
    </button>
  )
}
