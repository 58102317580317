import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import QrCodeScanner from "./qrcode_scanner.mjs"
import { assignTableCode } from "../mutations";

import { t } from "../i18n";
import { sendToast } from "../hub";

export default class extends QrCodeScanner {
  static targets = ["scanner", "startButton", "instructions", "alertContainer", "tableName"]
  static values = {
    tables: Array<{ id: String, number: String }>
  }

  declare scannerTarget: HTMLDivElement;
  declare startButtonTarget: HTMLButtonElement;
  declare instructionsTarget: HTMLDivElement;
  declare alertContainerTarget: HTMLDivElement;
  declare tableNameTarget: HTMLDivElement;
  declare tablesValue: Array<{ id: string, number: string }>;

  currentTable: { id: string, number: string } | undefined;
  tablesData: Array<{ id: string, number: string }> = []

  apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

  connect() {
    super.connect();

    // values are immutable, so we need to copy them to a mutable array
    this.tablesData = this.tablesValue.slice();

    this.apolloClient = new ApolloClient({
      uri: '/graphql',
      cache: new InMemoryCache()
    });
  }

  start(event: Event) {
    super.start(event);

    this.startButtonTarget.classList.add("hidden");
    this.instructionsTarget.classList.add("hidden");
    this.alertContainerTarget.classList.remove("hidden");

    this.currentTable = this.tablesData.shift();

    this.tableNameTarget.textContent = this.currentTable?.number!;
  }

  skip() {
    this.currentTable = this.tablesData.shift();

    if (this.currentTable) {
      this.tableNameTarget.textContent = this.currentTable?.number!;
    } else {
      window.history.back()
    }
  }

  async onScan(text: string) {
    if (text.match(/https:\/\/qly.at\/\w+/)) {
      const code = text.split("/").pop()!

      // Attach the table to the QR code
      const result = await this.apolloClient?.mutate({
        mutation: assignTableCode,
        variables: {
          tableId: this.currentTable?.id!,
          code
        }
      })

      if (result?.data?.assignTableCode?.accepted) {
        this.currentTable = this.tablesData.shift();

        // Is there a next table?
        if (this.currentTable) {
          sendToast(
            "success",
            t("js.hub.code_assigner.success_next", { number: this.currentTable?.number }),
            {
              position: "bottom-center",
              style: {
                background: "#15803D",
                color: "white",
              },
            }
          )
          this.tableNameTarget.textContent = this.currentTable?.number!;
        } else {
          sendToast(
            "success",
            t("js.hub.code_assigner.success_end"),
            {
              position: "bottom-center",
              style: {
                background: "#15803D",
                color: "white",
              },
            }
          )
          window.history.back()
        }
      } else {
        const error = result?.data?.assignTableCode?.errors![0]!

        sendToast(
          "error",
          t("js.hub.code_assigner.error", { error: error.message }),
          {
            position: "bottom-center",
            style: {
              background: "#D00000",
              color: "white",
            },
          }
        )
      }
    } else {
      sendToast(
        "error",
        t("js.hub.code_assigner.invalid_qr_code"),
        {
          position: "bottom-center",
              style: {
                background: "#D00000",
                color: "white",
              },
        }
      )
    }
  }
}
