import { LineItemsWriteContext } from "@hub-ordering/LineItemsContext"
import { HubOrderingItem, HubOrderingLineItem } from "@hub-ordering/types"
import Button from "@react-shared/Button"
import Price from "@react-shared/Price"
import React, { useContext } from "react"

import { t } from "../../i18n"

type Props = {
  item: HubOrderingItem
  lineItem: HubOrderingLineItem
  index: number
}

export default function LineItem({ item, lineItem, index }: Props) {
  console.log(lineItem.modifiers)
  let modifiers = lineItem.modifiers
    .map((group) => group.modifierOptions).flat()
    .map((modifier) => {
      if (modifier.quantity === 1) {
        return modifier.name
      } else {
        return `${modifier.quantity} x ${modifier.name}`
      }
    })

  if (lineItem.sizeId) {
    const sizeName = item.sizes!.find((size) => size.id === lineItem.sizeId)!.name
    modifiers.unshift(sizeName)
  }

  if (lineItem.notes.length > 0) {
    modifiers.push(lineItem.notes)
  }

  const { incrementLineItem, decrementLineItem } = useContext(LineItemsWriteContext)

  return (
    <>
      <div className="flex mt-2 gap-2">
        <div>{lineItem.quantity} x</div>
        <div className="flex-1">
          <div>{item.name}</div>
          {modifiers.length > 0 && <div>{modifiers.join(", ")}</div>}
        </div>
        <div className="ms-auto">
          <span>
            <Price cents={lineItem.unitPriceCents * lineItem.quantity} />
          </span>
        </div>
      </div>
      <div className="btn-group">
        <Button size="small" kind="warning" onClick={() => decrementLineItem(index)}>
          {t("js.hub.ordering.remove")}
        </Button>
        <Button size="small" kind="primary" onClick={() => incrementLineItem(index)}>
          {t("js.hub.ordering.add_more")}
        </Button>
      </div>
    </>
  )
}
