import React, { useEffect, useState } from "react"
import Button from "@react-shared/Button"
import SelectField from "@react-shared/Forms/SelectField"
import { mergeOverlappingTimeRanges } from "./hoursNormalizer"
import { ApolloClient, ApolloProvider, InMemoryCache, useMutation, useQuery } from "@apollo/client"
import { updateOpeningHours } from "../../mutations"

import { t } from "../../i18n"
import { getOpeningHours } from "../../mutations/getOpeningHours"
import { LoadingSpinner } from "@react-shared/LoadingSpinner"

type OpeningHoursProps = {
  locationId: string;
}

type OpeningHours = {
  days: OpeningHourDay[];
}

type OpeningHourDay = {
  closed: boolean;
  hours?: OpeningHoursRange[];
}

type OpeningHoursRange = {
  start: number | null;
  end: number | null;
}

export function OpeningHoursEditor({ locationId }: OpeningHoursProps) {
  const dayNames = t("js.hub.opening_hours.days")

  const [openingHours, setOpeningHours] = useState<OpeningHours>({
    days: [],
  })

  const dayIndices = [1, 2, 3, 4, 5, 6, 0];

  const [save] = useMutation(updateOpeningHours, {
    variables: {
      locationId,
      openingHours: openingHours.days.map((d) => ({
        hours: d.hours
          ?.filter((h) => h.start !== null && h.end !== null)
          .map((h) => ({
          open: h.start,
          close: h.end,
        })) || [],
      })),
    }
  })

  const { data, loading } = useQuery(getOpeningHours, {
    variables: {
      locationId,
    }
  })

  useEffect(() => {
    if (data && !loading) {
      setOpeningHours({
        days: data.location?.openingHours.map((d) => ({
          closed: d.hours.length === 0,
          hours: d.hours.map((h) => ({
            start: h.open,
            end: h.close,
          })),
        }))
      })
    }
  }, [loading])

  const confirm = async () => {
    const result = await save();
    if (result.data?.updateOpeningHours.ok) {
      history.back();
    } else {
      alert(t("js.hub.opening_hours.error"));
    }
  }

  const open = (idx: number) => {
    setOpeningHours({
      days: openingHours.days.map((d, i) => i === idx ? { closed: false, hours: [{ start: null, end: null }] } : d),
    })
  }

  const close = (idx: number) => {
    setOpeningHours({
      days: openingHours.days.map((d, i) => i === idx ? { closed: true } : d),
    })
  }

  const addHours = (dayIdx: number) => {
    setOpeningHours({
      days: openingHours.days.map((d, i) => i === dayIdx ? { ...d, hours: [...(d.hours || []), { start: null, end: null }] } : d),
    });
  }

  const removeHours = (dayIdx: number, hourIdx: number) => {
    const newHours = [...openingHours.days[dayIdx].hours!];
    newHours.splice(hourIdx, 1);

    if (newHours.length === 0) {
      setOpeningHours({
        days: openingHours.days.map((d, i) => i === dayIdx ? { ...d, closed: true } : d),
      });
      return;
    }

    setOpeningHours({
      days: openingHours.days.map((d, i) => i === dayIdx ? { ...d, hours: newHours } : d),
    });
  }

  const setStartingHours = (dayIdx: number, hourIdx: number, value: number) => {
    let newHours = [...openingHours.days[dayIdx].hours!];

    newHours[hourIdx] = { ...newHours[hourIdx], start: value };

    newHours = normalizeHours(newHours);

    setOpeningHours({
      days: openingHours.days.map((d, i) => i === dayIdx ? { ...d, hours: newHours } : d),
    });
  }

  const setEndingHours = (dayIdx: number, hourIdx: number, value: number) => {
    let newHours = [...openingHours.days[dayIdx].hours!];

    newHours[hourIdx] = { ...newHours[hourIdx], end: value };

    newHours = normalizeHours(newHours);

    setOpeningHours({
      days: openingHours.days.map((d, i) => i === dayIdx ? { ...d, hours: newHours } : d),
    });
  }

  const normalizeHours = (hours: OpeningHoursRange[]) => mergeOverlappingTimeRanges(hours);

  if (loading || openingHours.days.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className="space-y-8">
      {dayIndices.map((idx) => {
        const day = openingHours.days[idx];

        return (
            <div key={idx}>
              <div className="flex items-center">
                <span className="font-medium">{dayNames[idx]}: </span>
                {day.closed && (
                  <>
                    <span className="mx-2">{t("js.hub.opening_hours.closed")}</span>
                    <Button
                      size="small"
                      kind="primary"
                      onClick={() => open(idx)}>
                      {t("js.hub.opening_hours.open")}
                    </Button>
                  </>
                ) || (
                  <>
                    <Button
                      className="ms-2"
                      size="small"
                      kind="warning"
                      onClick={() => close(idx)}>
                      {t("js.hub.opening_hours.close")}
                    </Button>
                  </>
                )}
              </div>
              {!day.closed && (
                <div className={"my-3"}>
                  {day.hours?.map((range, i) => (
                    <div key={i} className="flex max-w-sm items-center space-x-2 mb-3">
                      <TimePicker
                        value={range.start}
                        onChange={(value => setStartingHours(idx, i, value))}
                        disabledTimes={[range.end || 1440]}
                      />
                      <span>-</span>
                      <TimePicker
                        value={range.end}
                        onChange={(value => setEndingHours(idx, i, value))}
                        disabledTimes={[range.start || 0]}
                      />
                      <Button
                        size="small"
                        kind="danger"
                        className={"flex-shrink-0"}
                        onClick={() => removeHours(idx, i)}>
                        {t("js.hub.opening_hours.remove")}
                      </Button>
                    </div>
                  ))}
                  <Button
                    size="small"
                    kind="primary"
                    onClick={() => addHours(idx)}>
                    {t("js.hub.opening_hours.add_hours")}
                  </Button>
                </div>
              )}
            </div>
        );
      })}
      <Button kind={"primary"} onClick={confirm} disabled={loading}>
        {t("js.hub.opening_hours.confirm")}
      </Button>
    </div>
  );
}

type TimePickerProps = {
  value: number | null
  onChange: (value: number) => void
  disabledTimes?: number[]
}

function TimePicker(props: TimePickerProps) {
  const values = Array.from({ length: 24 * 4 + 1 }, (_, i) => {
    return i * 15;
  });

  const format = (value: number) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours}`.padStart(2, '0') + ':' + `${minutes}`.padStart(2, '0');
  }

  const disabledTimes = props.disabledTimes || [];

  return (
    <SelectField
      value={props.value == null ? '' : props.value}
      onChange={(e) => props.onChange(parseInt(e.target.value))}>
      <option></option>
      {values.map((v) => (
        <option
          key={v}
          value={v}
          disabled={disabledTimes.includes(v)}>
          {format(v)}
        </option>
      ))}
    </SelectField>
  );
}

const apolloClient = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache()
});

export default function OpeningHoursEditorWrapper(props: OpeningHoursProps) {
  return (
    <ApolloProvider client={apolloClient}>
      <OpeningHoursEditor {...props} />
    </ApolloProvider>
  );
}