import { baseUrl, request } from "@private-api/request"

export type CardReader = {
  id: string
  name: string
}

type ListResponse = {
  card_readers: CardReader[]
}

type OffloadResponse = {
  success: true
  gateway_id: string
} | {
  success: false
  code: string
}

export function List() {
  const url = `${baseUrl()}/hub/card_readers`
  const method = "GET"

  return request<ListResponse, null>(url, method)
}

export function Offload(terminalId: string, intentId: string) {
  const url = `${baseUrl()}/hub/card_readers/${terminalId}/offload`
  const method = "POST"

  return request<OffloadResponse, { intent_id: string }>(url, method, { intent_id: intentId })
}

export function CancelPayment(terminalId: string) {
  const url = `${baseUrl()}/hub/card_readers/${terminalId}/cancel_payment`
  const method = "POST"

  return request(url, method)
}

export function CancelPaymentWithBeacon(terminalId: string) {
  const url = `${baseUrl()}/hub/card_readers/${terminalId}/cancel_payment`

  navigator.sendBeacon(url, null)
}

export default {
  List,
  Offload,
  CancelPayment,
  CancelPaymentWithBeacon,
}