import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, BarElement, LinearScale, CategoryScale, Tooltip } from 'chart.js';
import useDarkMode from '@react-shared/useDarkMode';

ChartJS.register(BarElement, LinearScale, CategoryScale, Tooltip)

type Props = {
  labels: string[]
  data: { [key: string]: number }
  currency: {
    iso: string,
    subunits: number,
  }
}

function dataValues(data: { [key: string]: number }) {
  // convert keys to numbers
  const dataWithNumbers = Object.keys(data).reduce<{ [key: number]: number }>((acc, key) => {
    acc[parseInt(key)] = data[key]
    return acc
  }, {})

  // return values in order
  const hours = Array.from(Array(24).keys())
  return hours.map((hour) => dataWithNumbers[hour] || 0)
}

export default function TimeOfDayBarChart(props: Props) {
  const isDark = useDarkMode()

  return (
    <Bar
      data={{
        labels: props.labels,
        datasets: [
          {
            data: dataValues(props.data).map((value) => value / props.currency.subunits),
            backgroundColor: isDark ? "white" : 'rgb(30, 41, 59)',
            borderColor: isDark ? "white" : 'rgb(30, 41, 59)',
          }
        ]
      }}

      options={{
        maintainAspectRatio: false,

        plugins: {
          tooltip: {
            enabled: true,
            displayColors: false,
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: isDark ? "white" : "#666",
              format: {
                style: 'currency',
                currency: props.currency.iso,
              }
            }
          },
          x: {
            ticks: {
              color: isDark ? "white" : "#666",
            }
          }
        }
      }}
    />
  )
}
