import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLDialogElement> {
  connect() {
    this.element.addEventListener("click", this.testClose)
  }

  disconnect() {
    this.element.removeEventListener("click", this.testClose)
  }

  testClose = (e: Event) => {
    if (e.target == this.element) {
      this.element.close()
    }
  }
}
