import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "preview", "input", "deleteInput"]

  declare imageTarget: HTMLImageElement
  declare previewTarget: HTMLDivElement
  declare inputTarget: HTMLInputElement
  declare deleteInputTarget: HTMLInputElement
  declare hasDeleteInputTarget: boolean

  connect() {
    this.inputTarget.addEventListener("change", this.preview)
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.preview)
  }

  preview = () => {
    const file = this.inputTarget.files?.[0]

    if (file) {
      if (this.hasDeleteInputTarget) {
        this.deleteInputTarget.value = "0"
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageTarget.src = e.target?.result as string
        this.previewTarget.classList.remove("hidden")
      }

      reader.readAsDataURL(file)
    }
  }

  remove(e: Event) {
    e.preventDefault()

    this.imageTarget.src = ""
    this.inputTarget.value = ""
    this.previewTarget.classList.add("hidden")

    if (this.hasDeleteInputTarget) {
      this.deleteInputTarget.value = "1"
    }
  }
}
