import React from "react"
import { HubBillPaymentProps } from "./types"

import { createMemoryRouter, RouterProvider } from "react-router-dom"
import Home from "./Home"
import Terminals from "./Terminals"
import SplitPerson from "./SplitPerson"
import SplitItem from "./SplitItem"
import AppContext from "./AppContext"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import TerminalWait from "./TerminalWait"

export default function HubBillPayment(props: HubBillPaymentProps) {
  const currency = { iso: props.currency_iso, subunits: props.currency_subunits }

  const router = createMemoryRouter([
    { path: "/", element: <Home /> },
    { path: "/terminals", element: <Terminals /> },
    { path: "/terminals/:id", element: <TerminalWait /> },
    {
      path: "/split-person",
      element: <SplitPerson {...props} />,
    },
    {
      path: "/split-item",
      element: <SplitItem {...props} />,
    }
  ])

  const [amount, setAmount] = React.useState(
    props.bill.balance_with_tips_cents <= 0 ? null : props.bill.balance_with_tips_cents,
  )

  const [tip, setTip] = React.useState(0)

  const [selectedItems, setSelectedItems] = React.useState<{ [key: string]: number[] }>({})

  const [payingQuotas, setPayingQuotas] = React.useState(0)

  const [newGuests, setNewGuests] = React.useState(0)

  return (
    <AppContext.Provider value={{
      ...props,
      amount, setAmount,
      tip, setTip,
      selectedItems, setSelectedItems,
      payingQuotas, setPayingQuotas,
      newGuests, setNewGuests,
    }}>
      <CurrencyContext.Provider value={currency}>
        <RouterProvider router={router} />
      </CurrencyContext.Provider>
    </AppContext.Provider>
  )
}
