import React, { useEffect } from "react"
import Form from "@react-shared/Forms/Form"
import Fieldset from "@react-shared/Forms/Fieldset"
import TextField from "@react-shared/Forms/TextField"
import FormField from "@react-shared/Forms/FormField"
import Button from "@react-shared/Button"

import { t } from "../../i18n"
import TakingSheetItemsList from "./TakingSheetItemsList"
import { ApolloProvider } from "@apollo/client"
import apolloClient from "../../shared/apolloClient"
import { createMemoryRouter, RouterProvider } from "react-router-dom"
import AddItemDialog from "./AddItemDialog"

type Props = {
  url: string
  newRecord: boolean
  locationId: string
  takingSheetId: string
  fields: Record<string, string>
  errors: Record<string, string[]>
}

export default function HubTakingSheetConfigurator(props: Props) {
  const [refresh, setRefresh] = React.useState(0)
  const refreshItems = () => setRefresh(refresh + 1)

  const router = createMemoryRouter([
    { path: "/", element: null },
    { path: "/add", element: (
      <AddItemDialog
        locationId={props.locationId}
        takingSheetId={props.takingSheetId}
        refreshItems={refreshItems}
      />
    )},
  ])

  return (
    <ApolloProvider client={apolloClient}>
      <Form
        action={props.url}
        method={props.newRecord ? "post" : "patch"}>
        <div className={"text-end"}>
          <Button kind={"primary"} isSubmit={true}>
            {t("js.common.save")}
          </Button>
        </div>
        <div className={"space-y-12"}>
          <Fieldset
            legend={t("js.hub.admin.inventory.taking_sheets.form.fieldsets.basic.title")}>
            <FormField
              label={t("js.hub.admin.inventory.taking_sheets.form.labels.name")}
              hint={t("js.hub.admin.inventory.taking_sheets.form.hints.name")}
              error={props.errors["name"]?.[0]}>
              <TextField
                name="inventory_taking_sheet[name]"
                defaultValue={props.fields["name"]}
              />
            </FormField>
          </Fieldset>
          {!props.newRecord && (
            <Fieldset
              legend={t("js.hub.admin.inventory.taking_sheets.form.fieldsets.items.title")}
              description={t("js.hub.admin.inventory.taking_sheets.form.fieldsets.items.description")}
              layout={"wide"}>
              <div>
                <Button
                  kind={"primary"}
                  onClick={() => router.navigate("/add")}
                  >
                  {t("js.hub.admin.inventory.taking_sheets.form.add_items")}
                </Button>
              </div>
              <TakingSheetItemsList
                locationId={props.locationId}
                takingSheetId={props.takingSheetId}
                refresh={refresh}
              />
            </Fieldset>
          )}
        </div>
      </Form>
      <RouterProvider router={router} />
    </ApolloProvider>
  )
}
