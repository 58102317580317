import React from "react"
import { RouterProvider, createMemoryRouter } from "react-router-dom"

import { AppContext, PropsContext } from "./Contexts"
import { CurrencyContext } from "@react-shared/CurrencyContext"
import { AppProps, RefundTypes } from "./types"
import CustomAmount from "./CustomAmount"
import RefundType from "./RefundType"
import Summary from "./Summary"

export default function HubBillRefund(props: AppProps) {
  const [refund_type, setRefundType] = React.useState<RefundTypes>(RefundTypes.EntireBill)
  const [refund_amount, setRefundAmount] = React.useState<number>(0)

  const router = createMemoryRouter([
    { path: "/", element: <RefundType /> },
    { path: "/custom", element: <CustomAmount /> },
    { path: "/summary", element: <Summary /> },
  ])

  const app_context = {
    refund_type,
    refund_amount,
    setRefundType,
    setRefundAmount,
  }

  return (
    <PropsContext.Provider value={props}>
      <AppContext.Provider value={app_context}>
        <CurrencyContext.Provider value={props.currency}>
          <RouterProvider router={router} />
        </CurrencyContext.Provider>
      </AppContext.Provider>
    </PropsContext.Provider>
  )
}
