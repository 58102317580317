import React, { useContext } from "react"
import { HubOrderingModifierOption } from "@hub-ordering/types"
import Price from "@react-shared/Price"
import MultiModifierOption from "./MultiModifierOption"
import PosButton from "@hub-ordering/PosButton"
import { ModifiersContext } from "../../../GuestOrdering/pages/ItemModal/ModifiersContext"

type Props = {
  type: "checkbox" | "radio"
  modifierGroupId: string
  modifierOption: HubOrderingModifierOption
  disabled: boolean
}

export default function ModifierOption(props: Props) {
  const { type, modifierGroupId, modifierOption, disabled } = props

  const { modifiers, setUniqueModifier, setMultiModifier } = useContext(ModifiersContext)

  if (type == "checkbox" && modifierOption.allow_multiple) {
    return (
      <MultiModifierOption
        modifierGroupId={modifierGroupId}
        modifierOption={modifierOption}
        disabled={disabled}
      />
    )
  }

  const isSelected = Object.values(modifiers).find((m) => m.modifierGroupId === modifierGroupId)?.modifierOptions.some(
    (option) => option.modifierOptionId === modifierOption.id
  )
  const isDisabled = disabled && !isSelected

  return (
    <PosButton
      tag="label"
      className={`p-3 cursor-pointer border border-cyan-800 ${isSelected ? "bg-cyan-800" : "white"
        }`}
      text_color={isSelected ? "text-white" : "text-black dark:text-white"}
    >
      <input
        type={type == "checkbox" ? "checkbox" : "radio"}
        className={`h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 me-2 ${type == "checkbox" ? "rounded" : ""
          }`}
        value={modifierOption.id}
        onChange={(e) => {
          if (type == "checkbox") {
            if (e.target.checked) {
              setMultiModifier(modifierGroupId, {
                name: modifierOption.name,
                modifierOptionId: modifierOption.id,
                quantity: 1,
                unitPriceCents: modifierOption.priceCents
              }, 1)
            } else {
              setMultiModifier(modifierGroupId, {
                name: modifierOption.name,
                modifierOptionId: modifierOption.id,
                quantity: 0,
                unitPriceCents: modifierOption.priceCents
              }, 0)
            }
          } else {
            setUniqueModifier(modifierGroupId, {
              name: modifierOption.name,
              modifierOptionId: modifierOption.id,
              quantity: 1,
              unitPriceCents: modifierOption.priceCents
            })
          }
        }}
        disabled={isDisabled}
        name={
          type == "radio"
            ? `modifier_group_${modifierGroupId}`
            : `modifier_option_${modifierOption.id}`
        }
      />
      <div className="flex-grow text-start">
        <div className={`${isDisabled ? "text-gray-400" : ""}`}>{modifierOption.name}</div>
        {modifierOption.priceCents != 0 && (
          <div className={`text-xs ${isSelected ? "text-white" : "text-gray-600`}>"}`}>
            <Price cents={modifierOption.priceCents} />
          </div>
        )}
      </div>
    </PosButton>
  )
}
