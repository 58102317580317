import React, { useContext } from "react"
import { FieldContext } from "@react-shared/Forms/FieldContext"

export type TextFieldProps = {
  className?: string
  isInvalid?: boolean
  disableDarkMode?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

export default function TextField({ className, disableDarkMode, isInvalid, id, ...props }: TextFieldProps) {
  const ctx = useContext(FieldContext);
  const invalid = isInvalid || !!ctx.error;
  const fieldId = id || ctx.id;

  return (
    <input
      aria-invalid={isInvalid}
      className={`
        base-field
        ${invalid ? "pe-10 !ring-red-300 !dark:ring-red-400" : ""}
        ${disableDarkMode ? "" : "dark:base-field"}
        ${className || ""}`}
      {...props}
      id={fieldId}
      type="text"
    />
  )
}
