export default function debounce (fn: (...args: any[]) => void, time: number) {
  let timeoutId: NodeJS.Timeout | null = null
  return wrapper

  function wrapper (...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}