import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, BarElement, LinearScale, CategoryScale, Tooltip } from 'chart.js';
import useDarkMode from '@react-shared/useDarkMode';

ChartJS.register(BarElement, LinearScale, CategoryScale, Tooltip)

type Props = {
  labels: string[]
  data: { [key: number]: number }
  currency: {
    iso: string,
    subunits: number,
  }
}

export default function DayOfWeekBarChart(props: Props) {
  const isDark = useDarkMode()

  return (
    <Bar
      data={{
        labels: props.labels,
        datasets: [
          {
            data: Object.values(props.data).map((value) => value / props.currency.subunits),
            backgroundColor: isDark ? "white" : 'rgb(30, 41, 59)',
            borderColor: isDark ? "white" : 'rgb(30, 41, 59)',
          }
        ]
      }}

      options={{
        maintainAspectRatio: false,

        plugins: {
          tooltip: {
            enabled: true,
            displayColors: false,
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: isDark ? "white" : "#666",
              format: {
                style: 'currency',
                currency: props.currency.iso,
              }
            }
          },
          x: {
            ticks: {
              color: isDark ? "white" : "#666",
            }
          }
        }
      }}
    />
  )
}
