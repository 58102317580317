import { createContext } from "react";
import { AppProps, RefundTypes } from "./types";

export const PropsContext = createContext<AppProps>({
  currency: {
    iso: "",
    subunits: 0,
  },
  bill: {
    id: ""
  },
  payments: []
})

export const AppContext = createContext({
  refund_type: RefundTypes.EntireBill,
  refund_amount: 0,
  setRefundType: (_: RefundTypes) => { },
  setRefundAmount: (_: number) => { },
})