import React, { useEffect, useState } from "react"
import TextField, { TextFieldProps } from "./TextField"

type DecimalFieldProps = TextFieldProps & {
  valueStr?: string | null
  initialValue?: number | null // A floating-point number, mutually exclusive with valueStr
  initialValueStr?: string | null
  minDecimalPlaces?: number
  maxDecimalPlaces?: number
  onUpdate?: (value: number, raw: string) => void
}

function cleanupDecimalValue(s: string, maxDecimalPlaces: number): string {
  // Remove all non-numeric characters
  s = s.replace(/[^0-9.,]/g, "")
  // Remove all but the first period or comma
  s = s.replace(/([.,])(.*)([.,])/, "$1$2")
  // Truncate to n decimal places
  const rx = new RegExp(`([.,]\\d{${maxDecimalPlaces}}).*`)
  s = s.replace(rx, "$1")

  return s
}
  

export default function DecimalField(props: DecimalFieldProps) {
  const { initialValue, initialValueStr, onUpdate, maxDecimalPlaces, minDecimalPlaces, valueStr, ...rest } = props

  let _minDecimalPlaces = props.minDecimalPlaces || 0
  let _maxDecimalPlaces = props.maxDecimalPlaces || 3

  const initial = initialValueStr || (initialValue ? initialValue.toLocaleString(undefined, {
    minimumFractionDigits: _minDecimalPlaces,
    maximumFractionDigits: _maxDecimalPlaces
  }) : "")
  const [raw, setRaw] = useState(initial)

  useEffect(() => {
    let clean = cleanupDecimalValue(valueStr || "", _maxDecimalPlaces)
    setRaw(clean)
  }, [valueStr])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const s = cleanupDecimalValue(e.target.value, _maxDecimalPlaces)

    // Set the raw value
    setRaw(s)

    // Change commas to periods
    let intl = s.replace(",", ".")

    // Determine the value, handling NaN
    let parsed = parseFloat(intl)
    parsed = isNaN(parsed) ? 0 : parsed

    // Call the onUpdate handler
    onUpdate?.(parsed, s)
  }

  if (valueStr && initialValue) {
    console.warn("DecimalField: valueStr and initialValue are mutually exclusive")
  }

  return (
    <>
      <TextField
        {...rest}
        name={undefined}
        inputMode="decimal"
        value={raw}
        onChange={handleChange} />
      <input type="hidden" name={rest.name} value={raw.replace(",", ".")} />
    </>
  )
}
