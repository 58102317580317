import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sizeForm", "timeForm", "basePriceContainer", "radio"]

  declare sizeFormTarget: HTMLFieldSetElement
  declare timeFormTarget: HTMLFieldSetElement
  declare basePriceContainerTarget: HTMLDivElement
  declare radioTargets: HTMLInputElement[]

  connect() {
    this.radioTargets.forEach((radio) => {
      if (radio.checked) {
        this.setStrategy(radio.value)
      }
    })
  }

  disconnect() {}

  onPricingStrategyChange(e: Event) {
    const strategy = (e.target as HTMLInputElement).value

    this.setStrategy(strategy)
  }

  setStrategy(strategy: string) {
    switch (strategy) {
      case "size_based":
        this.sizeFormTarget.classList.remove("hidden")
        this.timeFormTarget.classList.add("hidden")
        this.basePriceContainerTarget.classList.add("hidden")
        break
      case "time_based":
        this.sizeFormTarget.classList.add("hidden")
        this.timeFormTarget.classList.remove("hidden")
        this.basePriceContainerTarget.classList.remove("hidden")
        break
      default:
        this.sizeFormTarget.classList.add("hidden")
        this.timeFormTarget.classList.add("hidden")
        this.basePriceContainerTarget.classList.remove("hidden")
    }
  }
}
